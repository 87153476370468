.admin-body {
    background-color: #ffffff !important;
}

.adminpanel-container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px
}

.admin-panel-sidebar {
    padding-left: 0;
    padding-right: 0;
}

.admin-containt-section {
    padding: 36px 32px 32px 32px;
}

.usertable-avtar {
    width: 44px;
    height: 44px;
    background-color: #DFE1EC;
    border: 2px solid #FFFFFF;
}

.usertable-img {
    border-radius: 50px;
    width: 44px;
    height: 44px;
}

.usertable-avtar {
    border-radius: 50%;
}

.usertable-avtar p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: SF UI Display Heavy;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0d393a;

}