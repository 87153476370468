.userprofile-containt {
  background-color: #ffffff !important;
}

.profile {
  background-color: #ffffff;
}

.profile-section {
  padding: 64px 0px;
  background-color: #f9f9fb;
  border-bottom: 1px solid #f2f3f7;
}

.empty-prediction-div {
  border: 1px solid #F2F3F7 !important;
  border-radius: 6px;
}

.profile-bottom-section {
  background-color: #ffffff;
  /* height: 398px; */
}

.profile-avtar-circle {
  width: 110px;
  height: 110px;
  min-width: 110px;
  background-color: #f2f3f7;
  position: relative;
}

.useName-profile-letter {
  margin-bottom: 0rem;
}

.rounded-profile-circle p {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.user-fullname {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #131520;
  margin-bottom: 8px;
}

.userprofile-details {
  margin-left: 16px;
}

.user-username {
  color: #7f87b3;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.btn-edit-profile {
  max-width: 150px;
  min-width: 150px;
  width: 100%;
  padding: 6px 0px;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  line-height: 24px;
  color: #131520;
  background-color: #f9f9fb;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  cursor: pointer;
}

.btn-edit-profile:focus {
  border: 2px solid #dfe1ec !important;
}

.btn-edit-profile:hover {
  border: 1px solid #bfc3d9;
}

.profile-top-part {
  display: flex;
}

.share-button {
  cursor: pointer;
  width: 24px;
  height: 21.3px;
  transition: all 0.3s ease-in-out;
}

.share-button:hover .share-path {
  fill: #5f69a0;
}

.profile-center-part {
  max-width: 380px;
  padding-left: 12px;
  width: 100%;
  margin-top: 32px;
  justify-content: space-between;
}

.followers-count {
  /* margin-left: 22px; */
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.followers {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #7f87b3;
  text-align: center;
}

.add-bio {
  color: #131520;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.add-bio:hover:not([disabled]) {
  color: #131520;
}

.add-bio:hover {
  text-decoration: underline;
}

.show-bio-data {
  color: #131520;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.show-bio-data:hover:not([disabled]) {
  color: #131520;
}

.show-bio-data:hover {
  text-decoration: none;
}


.user-profile-left-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 48px;
}

.prediction-list-top-btn {
  padding: 12px 28px !important;
}

.tabs {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.profile-end-part {
  max-width: 540px;
  width: 100%;
  margin-top: 32px;
}

.profile-content {
  padding-top: 16px;
  max-width: 100%;
  width: 100%;

}

.prediction-div {
  max-width: 840px;
  width: 100%;
}

.profile-content .left-panel {
  padding-bottom: 16px;
}

.profile-bottom-section {
  padding-top: 16px;
  /* height: 600px; */
}

.predictions,
.analytics {
  color: #7f87b3;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 8px;
  margin-right: 32px;
}

.predictions-active,
.analytics-active {
  color: #131520;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 8px;
  margin-right: 32px;
}

.predictions-symbol {
  width: 24px;
  height: 24px;
}

.predictions-avtar-circle {
  width: 82px;
  height: 82px;
  background-color: #f2f3f7;
  margin-top: 64px;
}

.p-published {
  margin-top: 24px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 64px;
}

.btn-primary {
  --bs-btn-active-bg: none;
}

.btn-add-prediction {
  padding: 14px 16px;
  background-color: #0d393a;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  line-height: 24px;
  border: none;
  border-radius: 6px;
}

.btn-add-prediction:hover {
  background-color: #09292a;
  border: none;
  color: #ffffff;
}

.add-prediction-btn-div {
  padding-bottom: 64px;
}

.modal-backdrop {
  --bs-backdrop-bg: #131520;
  --bs-backdrop-opacity: 0.8;
}

.modal {
  border: none !important;
  --bs-modal-border-radius: 6px;
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
}

.modal-content {
  max-width: 551px;
  width: 100%;
  padding: 32px;
  margin-top: 100px;
  /* pointer-events: all; */
}

.modal-header {
  border-bottom: none;
  padding: 6px 0px 10px 0px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.close-button {
  cursor: pointer;
}

.model-close-btn {
  width: 27.75px;
  height: 27.25px;
  border-radius: 6px;
  background-color: #F2F3F7;
  cursor: pointer;
}

.model-close-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.model-close-btn:hover {
  background-color: #DFE1EC;
}

textarea:focus {
  border: 2px solid #dfe1ec !important;
}

.model-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* padding: 32px; */
}

.modal-footer {
  border-top: none;
  padding: 16px 0px 0px 0px;
}

.modal-footer>* {
  margin: 0px !important;
}

.btn-cancel {
  padding: 14px 16px;
  background-color: #ffffff;
  border: 1px solid #dfe1ec;
  color: #131520;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  line-height: 24px;
  margin-right: 8px !important;
}

.btn-cancel:hover:not([disabled]) {
  background-color: #ffffff;
  border: 1px solid #bfc3d9;
  color: #131520;
}

.btn-saveprofile {
  padding: 14px 16px;
  background-color: #0d393a;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
  border: none;
  color: #ffffff;
  margin-left: 8px !important;
}

.btn-saveprofile:hover {
  background-color: #09292a;
  border: none;
  color: #ffffff;
}

.edit-profile-content {
  height: 284px;
}

.modal-body {
  padding: 16px 0px 16px 0px;
}

.editprofile-avatar {
  margin-right: 16px;
  /* cursor: pointer; */
}

.img-size-intro {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #7f87b3;
}

.img-size-error {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #d14343;
  padding-bottom: 8px;
}

.img-validation {
  flex-direction: column;
}

.edit-profile-bio {
  margin-top: 32px;
}

.bio-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #131520;
  margin-bottom: 8px;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  max-width: 487px;
  width: 100%;
  height: 110px;
  padding: 16px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #131520;
  cursor: pointer;
}

.form-textarea::placeholder {
  color: #7f87b3 !important;
}

.bio-info {
  display: flex;
  justify-content: space-between;
}

.bio-count {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #131520;
}

.btn-img-delete {
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  line-height: 24px;
  color: #131520;
  background-color: #f9f9fb;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  cursor: pointer;
}

.conformation-detail {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}

.btn-yes {
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  line-height: 24px;
  color: #ffffff;
  background-color: #0d393a;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 8px;
}

.avatar-upload {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 110px;
  height: 110px;
  z-index: 2;
}

.camera-img {
  position: absolute;
  margin: 15px;
}

.camera-background {
  width: 48px;
  height: 48px;
  background: rgba(19, 21, 32, 0.5);
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uploaded-img {
  border-radius: 50%;
}

.validation-error-show {
  display: block;
}

.validation-error-hide {
  display: none;
}

.show-delete-button {
  display: block;
}

.hide-delete-button {
  display: none;
}

.show-intro-line {
  display: show;
}

.hide-intro-line {
  display: none;
}

.show-conformation-part {
  display: block;
}

.hide-conformation-part {
  display: none;
}

.userprofile-details {
  max-width: calc(100% - 110px);
  width: 100%;
  margin-left: 0;
  padding-left: 20px;
}

.profile-top-part .uploaded-img {
  width: 110px;
  height: 110px;
}

.user-profile-tabs {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
}

.user-profile-tab {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #7F87B3;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}

.user-profile-tab.active {
  color: black;
}

.user-profile-tab.active .tab-symbol img {
  filter: brightness(0%);
}

@media screen and (max-width: 1024px) {
  .prediction-div{
    max-width: 100%;
  }
}

@media screen and (max-width: 767.98px) {
  .profile-avtar-circle {
    width: 90px;
    height: 90px;
    min-width: 90px;
  }

  .profile-section {
    background-color: unset;
  }

  .profile-content .left-panel {
    padding-left: 16px;
    padding-right: 16px;
  }

  .profile-top-part .uploaded-img {
    width: 90px;
    height: 90px;
  }

  .userprofile-details {
    max-width: calc(100% - 90px);
    width: 100%;
    margin-left: 0;
    padding-left: 16px;
  }
}

.profile-top-part {
  max-width: 380px;
}

.followers-detail,
.followering-detail,
.predictions-tab,
.analytics-tab {
  cursor: pointer;
}

.followering-detail,
.followers-detail {
  max-width: 85px;
  width: 100%;
  padding: 0;
}


.prediction-detail {
  max-width: 70px;
  width: 100%;
  padding: 0;
}

.predictions-tab {
  transition: all 0.2s ease-in-out;
}

/* public profile css */
.btn-follow {
  max-width: 150px;
  min-width: 150px;
  width: 100%;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background-color: #0d393a;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  cursor: pointer;
}

.btn-follow:hover {
  background-color: #09292a;
}

.btn-following-profile {
  max-width: 150px;
  min-width: 150px;
  width: 100%;
  padding: 6px 0px;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  line-height: 24px;
  color: #131520;
  background-color: #f9f9fb;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}

.btn-following-profile:hover span {
  display: none;
}

.btn-following-profile:hover:lang(en)::before {
  content: "Unfollow";
}

.btn-following-profile:hover:lang(ua)::before {
  content: "Не стежити";
}

/* 
.btn-following-profile:hover:before {
  content: "Unfollow";
} */

.btn-following-profile:hover {
  color: #ffffff;
  background-color: #d14343;
}

/* follower model css */
.follower-model-body {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.user-frame {
  max-width: 179px;
  width: 100%;
}

.follower-user-circle {
  width: 44px;
  height: 44px;
  min-height: 44px;
  min-width: 44px;
  border-radius: 50px;
}

.user-detail {
  /* max-width: 119px;
  width: 100%; */
  padding-left: 16px;
}

.user-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #131520;
}

.username-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.userId {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #7f87b3;
}

.follow-button {
  max-width: 109px;
  min-width: 109px;
  width: 100%;
  padding: 6px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background-color: #0d393a;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  cursor: pointer;
}

.follow-button:hover {
  background-color: #09292a;
}

.following-button {
  max-width: 109px;
  min-width: 109px;
  width: 100%;
  padding: 6px 0px;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  line-height: 24px;
  color: #131520;
  background-color: #ffffff;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}

.following-button:hover span {
  display: none;
}

/* .following-button:hover:before {
  content: "Unfollow";
} */

.following-button:hover:lang(en)::before {
  content: "Unfollow";
}

.following-button:hover:lang(ua)::before {
  content: "Не стежити";
}

.following-button:hover {
  color: #ffffff;
  background-color: #d14343;
}

.follower-model .modal-content {
  max-width: 484px;
  width: 100%;
  max-height: 508px;
  height: 100%;
  padding: 32px;
  margin-top: 100px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.follower-model .user-frame {
  max-width: 250px;
  width: 100%;
}

.userProfile-mobile-add-prediction-button {
  display: none;
}

.mobile-prediction-button {
  display: none;
}

/* Media Query */
/* leptop and large screen */
@media screen and (min-width: 1024px) and (max-width: 1290px) {
  .container-1240 {
    /* max-width: 854px; */
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

/* teblet and medium screen */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container-1240 {
    /* max-width: 768px; */
    /* max-width: 854px; */
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .profile-section {
    padding: 40px 16px
  }

  .userProfile-add-prediction-button {
    display: none !important;
  }

  .userProfile-mobile-add-prediction-button {
    display: flex;
    justify-content: center;
  }

  .user-profile-left-panel,
  .left-panel {
    overflow-y: scroll;
    padding: 0 16px
  }

  .user-profile-left-panel::-webkit-scrollbar,
  .left-panel::-webkit-scrollbar {
    display: none;
  }

  .prediction-list-top-btn {
    width: calc(100% - 32px);
    position: fixed;
    bottom: 16px;
    left: 16px;
    right: 16px;
  }

  .container-1240 {
    padding: 0;

  }

  .navbar-section {
    position: relative;
    /* padding: 0; */
  }

  .mobile-prediction-button {
    display: block;
  }

}


/* prediction css */
.all-leagues {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px
}

.all-leagues-containts {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #131520;
}

.popular-leagues-title,
.model-country-title {
  padding: 0 16px 4px 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #7F87B3;
}

.model-country-title {
  margin-top: 8px;
  margin-bottom: 12px;
}

.popular-leagues {
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
}

.popular-leagues:hover {
  background-color: #F2F3F7;
  cursor: pointer;
  border-radius: 6px;
}

.sports-scroll {
  max-height: 304px;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sports-scroll::-webkit-scrollbar {
  width: 5px;
  height: 124px;
}

.sports-scroll::-webkit-scrollbar-thumb {
  background: #BFC3D9;
  border-radius: 6px;
}

.leagues-country-scroll {
  max-height: 300px;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 50px;
}

.leagues-country-scroll::-webkit-scrollbar {
  width: 5px;
  height: 124px;
}

.leagues-country-scroll::-webkit-scrollbar-thumb {
  background: #BFC3D9;
  border-radius: 6px;
}

.leagues-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #131520;
  margin-left: 8px;
}

.sport-container-hide {
  display: none !important;
}

.sport-container-show {
  display: block;
}

.leagues-country-container-hide {
  display: none;
}

.leagues-country-container-show {
  display: block;
}

.sports-containter,
.model-country-containter {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  margin-top: 8px;
}

.sports-leagues-country-container {
  /* margin-top: 8px; */
}

.model-country-dropdown-hide {
  display: none;
}

.model-country-dropdown-show {
  display: block;
}

.sports-containter:hover,
.model-country-containter:hover {
  background-color: #F2F3F7;
  cursor: pointer;
  border-radius: 6px;
}




.country-leaguees-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.sports-name,
.model-country-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #131520;
  padding-left: 8px;
}

/* add-prediction-model */

.add-prediction-model-hide {
  display: none;
}

.add-prediction-model-show {
  display: block;
}



.addPrediction-right-panel .card {
  --bs-card-border-color: #F2F3F7;
  --bs-card-border-width: 1px;
}

.addPrediction-right-panel .card-body {
  padding: 32px;
}

.addPrediction-left-panel hr {
  border: 1px solid #F2F3F7;
  margin-top: 24px;
  margin-bottom: 24px;
}

.addPrediction-prediction-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #131520;
  margin-bottom: 24px;
}

.addPrediction-left-panel {
  max-width: calc(62% - 18px);
  width: 100%;
  /* margin-right: 18px; */
}

/* .addPrediction-tab .tab-content {
  overflow-y: scroll;
  height: 30rem;
}

.addPrediction-tab .tab-content::-webkit-scrollbar {
  width: 5px;
  height: 124px;
}
.addPrediction-tab .tab-content::-webkit-scrollbar-thumb {
  background: #BFC3D9;
  border-radius: 6px;
} */
.all-tab-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 40rem;
  overflow-x: hidden;
}

.all-tab-scroll::-webkit-scrollbar {
  width: 5px;
  height: 124px;
}

.all-tab-table-hide {
  display: none;
}

.all-odds-score-display-hide {
  display: none;
}

.all-tab-scroll::-webkit-scrollbar-thumb {
  background: #BFC3D9;
  border-radius: 6px;
}

.champions-league-table {
  padding-right: 2px;
}

.addPrediction-right-panel {
  max-width: 38%;
  width: 100%;
}

.prediction-slip-avatar {
  width: 82px;
  height: 82px;
  background-color: #F2F3F7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 24px;
}

.prediction-slip-container {
  margin-top: 7px;
}

.prediction-slip-empty-msg {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #131520;
  margin-bottom: 8px;
  text-align: center;
}

.add-prediction-betslip {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7F87B3;
  text-align: center;
}

.addSome-prediction-container {
  display: flex;
  justify-content: space-between;
}


/* accordion */
.accordion-country-container .accordion {
  border: none !important;
  --bs-accordion-border-color: #ffffff;
  --bs-accordion-border-width: none;
  --bs-accordion-border-radius: 0px;
  --bs-accordion-active-color: #131520;
}

.country-leaguees-containts {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 58px;
  padding-right: 16px;
}

.filter-country-contains {
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 8px;
  cursor: pointer;
}

.accordion-country-container .accordion-button {
  padding: 10px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #131520;
}

.accordion-country-container .accordion-button::after {
  background-image: url("/public/down.svg");
}

.accordion-country-container .accordion-button:not(.collapsed)::after {
  background-image: url("/public/up.svg");
  width: 20px;
  transform: none;
}

.accordion-country-container .accordion-item:first-of-type .accordion-button {
  border-radius: 6px;
}

.accordion-country-container .accordion-body {
  padding: 0px;
}

.accordion-country-container .accordion-button:focus {
  z-index: 1;
  border-color: #F2F3F7;
  outline: 0;
  box-shadow: none;
}

.accordion-country-container .accordion-button:not(.collapsed) {
  background-color: #F2F3F7;
  border-radius: 6px;
}

.accordion-button:hover {
  border-radius: 6px;
}

.popular-leagues-container-hide {
  display: none;
}

.popular-leagues-container-show {
  display: block;
}


/* userProfile - tab  */
.userProfile-prediction-tab .nav-tabs {
  --bs-nav-tabs-border-width: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.userProfile-prediction-tab .nav-tabs .nav-link.active {
  color: #131520;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  /* margin-left: 8px;
  margin-right: 32px; */
}

/* .userProfile-prediction-tab .nav-item {
  padding-right: 24px;
} */

.userProfile-prediction-tab .nav-link {
  color: #7f87b3;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  /* margin-left: 8px; */
  text-decoration: none;
}

.main-content-section {
  display: flex;
  flex-direction: row;
  gap: 32px;
}