.cell-wrapper-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-name {
    display: flex;
    gap: 10px;
    /* flex-direction: column; */
}

.skeleton-placeholder-light {
    background: linear-gradient(90deg, #F2F3F7 0px, #f8f8f8 40px, #F2F3F7 80px);
    background-size: 350px;
    width: 64px;
    height: 48px;
    border-radius: 6px;
    animation: animation 2.5s infinite;
}

.skeleton-placeholder-dark {
    background: linear-gradient(90deg, #EBEBF0 0px, #f8f8f8 40px, #EBEBF0 80px);
    background-size: 350px;
    width: 64px;
    height: 48px;
    border-radius: 6px;
    animation: animation 2.5s infinite;
}


.leagues-skeleton-grid-placeholder,
.game-skeleton-grid-placeholder {
    background: linear-gradient(90deg, #F2F3F7 0px, #f8f8f8 40px, #F2F3F7 80px);
    background-size: 350px;
    width: 64px;
    height: 48px;
    border-radius: 6px;
    animation: animation 2.5s infinite;
}


.leagues-skeleton-grid-placeholder-title,
.game-skeleton-grid-placeholder-title {
    background: linear-gradient(90deg, #BFC3D9 0px, #f8f8f8 40px, #BFC3D9 80px);
    background-size: 350px;
    width: 64px;
    height: 20px;
    border-radius: 7px;
    animation: animation 2.5s infinite;
}

.sportline-sidebar-skeleton-placeholder {
    background: linear-gradient(90deg, #DFE1EC 0px, #f8f8f8 40px, #DFE1EC 80px);
    background-size: 350px;
    width: 97px;
    height: 20px;
    border-radius: 6px;
    animation: animation 2.5s infinite;
}

.sportline-sidebar-skeleton-placeholder.country {
    width: 20px;
}

.sportline-sidebar-skeleton-placeholder.counter {
    width: 16px;
    height: 16px;
}

.sidebar__loader-wrapper {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
}

.sidebar__loader-popular-item-wrapper,
.sidebar__loader-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 6px;
    height: 44px;
}

.sidebar__loader-item-wrapper {
    background-color: #F2F3F7;
}

.all-sports-loader-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sidebar__loader-item-right {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.leagues-skeleton-grid-placeholder-title.img {
    width: 30px;
    border-radius: 3px;
}

.leagues-skeleton-grid-placeholder-title.text,
.game-skeleton-grid-placeholder-title.text {
    width: 208px;
    border-radius: 7px;
    margin-left: 10px;
}

.leagues-skeleton-grid-placeholder.circle {
    width: 24px;
    height: 24px;
    border-radius: 100%;

}

.leagues-skeleton-grid-placeholder.date {
    width: 46px;
    height: 16px;
    border-radius: 6px;
}

.leagues-skeleton-grid-placeholder.time {
    width: 29px;
    height: 16px;
    border-radius: 6px;
}

.leagues-skeleton-grid-placeholder.team2 {
    width: 90px;
    height: 20px;
    border-radius: 6px;
}

.leagues-skeleton-grid-placeholder.team1 {
    width: 70px;
    height: 20px;
    border-radius: 6px;
}

.odds-grid-1 {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.leagues-skeleton-grid-placeholder.odd {
    flex: 1;
    height: 68px;
    border-radius: 6px;
}

.leagues-skeleton-grid-placeholder.date-time {
    width: 94px;
    height: 16px;
    border-radius: 6px;
}

/* game page */
.game-title {
    display: flex;
}

.match-date-time {
    display: flex;
    justify-content: center;
}

.game-skeleton-grid-placeholder-title.img {
    width: 21px;
    border-radius: 3px;
}

.game-skeleton-grid-placeholder.date {
    width: 103px;
    height: 24px;
    border-radius: 6px;
}

.game-skeleton-grid-placeholder.opp-name {
    margin-top: 5px;
    width: 85px;
    height: 24px;
    border-radius: 6px;
}

.game-skeleton-grid-placeholder.opp-img {
    width: 64px;
    height: 64px;
    border-radius: 100%;
}

.main-predictions,
.prediction-table {
    padding: 24px 16px 24px 16px;
    border-top: 1px solid #F2F3F7;
}

.predictions-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;
    gap: 20px;
}

.game-skeleton-grid-placeholder.odd-game-mobile {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    border-radius: 6;
}

.game-skeleton-grid-placeholder.title,
.game-skeleton-grid-placeholder.title2 {
    width: 106px;
    height: 24px;
    border-radius: 6;
}

.game-skeleton-grid-placeholder.title2 {
    margin-top: 24px;
}

.game-skeleton-grid-placeholder.odd {
    flex-grow: 1;
    width: 293px;
    height: 58px;
    border-radius: 6;
}

.prediction-table {
    width: 100%;
}

.odds-grid .game-skeleton-grid-placeholder.odd {
    width: auto;
}

.game-skeleton-grid-placeholder.odd-title {
    width: 40px;
    height: 24px;
    border-radius: 6;
}

.odds-grid {
    display: grid;
    margin-top: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.odd-wrapper {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-skeleton-grid-placeholder.odd-coef {
    width: 48px;
    height: 24px;
    border-radius: 6;
}

.champions-leaue-name-skeleton {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.champions-leaue-score-date-skeleton {
    display: flex;
    align-items: center;
}

.skeleton-header {
    display: flex;
    background-color: #f2f3f7;
    height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    padding-left: 20px;
}


/*Predictions list*/

.predictions-list-container {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    padding: 24px;
    margin-bottom: 16px;
}

.prediction-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.prediction-item-title {
    display: flex;
    gap: 16px;
}

.username-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.skeleton-grid-placeholder.user-img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}

.skeleton-grid-placeholder.over {
    position: relative;
    left: -20px;
    width: 52px;
    height: 52px;
    border: 2px solid #fff;
}

.skeleton-grid-placeholder.username {
    width: 119px;
    height: 24px;
    border-radius: 6px;
}

.skeleton-grid-placeholder.date {
    width: 83px;
    height: 20px;
    border-radius: 6px;
}


.prediction-item-bottom {
    display: flex;
    justify-content: space-between;
    margin-left: 80px;
}

.prediction-item-bottom-left {
    display: flex;
    gap: 4px;
}

.skeleton-grid-placeholder.like {
    width: 62px;
    height: 32px;
    border-radius: 6px;
}

.skeleton-grid-placeholder.share {
    width: 40px;
    height: 32px;
    border-radius: 6px;
}

.prediction-details-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    padding: 16px;
    gap: 16px;
}

.prediction-details-wrapper_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #F2F3F7;
    padding-bottom: 16px;
}

.prediction-details-wrapper_top_left {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.skeleton-grid-placeholder.title-text {
    width: 95px;
    height: 24px;
    margin-right: 4px;
    border-radius: 6px;
}

.skeleton-grid-placeholder.top-share {
    width: 39px;
    height: 36px;
    border-radius: 6px;
}

.prediction-details-wrapper_bottom {
    display: flex;
    gap: 24px;
}

.coef-item-wrapper {
    flex: 1;
    border-right: 1px solid #F2F3F7;
}

.skeleton-grid-placeholder.coef-title {
    width: 65px;
    height: 16px;
    border-radius: 6px;
}

.skeleton-grid-placeholder.coef {
    width: 25px;
    height: 24px;
    border-radius: 6px;
    margin-top: 8px;
}


/* rewards */

.left-section-skeleton {
    background-color: #FBFBFB !important;
    border: 1px solid #F2F3F7 !important;
}

.rewards-loader-left-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.rewards-loader-left-top-text {
    width: 81px;
    height: 24px;
    margin-bottom: 12px;
}

.rewards-loader-left-top-description {
    width: 108px;
    height: 44px;
}

.rewards-loader-left-bottom {
    display: flex;
    gap: 8px;
}

.rewards-loader-left-bottom-img {
    width: 32px;
    height: 32px;
}

.rewards-loader-left-bottom-txt {
    width: 316px;
    height: 32px;
}

.rewards-loader-left-top-img {
    width: 170px;
    height: 121px;
}

.rewards-loader-total-title {
    width: 144px;
    height: 24px;
}

.rewards-loader-total-count {
    width: 30px;
    height: 24px;
}

.rewards-category-name {
    width: 260px;
    height: 24px;
    border-radius: 6px;
}

.rewards-loader-subitem_img {
    width: 124px;
    height: 124px;
    border-radius: 6px;
}

.rewards__loader-sub-item_title-wrapper {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    justify-content: space-between;
}

.rewards-loader-subitem_title {
    width: 167px;
    height: 24px;
    border-radius: 6px;
}

.rewards-loader-subitem_info-section {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.rewards-loader-subitem_info-item1,
.rewards-loader-subitem_info-item2,
.rewards-loader-subitem_info-item3 {
    display: flex;
    flex-direction: column;
    height: 52px;
    justify-content: space-between;
    gap: 4px
}

.rewards-loader-info-item-title {
    width: 41px;
    height: 16px;
}

.rewards-loader-info-item-value {
    width: 24px;
    height: 24px;
}

.rewards-loader-subitem_info-item1 {
    width: 81px;
}

.rewards-loader-subitem_info-item2 {
    width: 240px;
}

.rewards-loader-subitem_info-item2 .rewards-loader-info-item-title {
    width: 54px;
}

.rewards-loader-subitem_info-item2 .rewards-loader-info-item-value {
    width: 208px;
    height: 8px;
}

.rewards-loader-subitem_info-item3 {
    width: 105px;
}

.rewards-loader-subitem_info-item3 .rewards-loader-info-item-title {
    width: 65px;
}

.rewards-loader-subitem_info-item3 .rewards-loader-info-item-value {
    width: 40px;
    height: 24px;
}

.user-rewards-loader-img {
    width: 100px;
    height: 100px;
}

.user-rewards-loader-item-title {
    width: 75px;
    height: 16px;
}

.user-rewards-loader-title {
    width: 134px;
    height: 24px;
}

.user-rewards-loader-btn {
    width: 100%;
    height: 48px;
}

.user-rewards-list-item {
    width: 100%;
}

.user-rewards-list-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.wallet-history-loader-data-row,
.wallet-history-loader-title-row {
    display: flex;
    gap: 12px;
}

.wallet-history-loader-data-row {
    height: 88px;
    align-items: center;
    border-bottom: 1px solid #F2F3F7;
}

.wallet-history-loader-data1,
.wallet-history-loader-title1 {
    width: 55%;
}

.wallet-history-loader-data2,
.wallet-history-loader-data3,
.wallet-history-loader-data4,
.wallet-history-loader-title2,
.wallet-history-loader-title3,
.wallet-history-loader-title4 {
    width: 15%;
}

.wallet-history-loader-data2,
.wallet-history-loader-data3,
.wallet-history-loader-data4 {
    height: 88px;
}

.wallet-history-title1 {
    height: 20px;
    width: 79px;
}

.wallet-history-title2 {
    height: 20px;
    width: 42px;
}

.wallet-history-title3 {
    height: 20px;
    width: 80px;
}

.wallet-history-title4 {
    height: 20px;
    width: 20px;
}

.wallet-history-loader-data1,
.wallet-history-loader-data2,
.wallet-history-loader-data3,
.wallet-history-loader-data4 {
    display: flex;
    gap: 8px;
    align-items: center;
}

.wallet-history-img {
    width: 42px;
    height: 40px !important;
}

.wallet-history-data1 {
    width: 70px;
    height: 20px;
}

.wallet-history-data2 {
    width: 22px;
    height: 24px;
}

.wallet-history-data4,
.wallet-history-data3 {
    width: 70px;
    height: 24px;
}

.mobile-wallet-history-title {
    width: 130px;
    height: 24px;
}

.mobile-wallet-history-subtitle {
    width: 65px;
    height: 20px;
}

.mobile-wallet-history-img {
    width: 52px;
    height: 48px;
}

.mobile-wallet-history-value-title1 {
    width: 76px;
    height: 20px;
}

.mobile-wallet-history-value-title2 {
    width: 34px;
    height: 20px;
}

.mobile-wallet-history-value-data1 {
    width: 50px;
    height: 20px;
}

.mobile-wallet-history-value-data2 {
    width: 99px;
    height: 20px;
}

.chat__loader-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    gap: 24px;
}

.msg-loader-wrapper {
    display: flex;
    gap: 12px;
}

.msg-loader__user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%
}

.msg-loader__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.msg-loader__username {
    width: 114px;
    height: 24px;
    border-radius: 4px
}

.msg-loader__text {
    width: 240px;
    height: 20px;
    border-radius: 4px
}


@media screen and (max-width: 768.1px) {
    .rewards-loader-left-top-img {
        width: 155px;
        height: 111px;
    }

    .rewards-loader-left-bottom {
        height: 133px !important;
        align-items: end;
    }

    .rewards-loader-total-title {
        width: 123px;
    }

    .rewards-loader-subitem_img {
        width: 100%;
        height: 315px;
    }

    .rewards-loader-subitem_info {
        width: 100%;
        height: 76px;
    }

    .rewards-loader-subitem_top-info-wrapper {
        display: flex;
        gap: 12px;
    }
}

@keyframes animation {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 270px;
    }
}