.alert-notification {
    position: fixed;
    width: fit-content;
    top: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 16px;
    background-color: #000;
    color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 1000;
    font-size: 12px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.alert-notification.show {
    opacity: 1; 
  }

  @media only screen and (max-width: 1024px) {
    .alert-notification{
        top: 105px
    }
  }