.community-group-contains .card-body {
    color: unset !important;
}


.community-group-col .card {
    border: 0 !important;
}

.community-group-col .card-body {
    padding: 0;
}

.community-userDetail {
    padding-bottom: 20px;
    align-items: center;
}

.review-decription {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #0B0D13;
}

.community-group-contains {
    padding-bottom: 185px;
}

.community-group-card {
    padding: 24px;
    margin-bottom: 24px;
}

.community-username {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.community-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}