.cookie-popup .notification-container {
    left: 0;
}

.cookie-popup .notification {
    position: fixed;
    bottom: 64px;
    left: 32px;
    box-shadow: 0px 0px 8px 0px rgba(5, 6, 15, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 32px 24px;
    max-width: 322px;
    width: 100%;
}

.cookie-section {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    color: #7F87B3;
}

.cookie-image {
    position: absolute;
    top: -20px;
}

.cookie-nav-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    color: #208B8E;
    text-decoration: none;
}

.cookie-nav-title:hover {
    color: #208B8E;

}

.cookie-btn {
    background-color: #0d393a;
    margin-top: 0px;
    padding: 6px 16px;
    min-width: unset;
}

.cookie-popup .notification-container.notification-container-empty {
    transform: translateX(-100%);
}

.cookie-popup .notification-container {
    transform: translateX(0);
    transition: all 0.7s ease-in-out;
    height: 100%;
}

.cookie-popup .notification {
    transform: none !important;
}