/* --------- Reset Password css ------- */

.resetpassword-container {
  height: 1400px;
  padding: 55px;
}

.resetpassword-avatar {
  display: flex;
  justify-content: center;
}

.reset-title {
  font-size: 20px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .resetpassword-container {
    padding: 25px;
  }
}

/* -------- reset New Password css ------ */

/* ----------- checkMail css ---------- */
.checkmail-container {
  height: 1400px;
  padding: 55px;
}

.checkmail-avatar {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .checkmail-container {
    padding: 25px;
  }
}

@media screen and (max-width: 991.98px) {
  .resetPasswordIconfaild {
    flex-wrap: wrap;
  }
}

/* ---------- returnlogin ---------- */

.returnlogin-card-title {
  margin-bottom: 0;
}