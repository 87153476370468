/* .logedInUser-navbar,
.notLogedIn-userNavbar {
    width: 100%;
    position: fixed !important;
    top: 0;
    transition: all 0.5s;
    display: block;
    z-index: 100;
}

.logedInUser-navbar.nav-bar--hidden,
.notLogedIn-userNavbar.nav-bar--hidden {
    transform: translateY(-100%);
} */

.navigation-toggle {
    display: none;
}

.navbar-expand .navbar-collapse {
    margin-left: 64px;
}

.toggle-avatar-name {
    display: none;
}

.language-toggle-icon {
    display: none;
}

.currancy-toggle-icon {
    display: none;
}

.mobile-navigation-profile {
    display: none !important;
}

.navbar-toggler-icon {
    width: 24px;
    height: 24px;
}

.mobile-country-name {
    display: none;
}

.hidden-overFlow {
    overflow: hidden !important;
}

.navbar-avtar:hover {
    border: 2px solid #DFE1EC;
}

.navbar-uploaded-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.mobile-coin-section {
    display: none !important;
}

.learge-screen-coin-section {
    display: flex !important;
    align-items: center;
}

@media (min-width: 768px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
}

.coin-navbar-image {
    max-width: 25.8px !important;
    width: 100%;
    max-height: 24px;
}

@media (min-width: 768px) {
    .navbar-expand-lg .navbar-toggler {
        display: none;

    }
}

.coin-total-count {
    font-weight: 700;
    font-size: 14px;
    color: #131520;
    width: fit-content;
    white-space: nowrap;
}

@media screen and (max-width: 1024px) {
    .mobile-coin-section {
        display: flex !important;
        padding: 8px 16px 8px 8px;
        align-items: center;
    }

    .learge-screen-coin-section {
        display: none !important;
    }

    .navbar-logo {
        justify-content: space-between;
        width: 100%;
    }

    .navbar-collapse {
        /* flex-basis: 100%; */
        flex-grow: 1;
        position: absolute;
        z-index: 1025;
        background: #ffffff;
        height: 100vh !important;
        width: 100%;
        left: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        /* padding-left: 0 !important; */
        top: 100%;
        /* padding-right: 0 !important; */

    }

    @supports (-webkit-touch-callout: none) {

        .navbar-collapse {
            padding-bottom: 110px;
        }
    }

    /* .logedInUser-navbar {
        padding: 0px;
    } */

    .logedin-parant-collapse {
        margin-top: 1px;
        overflow-y: scroll;
    }

    .language-toggle {
        position: relative
    }

    .setting-profile-part {
        display: block !important;
    }

    .setting-profile-part .navbar-profile-detail {
        padding: 0px;
    }

    .language-suggestion {
        right: 0;
        min-width: 100%;
        position: absolute;
        border: none;
        box-shadow: none;
        top: 36%;
        padding: 0;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .currency-suggestion {
        right: 0;
        max-width: 100%;
        position: absolute;
        border: none;
        box-shadow: none;
        top: 36%;
        padding: 0;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .language-toggle:hover {
        background-color: #f2f3f7;
        border-radius: 6px;
        cursor: pointer;
    }

    .country-name {
        display: none;

    }

    .currancy-toggle:hover {
        background-color: #f2f3f7;
        border-radius: 6px;
        cursor: pointer;
    }

    .mobile-country-name {
        display: block;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #131520;
        font-family: 'SF UI Display';

    }

    .navbar-expand .navbar-nav {
        flex-direction: column !important;
        border-bottom: 1px solid #F2F3F7;
        width: 100%;
    }

    .navbar-notification-part {
        margin: 0px 0px 0px 0px;
        align-self: unset;
        width: 100%;
    }

    .notification-button__badge {
        position: unset;
    }

    .navbar-expand .navbar-collapse {
        flex-direction: column !important;
        padding: 16px;
        margin-left: 0px !important;
    }

    .language-toggle-icon {
        display: flex;
        align-items: center;
    }

    .currancy-toggle-icon {
        display: flex;
        align-items: center;
    }

    .navbar-toggler-icon {
        display: block;
    }

    .collapse:not(.show) {
        display: none !important;
    }

    .navbar-expand .navbar-toggler {
        display: flex;
        border: none !important;
    }

    .navbar-expand .navbar-toggler:focus {
        outline: none !important;
    }

    .topnav {
        justify-content: space-between;
        /* border-bottom: 1px solid #F2F3F7; */
    }


    .navbar-toggler {
        padding: 0px;
    }

    .navigation-toggle {
        display: block;
        display: grid;
        align-items: center
    }

    .slogo {
        display: none;
    }

    .country-language {
        flex-direction: column;
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .country-part {
        max-width: 100%;
        justify-content: flex-start;
        padding-left: 0px;
        padding-right: 0px;
    }

    .language-part {
        max-width: 100%;
        justify-content: flex-start;
        padding-left: 0px;
        padding-right: 0px;
    }

    .right-side .country-language {
        padding-top: 24px;
    }

    .mobile-language-name {
        color: #7F87B3;
    }

    .navbar-language-name {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .navbar-currency-name {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .navbar-expand .navbar-nav .nav-link {
        padding: 0px;
        padding-bottom: 16px;
    }

    .nav-link {
        margin-right: 0px;
    }

    .navbar-section .button:focus:not(:focus-visible) {
        outline: none !important;
    }

    .navigation-avatar {
        justify-content: space-between;
        max-width: 100%;
    }

    .toggle-avatar-name {
        display: flex;
        align-items: center;
        padding-left: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .avatar-name-containt {
        display: flex;
    }

    .topnav .navbar-toggler:focus {
        box-shadow: none
    }

    .navbar-suggestion {
        right: 0;
        width: 100%;
        top: 103%;
        z-index: 15;
        position: absolute;
        max-width: 100%;
        visibility: hidden;
        border: none;
        box-shadow: none;
    }

    .navbar-avatar-top {
        padding-left: 0px;
        padding-right: 0px;
    }

    .logout-part {
        padding-left: 0px;
        padding-right: 0px;
    }

    .navigation-avatar {
        position: relative;
    }

    .close-button:hover {
        visibility: visible !important;
        opacity: 1 !important;

    }

    .Accordion-body-hide-description {
        max-width: unset !important;
        width: unset !important;
    }

    .pagination-section {
        flex-wrap: wrap;
    }

    .mobile-notification-suggestion .notification-suggestion {
        opacity: 1;
        visibility: visible;
        position: relative;
        left: 0;
        width: 100%;
        top: 0;
        max-width: 100%;
        min-width: unset;
        border: none;
        box-shadow: none
    }

    .new-notificationsData-div {
        padding: 0px !important;
    }

    .pending-notifications {
        margin: 0px !important;
    }

    .mobile-notification-suggestion .new-notificationsData-scroll {
        max-height: 116px;
    }

    .navbar-notification-part {
        flex-direction: column;
    }

    .country-part {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 8px;
        padding-right: 8px
    }

    .language-part {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 8px;
        padding-right: 8px
    }



    /* navbar-avtar.nav-avt.rounded-circle.d-flex.align-items-center.justify-content-center: hover {
        border: 2px solid red !important;

    } */
}

.mobile-logout-button {
    display: none;
}

/* .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px;
    color: #fff;
    transition: top 0.2s ease-in-out;
}

.nav-up {
    top: -40px;
} */

@media screen and (max-width: 1024px) {
    .container-1240 {
        width: 100%;
        margin: 0 auto;
    }

    .userProfile-navLink {
        padding-top: 36px;
        padding-bottom: 24px;
    }

    .feed-navLink {
        padding-bottom: 24px;
    }



    .notification-button__badge {
        max-width: 24px;
        max-height: 24px;
        font-size: 12px;
        padding: 8px 5px;
    }

    .admin-navlink {
        padding-bottom: 36px;
    }

    .right-side {
        flex-direction: column;
        padding-top: 16px;
        max-width: 100%;
    }

    .mobile-logout-button {
        display: block;
    }

    .mobile-logout-icon-button {
        width: 100%;
        background-color: #0D393A;
        border-radius: 6px;
        justify-content: center;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        transition: all 0.3s ease-in-out;
        line-height: 24px;
        border: none;
        margin-top: 16px;
        margin-bottom: 180px;
    }

    .mobile-logout-icon-button:hover {
        background-color: #09292a;
        border: none;
        color: #ffffff;
    }


    .navbar {
        --bs-navbar-toggler-icon-bg: url('../../public/close.svg') !important;
    }

    .test.navbar-toggler.collapsed {
        content: '';
        background-image: url('../../public/menu.svg');
        width: 24px;
        height: 24px;
    }

    .test.navbar-toggler.collapsed .navbar-toggler-icon {
        display: none;
    }

    .userprofile-containt .navbar-collapse.collapse.show:target {
        overflow: hidden;
    }

    .navigation-avatar {
        flex-wrap: wrap;
    }

    .navbar-suggestion {
        opacity: 1;
        visibility: visible;
        position: relative;
        left: 0;
        width: 100%;
        top: 0;
        max-width: 100%;
    }

    .desktop-navigation-profile {
        display: none !important;
    }

    .mobile-navigation-profile {
        display: flex !important;
    }

    .navbar-model-hide {
        display: none !important;
    }

    .navbar-model-show {
        display: block !important;
    }

    .language-suggestion,
    .currency-suggestion {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        right: 0;
    }

    .mobile-languages-dropdown {
        display: flex !important;
        margin-top: 22px;
        border: 1px solid #F2F3F7;
        border-radius: 6px;
    }

    .English-EN-dropDown-active {
        padding: 16px 16px;
        display: flex !important;
        justify-content: center;
        background-color: #F2F3F7;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #131520;
        align-items: center;
    }

    .Українська-UA-dropDown-active {
        padding: 16px 16px;
        display: flex !important;
        justify-content: center;
        background-color: #F2F3F7;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #131520;
        align-items: center;
    }

    .Українська-UA-English-EN-dropDown {
        padding: 16px 16px;
        display: flex !important;
        justify-content: center;
        border-radius: 6px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #131520;
        align-items: center;
    }

    .Українська-UA-label,
    .English-EN-label {
        padding-left: 8px;
    }

    .language-toggle {
        display: none !important;
    }

    .notificationIcon-icon {
        display: none;
    }

    .notification-mobile-title,
    .userProfile-navLink,
    .feed-navLink,
    .admin-navlink,
    .navbar-profile-detail {
        display: block !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #131520;
        text-decoration: none;
    }

    .notification-mobile-title:focus {
        color: #131520;
    }

    .notification-img {
        align-items: center;
        padding: 12px 0px 12px 0px;
        width: 100%;
    }

    .desktop-notification-suggestion {
        display: none;
    }

    .mobile-notification-suggestion {
        display: block !important;
    }

}

.mobile-notification-suggestion {
    display: none;
}

.notification-mobile-title {
    display: none;
}

/* destop */
.mobile-languages-dropdown {
    display: none;
}



/* teblet media */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .logedInUser-navbar .test.navbar-toggler.collapsed {
        content: '';
        background-image: url('../../public/toggle-button.svg');
        width: 24px;
        height: 24px;
        display: inline-block;
        border: none;
    }

    .logedInUser-navbar .test.navbar-toggler {
        background-image: url('../../public/toggle-close.svg');
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        border: none;
    }

    .logedInUser-navbar .navbar-toggler .navbar-toggler-icon {
        display: none;
    }

    .logedInUser-navbar .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        position: absolute;
        align-items: center;
        top: 100%;
        left: 0px;
        width: 100%;
        z-index: 1025;
        background: #ffffff;
        height: 100vh;
        flex-direction: column;
        align-items: baseline;
        padding: 16px
    }
}