.top-game-predictions {
    padding: 0 32px;
}

.top-game-predictions__title {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.game-top-predictions__table {
    width: 100%;
    border: 1px solid #F2F3F7;
    border-bottom: none;
    border-radius: 6px;
    border-collapse: separate;
    border-spacing: 0;
}

.game-top-predictions__table td,
.game-top-predictions__table th {
    border-bottom: 1px solid #F2F3F7;
    box-sizing: border-box;
}

.top-predictions-table__header {
    border-bottom: 1px solid #F2F3F7;
    color: #7F87B3;
    font-size: 12px;
    font-weight: 500;
    height: 48px;
    border-radius: 12px
}

.prediction-place-header {
    width: 65px;
}

.prediction-name-header {
    /* width: 50%; */
}

.prediction-count-header {
    width: 110px;
}

.prediction-avg-header {
    width: 95px;
}

.prediction-coins-header {
    width: 120px;
}

.top-predictions-table__header-hint {
    display: flex;
    gap: 4px;
    cursor: pointer;
    align-items: center;
}

.top-predictions-table__header-hint:hover {
    color: #000;
}

.top-predictions-table__row {
    border-bottom: 1px solid #F2F3F7;
    font-size: 14px;
    font-weight: 300;
    height: 92px;
}

.prediction-place-header,
.prediction-place-cell {
    padding: 0;
    text-align: center;
}

.top-predictions__oc_name-wrapper {
    display: flex;
    flex-direction: column;
}

.top-predictions__oc_name {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

.top-predictions__oc_group_name {
    color: #7F87B3;
}

.top-predictions__sm-value-loader {
    width: 23px;
    height: 24px;
    border-radius: 4px
}

.top-predictions__md-value-loader {
    width: 32px;
    height: 24px;
    border-radius: 4px
}

.top-predictions__oc-name-loader {
    width: 103px;
    height: 20px;
    border-radius: 4px
}

.top-predictions__oc-group-name-loader {
    margin-top: 12px;
    width: 46px;
    height: 20px;
    border-radius: 4px
}


@media screen and (max-width: 768px) {
    .top-game-predictions {
        padding: 0 16px;
    }

    .prediction-place-header,
    .prediction-place-cell {
        border-right: 1px solid #F2F3F7;
    }

    .game-top-predictions__table-wrapper{
        width: 100%; 
        overflow-x: auto;
        overflow-y: hidden;
        border: 1px solid #F2F3F7;
        border-radius: 6px;
    }

    .game-top-predictions__table {
        min-width: 600px;
        table-layout: fixed;
        width: auto;
        border: none;
        border-spacing: 0;
    }

    .game-top-predictions__table  .tooltip-text{
        width: 140px !important;
        height: fit-content !important;
        top: 120%;
    }

    .game-top-predictions__table  .tooltip-text::after{
        top: 0; 
        left: 50%;
        transform: translate(-50%, -100%); 
        border-width: 6px; 
        border-style: solid;
        border-color: transparent transparent #131520 transparent;
    }

    .top-predictions-table__row{
        height: 76px;
    }

    .prediction-place-cell img{
        width: 24px;
        height: 24px;
    }

    .prediction-place-header,
    .prediction-place-cell{
        width: 40px !important;
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: #fff;
    }

    .prediction-name-header,
    .prediction-name-cell{
        width: 190px;
    }

    .prediction-count-header{
        width: 100px;
    }

    .prediction-avg-header{
        width: 95px;
    }

    .prediction-coins-header{
        width: 120px;
    }
}