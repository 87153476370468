.horizontal-chart-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 32px;
    background-color: #fff;
}

.chart-title-wrapper{
    display: flex;
    gap: 4px;
    align-items: center;
}

.chart-title-img{
    width: 21px;
    height: 24px;
}

.chart-title{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.scale{
    display: flex;
    justify-content: space-between;
    position: relative;
}

.scale-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
}

.scale-number{
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

.scale-delimeter{
    width: 2px;
    height: 20px;
    background-color: #208B8E;
    border-radius: 6px;
}

.scale-title{
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #7F87B3;
}

.middle-scale-item{
    align-items: center;
}

.max-scale-item{
    align-items: end;
}

.scale-progress{
    position: absolute;
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #DEF7F8, #208B8E);;
    top: 46px;
}

.chart-area-inner{    
    display: flex;
    gap: 16px;
}

.chart-area-y{
    width: 75px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chart-area-y__item{
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    /* line-height: 16px; */
    color: #7F87B3;
    vertical-align: middle;
}

.chart-area{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    border-left: 1px solid #F2F3F7;
    border-bottom: 1px solid #F2F3F7;
}

.chart-area__item{
    height: 24px;
    width: 100%;
    background: linear-gradient(90deg, rgba(32, 139, 142, 0.4), rgba(32, 139, 142, 0.4));
    border-radius: 0 6px 6px 0;
    transition: all .3s ease-in-out;
}

.chart-tooltip-wrapper:hover .chart-area__item{
    background: linear-gradient(90deg, rgba(32, 139, 142, 0.8)
    , rgba(32, 139, 142, 0.8));
    transition: all .3s ease-in-out;
}

.chart-area-lines{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.chart-area-line{
    width: 100%;
    border-right: 1px solid #F2F3F7;
}

.chart-area-x{
    margin-top: 10px;
    display: flex;
    padding-left: 75px;
    justify-content: space-between;
    color: #7F87B3;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
}

.chart-area-x__item.item25{
    padding-left: 25px;
}

.chart-area-x__item.item50{
    padding-left: 20px;
}

.chart-area-x__item.item75{
    padding-left: 25px;
}

.horizontal-chart__loader-wrapper{
    width: 100%;
    height: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .horizontal-chart-wrapper{
        padding: 0 24px;
    }  
}

@media screen and (max-width: 768px) {
    .horizontal-chart-wrapper{
        padding: 0 16px;
    }  
    
    .chart-area-inner{
        gap: 8px;
    }

    .chart-area-y{
        width: 90px;
    }
}
