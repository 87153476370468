.ad-wrapper,
.ad2-wrapper {
    height: 492px !important;
    margin-top: 16px;
}

.ad2-wrapper {
    position: sticky;
    top: 16px;
}

.list-add__wrapper {
    border-top: 1px solid #F2F3F7;
    padding: 16px;
    height: 202px !important;
}

@media screen and (max-width: 768px) {

    .ad-wrapper,
    .ad2-wrapper {
        height: 370px !important;
        margin-top: 16px;
    }

    .list-add__wrapper {
        height: 388px;
    }

}