.betSlip-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-right: 32px;
}

.betSlip-ClearAll {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    cursor: pointer;
}

.betSlip-ClearAll:hover {
    text-decoration: underline;
}

.betSlip-parlay,
.errorTeam-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.full-time-result-containt span,
.total-odds,
.predication-decription-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.selected-team-name,
.errorname-sub-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.odds-remove-btn {
    padding-right: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.odds-details {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #F2F3F7;
}

.odds-score,
.total-score {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #131520;
}

.betSlip-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 210px;
    height: 100%;
    padding-right: 25px;
}

.prediction-slip-section {
    padding-right: 27px;
}

.betSlip-scroll::-webkit-scrollbar {
    width: 5px;
}

.betSlip-scroll::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.addPrediction-right-panel .card-body {
    padding-left: 32px;
    padding-right: 5px;
    padding-bottom: 32px;
    padding-top: 32px;
}

.total-odds-section {
    padding-right: 27px;
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
}

.predication-decription-title {
    margin-bottom: 8px;
}

.betSlip-textArea {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    min-height: 120px;
    height: 100%;
}

.place-prediction-btn {
    width: 100%;
    padding: 12px 16px;
    background-color: #0d393a;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    line-height: 24px;
    border: none;
    border-radius: 6px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.place-prediction-btn .spinner-border {
    width: 20px;
    height: 20px;
}


.place-prediction-btn span {
    padding-right: 8px;
}

.Bet-total-title {
    padding-bottom: 10px;
}

.Bet-total-input-field {
    padding: 16px 0px 16px 16px;
    border: 1px solid #dfe1ec;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    cursor: pointer;
}

.Bet-total-input-field-inner-content {
    position: absolute;
    /* width: 100%; */
    right: 0;
    padding: 16px 45px 16px 0px;
}

.Bet-total-input-field-inner-content .CAP-title,
.Bet-total-input-field-inner-content .Max-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.Bet-total-input-field-inner-content .CAP-title,
.Available-title {
    color: #7F87B3;
}

.Bet-total-input-field-inner-content .Max-title {
    color: #208B8E;
    cursor: pointer;
    padding-left: 12px;
}

.Available-title,
.coin-count {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}

.saveCancel-decription-data {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.coin-count,
.getCPA-coin-tooltip .Learn-more,
.saveCancel-decription-data {
    color: #208B8E !important;
}

.saveCancel-decription-data:hover {
    text-decoration: underline;
    cursor: pointer;
}

.Bet-total-input-field-inner-content .Max-title:hover,
.getCPA-coin-tooltip .Learn-more:hover {
    text-decoration: underline;
    cursor: pointer;
}

.Bet-total-input-field::placeholder {
    color: #7f87b3 !important;
}

.Bet-total-input-field::placeholder {
    color: #7f87b3 !important;
}

.Bet-total-input-field:-moz-placeholder {
    color: #7f87b3 !important;
}

.Bet-total-input-field::-moz-placeholder {
    color: #7f87b3 !important;
}

.Bet-total-coin-container {
    padding-right: 27px;
    padding-bottom: 8px;
}

.Bet-total-input-field:focus {
    box-shadow: none;
    border-color: #DFE1EC;
}

.available-coin-tooltip-section {
    margin-right: 27px;
    padding-bottom: 24px;
    border-bottom: 1px solid #F2F3F7;

}

.getCPA-coin-tooltip {
    padding: 8px 16px 8px 16px;
    border: 1px solid #DEF7F8;
    background-color: #F7FDFD;
    border-radius: 6px;
}

.icon-description-section .title,
.getCPA-coin-tooltip {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.Add-description-section .title,
.Add-description-section .sub-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.Add-description-section {
    padding-right: 27px;
}

.Add-description-section .sub-title {
    color: #7F87B3 !important;
}

.Add-description-hover:hover .title {
    border-bottom: 1px solid #131520;
    /* text-decoration: underline; */
    cursor: pointer;
}

.Add-description-hover:hover .sub-title {
    border-bottom: 1px solid #7F87B3 !important;
    cursor: pointer;
}

.transition-div {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    position: absolute;
}

.transition-div.show {
    opacity: 1;
    visibility: visible;
    position: relative;
}

.place-prediction-btn:disabled,
.place-prediction-btn[disabled] {
    background-color: #0D393A;
    opacity: 40%;
}

@media screen and (max-width: 1297.99px) {
    .responsive-addPrediction-right-panel {
        padding: 0px;
    }

    .Bet-total-coin-container,
    .Add-description-section {
        padding-right: 19px;
    }

    .predication-decription-section {
        padding-right: 0px !important;
    }
}


/* --------------- */
.alert-success {
    max-width: 476px;
    width: 100%;
    display: none;
    position: fixed;
    top: 75px;
    right: 20px;
    padding: 24px;
    border-radius: 6px;
    border: 1px solid var(--neutral-n-50, #F2F3F7);
    background: var(--neutral-n-0, #FFF);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.BetSlip-col {
    z-index: 10;
}

/* .visible {
    display: block;
    animation: slideIn 0.5s ease-in-out;
}

.hidden {
    animation: slideOut 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
} */

.tooltip-center-part {
    padding-left: 12px;

}

.tooltip-center-part .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #131520;
}

.tooltip-center-part .sub-title {
    font-size: 16px;
    line-height: 24px;
    max-width: 336px;
    width: 100%;
    color: #7F87B3 !important;
}

.go-to-Predictionlist {
    background-color: #0D393A;
    width: 100%;
}

/* pop up */
.prediction-list-error-popup .notification:before {
    display: none !important;
}

.prediction-list-error-popup .notification-container {
    max-width: 476px;
    width: 100%;
    top: 62px;
}

.prediction-list-error-popup .notification {
    /* position: fixed; */
    /* top: 75px;
    right: 20px; */
    padding: 24px;
    border-radius: 6px;
    color: #0D393A;
    border: 1px solid var(--neutral-n-50, #F2F3F7);
    background: var(--neutral-n-0, #FFF);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    opacity: unset;
}

.error-msg-teamname-msg {
    padding-top: 8px;
}



/* .prediction-list-error-popup .notification:last-child {
    display: none;
} */