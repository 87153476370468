/*------ signup css -------- */

.signup-container {
  padding: 60px;
}

.btn-google {
  margin-right: 8px;
}

.btn-facebook {
  margin-left: 8px;
}

.signUp-page-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #131520;
}

@media only screen and (max-width: 768px) {
  .signup-container {
    padding: 25px;
  }
}

@media screen and (min-device-width: 269px) and (max-device-width: 310px) {
  .signup-card {
    margin-top: 15px;
  }

  .btn-google-facebook {
    padding: 9px 3px;
  }
}

/* ------- footer -------- */

.register-footer {
  color: #7f87b3;
  text-align: center;
  text-decoration: none !important;
  font-size: 14px;
  line-height: 20px;
}

.signup-footer {
  color: #7f87b3;
  text-decoration: none !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
}

.signup-footer-link {
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #4c5480;
  font-weight: 400;
}

.signup-footer-link:hover {
  text-decoration: underline;
  color: #4c5480;
}

/* ------ register css ------- */

.register-container {
  padding: 60px;
}

@media only screen and (max-width: 768px) {
  .register-container {
    padding: 25px;
  }
}

/* --------- settingup Account ----------- */
.settingupaccount-card {
  padding: 64px 48px 64px 48px;
  /* width: 511px; */
  height: 452px;
}

@media only screen and (max-width: 768px) {
  .settingupaccount-card {
    padding: 0.5rem !important;
  }
}

.settingaccount-card-body {
  margin-bottom: -15px;
}

.card.signup-main-card {
  padding: 64px 48px 64px 48px;
  /* max-width: 511px;
  width: 100%; */
  border: 1px solid #dfe1ec;
}

.signup-card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #131520;
  text-align: center;
}

.signup-subtitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7f87b3 !important;
  text-align: center;
}

.login-link {
  font-weight: 400;
  line-height: 24px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
}

.btn.btn-google-facebook,
.btn.btn-google {
  transition: all 0.2s ease-in-out;
}

.btn.btn-google-facebook:hover,
.btn.btn-google:hover {
  border: 1px solid #bfc3d9;
}

/* how to apply fix width */
/* .test{
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
} */

/* nav css  */
.nav-left-side {
  max-width: 335px;
  width: 100%;
  /* margin: 0 auto; */
}