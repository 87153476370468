.chat__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.chat__messages-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.chat__messages-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 24px;
}

.chat__messages-no_message {
  display: flex;
  padding: 20px 24px;
}

.chat__messages-no_message-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
  font-size: 14px;
  line-height: 20px;
  gap: 8px;
  width: 100%;
}

.chat__messages-no_message-title {
  font-weight: 600;
}

.chat__messages-no_message-description {
  text-align: center;
  font-weight: 300;
  color: #7F87B3;
}

.loading-more-msg__loader-wrapper {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  gap: 12px;
  opacity: 1;
}

.loading-more-msg__loader-wrapper.hidden {
  opacity: 0;
}

.loading-more-msg__loader-wrapper .circle-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid black;
  width: 20px;
  height: 20px;
}

.close-btn {
  cursor: pointer;
}

.new_messages-notification {
  display: flex;
  border-radius: 24px;
  width: fit-content;
  white-space: nowrap;
  padding: 6px 12px;
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  gap: 8px;
}

.chat__input::-webkit-scrollbar,
.chat__messages-section::-webkit-scrollbar {
  width: 5px;
}

.chat__input::-webkit-scrollbar-thumb,
.chat__messages-section::-webkit-scrollbar-thumb {
  background-color: #BFC3D9;
  border-radius: 6px;
}

.chat__message-wrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  max-width: 100%;
  box-sizing: border-box;
}

.chat__message-wrapper .read-more-less_btn {
  color: #208B8E;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}

.chat__input-form_wrapper {
  border-top: 1px solid #F2F3F7;
}

.chat__input-wrapper .user-avatar,
.chat__message-wrapper .user-avatar {
  min-width: 32px;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.message__msg-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 90%;
  flex-grow: 1;
}

.message__msg-title {
  display: inline-block;
  gap: 4px;
  width: 100%;
  word-wrap: break-word;
}

.message__msg-url {
  color: #208B8E;
  cursor: pointer;
  text-decoration: none;
}

.message__msg-url:hover {
  color: #208B8E;
  text-decoration: underline;
}

.message__msg-username {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  flex-grow: 1;
  color: #000;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.message__msg-username:hover {
  color: #000;
  text-decoration: underline;
}

.chat__separator {
  color: #DFE1EC;
  padding: 0 4px;
}

.message__msg-date {
  width: fit-content;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #7F87B3;
  white-space: nowrap;
}

.message__msg-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  word-break: break-word;
}

.message__msg-main_txt {
  white-space: pre-wrap;
  word-break: break-word;
  text-indent: 0;
}

.message__msg-text .tooltip-wrapper {
  display: inline;
  cursor: pointer;
}

.message__msg-edited {
  font-size: 12px;
  line-height: 16px;
  color: #7F87B3;
}

.message__msg-controls {
  display: flex;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #7F87B3;
}

.message__msg-controls div {
  cursor: pointer;
}

.message__msg-controls div:hover {
  text-decoration: underline;
}

.chat__input-form {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
  padding: 20px 24px;
  position: relative;
  transition: all 0.5s ease;
}

.chat__input-form.active {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: 216px;
  transition: all 0.5s ease;
}

.chat__input-form.active .chat__input-wrapper {
  align-items: flex-start;
}

.chat__input-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  position: relative;
  align-items: center;
  height: auto;
}

.chat__input {
  max-width: 100% !important;
  width: 100% !important;
  height: 40px;
  max-height: 100%;
  padding: 10px;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: #131520;
  cursor: text;
  /* transition: height 0.5s ease; */
}

.chat__input:focus {
  border: none !important;
  outline: none;
}

.chat__input::placeholder {
  color: #7F87B3;
}

.chat__input-form.active .chat__input {
  min-height: 100px;
  height: 100px;
  overflow: hidden;
  resize: none;
  /* transition: height 0.5s ease; */
}

.chat__replayTo-wrapper {
  margin-top: 16px;
  padding: 0 20px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.chat__replayTo-title {
  font-weight: 600;
}

.chat__replayTo-user {
  text-decoration: none;
  color: #208B8E;
  margin-right: 4px;
}

.chat__replayTo-user:hover {
  text-decoration: underline;
  color: #208B8E;
}

.chat__input-form.active .chat__input-controls {
  width: 100%;
}

.chat__message-limit {
  width: 100%;
  text-align: end;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
}

.chat__message-limit.limited {
  color: #D14343;
  display: flex;
  justify-content: space-between;
}

.chat__input-controls {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.emoji-wrapper {
  position: absolute;
  bottom: 64px;
  width: 90%;
}

em-emoji-picker {
  width: 100%;
}


.epr_q53mwh {
  display: none;
}

::part(button) {
  background-color: black !important;
}


.chat__input-controls-btn {
  display: flex;
  gap: 8px;
}

.chat__send_btn {
  height: 40px;
  background-color: #0D393A;
  padding: 8px 16px;
  width: fit-content;
}

.chat__cancel_btn {
  padding: 8px 16px;
  height: 40px;
}

.chat__emoji_btn {
  cursor: pointer;
  padding: 5px;
  border-radius: 6px
}

.chat__emoji_btn.active {
  background-color: #F2F3F7;
}

.chat__emoji_btn:hover {
  background-color: #F2F3F7;
}

.remove-game-msg__modal .modal-dialog {
  width: 400px;
}

.remove-game-msg__modal .modal-content {
  padding: 24px;
}

.chat-modal__warning-text {
  color: #5F69A0;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.remove-game-msg__modal .chat-modal__controls {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.open-msg-url__modal .chat-modal__controls {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 8px;
}

.open-msg-url__modal .chat-modal__controls .cancel-btn {
  width: 100%;
}


.chat__input-div {
  width: 100%;
  border: 1px solid;
  height: 200px;
}

.chat__wrapper .tooltip-text {
  white-space: nowrap;
  width: fit-content !important;
}

@media only screen and (max-width: 1280px) {
  .chat-showall-msg_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .chat-showall-msg {
    width: fit-content;
  }

  .chat__input-form {
    padding: 16px 24px;
  }

  em-emoji-picker {
    height: 365px;
  }
}

@media only screen and (max-width: 1024px) {
  .chat__messages-section {
    gap: 24px;
  }
}

@media screen and (max-width: 768px) {

  .chat__messages-section,
  .chat__input-form {
    padding: 16px;
  }

  .chat-showall-msg {
    width: 100%;
  }

  .remove-game-msg__modal .modal-dialog {
    max-width: unset;
    width: unset;
  }
}