.login-container {
  height: 1400px;
  padding: 55px;
}

.login-containt {
  background-color: #F6F6F7 !important;
}

.login-card {
  padding: 64px 48px 64px 48px;
  max-width: 511px;
  width: 100%;
  border: 1px solid #dfe1ec;
}

/* @media screen and (max-width: 500px) {
  .login-section {
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .login-container {
    padding: 0 !important;
  }
} */
.login-input-label {
  display: flex;
  justify-content: space-between;
}

.login-avatar {
  display: flex;
  justify-content: center;
}

.welcome-carl p {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #131520;
}

.login-email {
  font-weight: 600;
  font-size: 14px;
  color: #131520;
  line-height: 24px;
}

.login-change-email {
  color: #7f87b3;
  text-decoration: none;
  font-weight: 300;
}

.useName-letter {
  margin-bottom: 0rem !important;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.forgot-password {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f87b3;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
  color: #4c5480;
}

.login-change-email {
  transition: all 0.2s ease-in-out;
}

.login-change-email:hover {
  text-decoration: underline;
  color: #4c5480;
}

.form-input-field {
  transition: all 0.2s ease-in-out;
  cursor: text;
}

.form-input-field:hover {
  border-color: #bfc3d9;
}

.form-input-field:focus {
  border-color: #bfc3d9;
  outline: none;
  box-shadow: none;
}

.form-input-field:focus-visible {
  border-color: #bfc3d9;
  outline: none;
  box-shadow: none;
}

.form-input-field {
  color: #131520 !important;
}

input[type="password"] {
  color: #131520 !important;
}

/* .input-container {
    display: -ms-flexbox; 
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
  } */

.profile-avtar {
  width: 64px;
  height: 64px;
  background-color: #f2f3f7;
}