.prediction-details-modal .modal-dialog,
.prediction-details-modal .modal-content {
    max-width: 800px;
    width: 800px;
}

.prediction-details-modal .modal-header {
    display: none;
}

.prediction-details-modal-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.prediction-details-video-wrapper {
    width: 100%;
    height: 450px;
    position: relative;
    box-sizing: content-box;
    aspect-ratio: 16 / 9;
}

.demo-canvas-frame{
    width: 100%;
}

.prediction-details-video-wrapper iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    aspect-ratio: 16 / 9;
}

.prediction-details-text__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.prediction-details-text__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.prediction-details-text__description,
.prediction-details-text__bottom-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #7F87B3;
}

.prediction-details-text__description ul {
    list-style-type: none;
    padding-left: 16px;
}

.page-detail-modal-control {
    display: flex;
    gap: 16px
}

@media screen and (max-width: 768px) {
    .prediction-details-modal .modal-dialog,
    .prediction-details-modal .modal-content {
        max-width: 97%;
        width: 100%;
    }

    .prediction-details-modal .modal-content {
        padding: 16px;
    }

    .prediction-details-video-wrapper {
        height: auto;
    }

    .page-detail-modal-control {
        flex-direction: column;
        gap: 8px;
    }

    .modal-close-btn{
        order: 2
    }

    .modal-open-btn{
        order: 1
    }
}