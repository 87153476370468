.dicription-card {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
}

.dicription-card:first-child {
    padding-left: 12px;
}

.dicription-card-group {
    padding-top: 32px;
}

.card-description {
    padding: 24px;
    border: 1px solid#F2F3F7;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-description .team1-logo {
    margin: unset;
}

.card-description .team1-logo,
.card-description .team2-logo {
    width: 80px;
    height: 80px;
    background-color: #F2F3F7
}

.card-description .both-Team-logo {
    padding-right: 0px;
}

.card-description .both-team-name,
.Participants-card-point {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.Participants-card {
    height: 100%;
    padding: 12px 16px;
    background: #F7FDFD;
    border: 1px solid #DEF7F8;
    border-radius: 4px;

}



.Participants-card-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    padding-bottom: 6px;
}

.Participants-card .emptyMsg {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #0B0D13;
}

.JoinGiveaway-btn {
    width: 100%;
}

.Participants-card-point {
    color: #131520;
}

/* 
.Participants-card:first-child {
    padding: 18px 12px;

} */

.Joined-btn {
    background-color: #ffffff;
    margin-top: 24px;
    width: 100%;
    padding: 12px;
    color: #0D393A;
    border: 1px solid #0D393A;
}

.Calculation-btn {
    margin-top: 24px;
    width: 100%;
    padding: 12px;
    color: #C09E16;
    border: 1px solid #C09E16;
}

.GiveawayIsFinished-btn {
    width: 100%;
    opacity: 0.5;
    padding: 12px;
    border: 1px solid #DFE1EC;
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.Winner-userName {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.Participants-avatar img {
    border: 1px solid#F2F3F7;
    border-radius: 50px;
}

.Participants {
    margin-left: -12px;
}

/* .Participants:first-child {
    margin-left: 0px;
} */

.Participants-avatar-data {
    border: 1px solid#F2F3F7;
    padding: 8px 6px;
    margin-left: -10px;
    border-radius: 50px;
    display: flex;
    background-color: #0D393A;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    justify-content: center;
}

.Participants-avatar {
    margin-left: 8px;
}

.username-avatar-data {
    border: 1px solid #F2F3F7;
    padding: 8px 6px;
    margin-left: -10px;
    border-radius: 50px;
    display: flex;
    background: #FFFFFF;
    color: #131520;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    height: 32px;
    width: 32px;
    justify-content: center;
}

/* model++++++ */
.createGiveAways-model .modal-header {
    padding-left: 32px;
    padding-right: 27px;
    padding-top: 0px;
    padding-bottom: 6px;
}

.createGiveAways-model .modal-content {
    padding-left: 0;
    padding-right: 5px;
}

.createGiveAways-model .popular-leagues {
    padding: 10px 16px;
}

.createGiveAways-model .country-leaguees-containts {
    cursor: pointer;
}

.createGiveAways-model .Search-team-name {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
}

.createGiveAways-model .Search-team-name:hover {
    background-color: #F2F3F7;
    cursor: pointer;
    border-radius: 6px;
}

.Search-team-name .team1-logo,
.Search-team-name .team2-logo {
    background-color: #F2F3F7;
    width: 24px;
    height: 24px;
}

.Search-team-name .leagues-name {
    font-weight: 600;
}

.createGiveAways-model .modal-dialog {
    max-width: 484px;
    width: 100%;
}

.giveAways-SеtAnAward {
    padding-right: 32px;
    padding-left: 32px;
}

.giveAways-SеtAnAward .inputfield-label {
    padding-bottom: 0px;
    margin-top: 0px;
}

.giveAways-SеtAnAward .inputfield-tab {
    max-width: 100%;
}

/* tounament-detail-page */
.tournament-detail-Reward-card,
.tournament-detail-Participants-card {
    min-width: 376px;
    width: 100%;
    margin: auto;
    padding: 24px 32px;
    background: #F9F9FB;
    border-radius: 6px;
}

.tournament-detail-page-top .Participants-card-point {
    font-size: 20px;
}

.tournament-detail-page-section {
    border-bottom: 1px solid #f2f3f7;
    padding-bottom: 64px;
}

.participant-div {
    margin-left: 10px;
}

.GiveAways-icon-btn {
    max-width: 109px;
    width: 100%;
    cursor: pointer;
    padding: 4px 8px 4px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
    margin-bottom: 32px;
}

.tournament-detail-sport-name {
    padding-bottom: 32px;
}

.tournament-detail-teams-detail {
    max-width: 607px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.opps_team1-avatar,
.opps_team2-avatar {
    width: 140px;
    height: 140px;
    background-color: #f2f3f7;
    border: 2px solid #FFFFFF;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.set-opps_team1-2-avatar-name {
    max-width: 140px;
    width: 100%;
}

.tournament-opps_team1-name,
.tournament-opps_team2-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131520;
    padding-top: 12px;
}

.team-time-scrore-div {
    max-width: 199px;
    width: 100%;
}

.team-time-scrore-div .time {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #131520;
    text-align: center;
}

.team-time-scrore-div .date {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
    text-align: center;
}

.team-score-box {
    padding: 12px 24px;
    background: #F2F3F7;
    border-radius: 6px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #131520;
    display: flex;
    justify-content: center;
    align-items: center;
}

.giveaway-Rules-card {
    max-width: 376px;
    width: 100%;
    padding: 32px;
    border: 1px solid #f2f3f7;
    border-radius: 6px;
}

.giveaway-Rules-card .title,
.Take-giveaway-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131520;
}

.Ellipse-design {
    margin-right: 17px;
}

.tournament-detail-page-bottom {
    padding-bottom: 90px;
}

.social-media-title,
.active-makePrediction-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0B0D13;
}

.TakePartSubTitle,
.makePrediction-SubTitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.toggle-line {
    border-right: 2px solid #DFE1EC;
    min-height: 115px;
    position: relative;
    left: -18px;
    top: 10px;
}

.makePrediction-title,
.more-or-count-predictions {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;

}

.prediction-data-tab {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    padding: 12px 24px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 8px;

}

.prediction-data-tab:hover {
    border: 1px solid #DFE1EC;

}



/* progress bar slider */
/* 
.makePrediction-progressbar .wrapper {
    width: 520px;
    max-width: 100%;
} */

.makePrediction-progressbar .progress-bar {
    min-height: 48px;
    height: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    padding: 12px 24px;
    border: 1px solid #A4EBE1;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

*+.progress-bar {
    margin-top: 2rem;
}

.bar {
    width: 0;
    position: absolute;
    width: 0;
    /* padding: 12px 24px; */
    left: 0;
    height: 100%;
    background-color: #F2FBF9;
    border-radius: 6px;
    background-size: 30px 30px;
    animation: move 2s linear infinite;
    transition: width 2s ease-out;
}

/*Lollipop background gradient animation*/
@keyframes move {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 30px 30px;
    }
}

.more-or-count-predictions:hover {
    text-decoration: underline;
}

.progress-bar:hover .user-bar {
    background-color: #A4EBE1 !important;
}

.progress-bar:hover .bar {
    background-color: #D1F6F0;
}

.userList-model-subtitle {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #7F87B3;
}

.userList-model-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.userList-model-name:hover {
    text-decoration: underline;
    cursor: pointer;
}

.userList-avatar {
    border-radius: 50px;
    background-color: #f2f3f7;
    width: 32px;
    height: 32px;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    font-family: SF UI Display Heavy;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0d393a;
}

.insta-username .name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #208B8E;
}

.insta-username {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.Winner-teamname-dollerimg {
    background-color: #0D393A;
    border-radius: 6px;
}

.Winner-teamname {
    padding: 32px 0px 32px 32px;
}

.Winner-team-avatar {
    background-color: #DFE1EC;
    width: 44px;
    height: 44px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: SF UI Display Heavy;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #131520;
    border: 2px solid #F2F3F7;
    border-radius: 50px;
}

.Winner-team-img {
    border: 1px solid #F2F3F7;
    border-radius: 50px;
}

.Winner-team-name,
.perfect-prediction-team-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.winner-team-card,
.perfect-prediction-card {
    margin-bottom: 32px;
}

.Winner-team-username,
.perfect-prediction-team-username {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

/* table */
.Perfect-predictors-div {
    padding-top: 32px;
}

.Perfect-predictors-table-div {
    padding: 32px 32px 8px 32px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

.Perfect-predictors-table-div .Top-predictions-row {
    padding: 0px;
    align-items: inherit;
}

.Perfect-predictors-table-div .Top-predictions-teams-detail {
    padding: 19px 24px 19px 0px;
    border-bottom: 1px solid #F2F3F7;
}

.Perfect-predictors-table-div .Predictiontype-decription {
    padding: 19px 0px 19px 24px;
    border-bottom: 1px solid #F2F3F7;
}

.Perfect-predictors-table-div .Odds-decription {
    padding: 19px 0px 19px 16px;
    border-bottom: 1px solid #F2F3F7;
}

.tournament-detail-Participants-card .username-avatar-data {
    height: 36px;
    width: 36px;
}

.Winner-card {
    background: #F2FBF9;
    border: 1px solid #208B8E;
    border-radius: 6px;
    padding: 19px 24px 19px 24px;
}

.prediction-card {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    padding: 19px 24px 19px 24px;

}

.badge-dot {
    width: 6px;
    height: 6px;
    /* padding: 6px; */
    background-color: #208B8E;
    border-radius: 50px;
}

.badge-score {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0D393A;
}

.badge-div {
    background: #EEFBFB;
    border-radius: 16px;
    padding: 2px 8px 2px 6px;
}

.perfect-prediction-team-name:hover {
    text-decoration: underline;
    cursor: pointer;
}

.noPerfectPredictorsline {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}


/* table */
.Participants-table {
    max-width: 840px;
    width: 100%;
    padding: 32px 32px 8px 32px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

.Participants-table-user,
.Participants-table-user-contains {
    min-width: 250px;
    width: 100%;
}

.Participants-table-Instagram,
.Participants-table-TikTok,
.Participants-table-Instagram-contains,
.Participants-table-tiktok-contains {
    min-width: 200px;
    width: 100%;
}

.Participants-table-Confirm-winner,
.Participants-table-Confirm-winner-contains {
    min-width: 126px;
    width: 100%;
}

.Participants-table-Instagram,
.Participants-table-TikTok,
.Participants-table-Instagram-contains,
.Participants-table-tiktok-contains {
    padding-left: 24px;
}

.Participants-table-Confirm-winner,
.Participants-table-Confirm-winner-contains {
    padding-left: 15px;
}

.predictions-table-row {
    padding: 19px 24px 19px 0px;
}

.Participants-table-Instagram-contains,
.Participants-table-tiktok-contains {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #208B8E;
}

.Participants-table .container-checkbox .checkmark {
    top: -4px;
    margin-left: 0px
}

@media screen and (max-width: 431px) {
    .toggle-line {
        min-height: 210px !important;
    }

    .tournament-detail-Reward-card,
    .tournament-detail-Participants-card {
        min-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .tournament-detail-page-top-mobile-responsive {
        margin-top: 16px !important;
        flex-direction: column;
        padding: 0px 16px;
    }

}

@media screen and (max-width: 1024px) {
    .dicription-card-group .both-team-contains {
        display: block;
    }

    .EditUsernamesBtn {
        min-width: 100%;
    }

    .mobile-team-score-box {
        padding: 8px 16px;
        background: #F2F3F7;
        border-radius: 6px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #131520;
        text-align: center;
        max-width: 67px;
        width: 100%;
        margin: 0 auto;
    }

    .mobile-Winner-team-name {
        display: block !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #4C5480;
    }

    .mobile-Winner-div {
        margin-top: 12px;
    }

    .Participants-table-Instagram-contains,
    .Participants-table-tiktok-contains {
        text-align: end;
    }

    .Participants-table .Top-predictions-tabletitle {
        display: none;
    }

    .Participants-table .Top-predictions-teams-bitton-div {
        flex-direction: column;
        align-items: unset !important;
    }

    .Participants-table-Confirm-winner-contains {
        align-items: center;
        justify-content: end !important;
        width: unset;
    }

    .Participants-table {
        max-width: 100%;
        padding: 16px 16px 8px 16px;
    }

    .Participants-table .container-checkbox .checkmark {
        margin-left: 16px
    }

    .Participants-table-Confirm-winner-contains .tournament-detail-page-top-mobile-responsive {
        flex-direction: column;
        padding: 0px 16px;
    }

    .Take-giveaway-Rules-card {
        flex-direction: column-reverse;
    }

    .tournament-detail-page-section {
        padding-bottom: 32px;
    }

    .Take-giveaway-Rules-card {
        padding: 0px 16px;
    }

    .giveaway-Rules-card {
        max-width: 100%;
    }

    .opps_team1-avatar,
    .opps_team2-avatar {
        width: 64px;
        height: 64px;
    }

    .opps_team1-image {
        width: 46.08px !important;
        height: 46.08px !important;
    }

    .tournament-opps_team1-name,
    .tournament-opps_team2-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }

    .team-score-count-box {
        display: none !important;
    }

    .tournament-detail-Reward-card {
        margin-top: 24px;
    }

    .makePrediction-btn {
        min-width: 100% !important;
    }

    .mobile-team-score-box {
        display: block !important;
        padding: 8px 16px;
    }
}

.mobile-team-score-box,
.mobile-Winner-team-name {
    display: none;
}

.team-score-count-box {
    display: flex;
}

.opps_team1-image {
    width: 97.44px;
    height: 97.44px;
}