.add-predication-model .modal-dialog {
    max-width: 484px;
    width: 100%;
}

.add-predication-model .modal-header {
    margin-bottom: 0;
    padding: 6px 0px 22px 0px;
}

.add-predication-model .model-footer-part {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 18px 27px 0px 32px;
}

.empty-error-msg {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    margin-top: 18px;
}

.empty-error-msg-hide {
    display: none;
}

.empty-error-msg-show {
    display: block;
}

.add-predication-model .modal-content {
    padding-left: 0;
    padding-right: 5px;
}

.add-predication-model .modal-header {
    padding-left: 32px;
    padding-right: 27px;
}


.sport-container .searchbar {
    padding-bottom: 8px;
}

.leaues-country-container .searchbar,
.sport-container .searchbar {
    padding-left: 32px;
    padding-right: 27px;
}

.sports-main-container,
.leagues-all-leagues-container {
    padding-left: 32px;
    padding-right: 23px;
}

.add-some-predication-model .modal-dialog {
    max-width: 100%;
    width: 100%;
}

.add-some-predication-model {
    padding: 0px !important;
}

.add-some-predication-model .modal-content {
    max-width: 100%;
    width: 100%;
    margin-top: 50px;
    padding-top: 32px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 48px;
}

.add-some-predication-model .modal-header {
    margin-bottom: 32px;
    padding: 6px 0px 6px 0px;
}


/* --------- */

.modelSubTitle-ConfirmSubscription {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    margin-right: 16px;
}


.model-instragram-title .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.ConfirmSubscription-model .modal-dialog {
    max-width: 516px;
    width: 100%;
}

/* userList-model */

.userList-model .modal-header {
    padding: 6px 27px 6px 0px;
}

.userList-model .modal-content {
    padding: 32px 5px 32px 32px;
}

.userList-model-dateTime {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.userList-model-content {
    padding: 10px 27px 10px 0px;
}

.userList-model-scroll {
    max-height: 364px;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.userList-model-scroll::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.userList-model-scroll::-webkit-scrollbar {
    width: 5px;
    height: 124px;
}



/* exitConfirmtionModel css */
.exitConfirmtionModel-div .modal-header {
    padding-top: 4px;
}

.exitConfirmtionModel-div .coin-model-collected-message {
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}