.game-details__content-title.youtube {
    display: flex;
    justify-content: space-between;
}

.game-details__title-controls {
    display: flex;
    gap: 16px;
}

.game-details__title-controls .tooltip-text {
    width: fit-content !important;
}

.game-details__title-controls_edit,
.game-details__title-controls_remove {
    cursor: pointer;
}

.game-details__title-controls_edit:hover img,
.game-details__title-controls_remove:hover img {
    filter: brightness(0%);
}

.game-details__add-video-wrapper {
    height: 148px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.game-details__add-video-txt {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.game-details__remove-video-btn,
.game-details__add-video-btn {
    background-color: #0D393A;
    padding: 12px 16px;
    border-radius: 6px;
    color: #fff;
    width: fit-content;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.game-details__remove-video-btn:hover,
.game-details__add-video-btn:hover {
    background-color: #09292a;
}

.remove-game-video__warning {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #5F69A0;
}

.add-edit-game-video__input {
    width: 100%;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    border: 1px solid #DFE1EC;
    border-radius: 6px
}

.add-edit-game-video__input:focus {
    /* border: none !important; */
    border: 1px solid #BFC3D9 !important;
    outline: none;
}

.add-edit-game-video__input::placeholder {
    color: #7F87B3;
}

.game-video__controls {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-top: 16px;
}

.game-video__cancel-btn {
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #DFE1EC;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.game-video__cancel-btn:hover {
    border: 1px solid #BFC3D9;
}

.game-video__wrapper .game-details__add-video-btn,
.game-video__wrapper .game-details__remove-video-btn,
.game-video__cancel-btn {
    width: 100%;
    height: 48px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .game-details__video-overview-wrapper {
        padding: 24px 16px !important;
    }
}