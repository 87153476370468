.pending-notifications-mainDiv .notifications-count {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #131520;
    padding-right: 8px;

}

.pending-notifications-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #131520;
    padding-right: 8px;
}

.pending-notifications {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 16px 8px 16px;
    padding: 12px;
    background-color: #F2F3F7;
    border-radius: 6px;
}

.pending-notifications-mainDiv .clear-all {
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #208B8E;
    cursor: pointer;
}

.pending-notifications-mainDiv .clear-all:hover {
    text-decoration: underline;
}

.pending-notifications-mainDiv .viewAll {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #131520;
    cursor: pointer;
}

.notificationArrow-img {
    position: relative;
}

.viewAll-div:hover .notificationArrow-path {
    stroke: #0D393A;
}

.viewAll-div:hover .viewAll {
    color: #0D393A;
}

.No-new-notifications-div .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

.No-new-notifications-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 0;
}

.new-notificationsData-div {
    padding: 0px 3px 16px 16px;
}

.notification-detail {
    display: flex;
    align-items: flex-start;
    padding: 12px 16px 12px 16px;
    cursor: pointer;
}

.notification-detail:hover {
    background: #F2F3F7;
    border-radius: 6px;
}

.notification-detail .description {
    padding-left: 10px;
}

.notification-topic-title,
.accordion-notification-container .notification-subject {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.notification-tab-content .notification-container {
    position: unset;
    width: unset;
    max-height: unset;
}

.accordion-notification-container .notification-subject {
    /* min-width: 640px; */
    width: 100%;
    padding-left: 10px;
}

.notification-topic-detail,
.Accordion-Body-decription {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5F69A0;
}

.notification-topic-detail {
    max-width: 360px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification-topic-time {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

.new-notificationsData-scroll {
    max-height: 352px;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-right: 8px;
}

.new-notificationsData-scroll::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.new-notificationsData-scroll::-webkit-scrollbar {
    width: 5px;
    height: 124px;
}

/* notification - component */
.notification-section {
    background-color: #F9F9FB;
}

.notification-section .notification-tab {
    max-width: 1270px !important;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    border-bottom: 1px solid #F2F3F7;
    --bs-nav-tabs-border-width: 0;
    align-items: flex-end;
    /* margin-bottom: 24px; */
}

.notification-tab-content {
    background-color: #ffffff !important;
}

.notification-section .tab-content {
    max-width: 1270px !important;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.notificationpage-title {
    padding-top: 32px;
    padding-bottom: 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #131520;
}

#justify-tab-example-tab-Usersactivity {
    min-width: 98px !important;
    width: 100%;
}

.Empty-notification-div {
    padding-top: 88px;
    padding-bottom: 64px;
}

.notification-tab-content .tab-content .tab-pane {
    max-width: 100%
}

.Empty-notification-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Empty-notification-div .title,
.tournament-detail-Reward-card,
.tournament-detail-Participants-card,
.tournament-detail-sport-name,
.TakePartSubTitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
}

@media screen and (max-width: 768px) {
    .notification-section .container-1240 {
        padding: 0px 15px !important;
    }

    .collect-coin-model-div .modal-content {
        max-width: unset;
    }
}


.Accordion-body:hover {
    background: #F2F3F7;
    /* border-bottom: 1px solid #F2F3F7; */
    border-radius: 6px;
}

.accordion-notification-container {
    padding-top: 24px;
    padding-bottom: 24px;
}

.accordion-notification-container .Accordion-main-title {
    /* display: flex;
    justify-content: space-between;
    width: 100%; */
    cursor: pointer;
    padding: 16px 16px 0px 16px;
    /* border-bottom: 1px solid #F2F3F7; */
}

.Accordion-main-containt {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    margin-bottom: 4px;
}

.Accordion-Body-decription {
    max-width: 640px;
    width: 100%;
    margin-left: 45px;
    transition: all 0.3s ease-in-out;
    padding-bottom: 16px;
}

/* pagination */
.pagination-section {
    padding: 8px 0px 26px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Previous-btn,
.Next-btn {
    padding: 6px 16px 6px 16px;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    display: flex;
    align-items: center;
}

.notification-extra-page {
    min-width: 40px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 14px 10px 14px;
    color: #393F60;
}

.pageCount {
    margin-left: 1px;
    margin-right: 1px;
    min-width: 40px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 12px 10px 12px;
    color: #1D2939;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
}

.Accordion-body-hide-description {
    max-width: 599px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5F69A0;
    margin-left: 30px;
    transition: all 0.3s ease-in-out;
}

.notification-date-time {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

.accordion-notification-container .accordion-button .Accordion-body-hide-description {
    display: none;
}

.accordion-notification-container .accordion-button.collapsed .Accordion-body-hide-description {
    display: block;
}

.Accordion-section {
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #F2F3F7;
    cursor: pointer;
}

.Accordion-section:hover {
    background-color: #DFE1EC !important;
    border-radius: 6px;
}

.Accordion-section-active {
    background-color: #F2F3F7;
}

.pageCount:hover {
    background-color: #F2F3F7;
}


.Previous-btn:hover,
.Next-btn:hover {
    background: #F2F3F7;
    border: 1px solid #DFE1EC;
}

.Previous-btn:disabled,
.Next-btn:disabled {
    background: #ffffff;
}

/* notification-model */
.notification-modal .modal-content,
.notification-modal .modal-dialog {
    max-width: 764px;
    width: 100%;
    padding: 0px;
}

.notification-modal .modal-content {
    padding: 0px 3px 0px 0px;
}

.notifiction-model-container .team1-logo,
.notifiction-model-container .team2-logo {
    background-color: #F2F3F7;
}

.notifiction-model-container .modal-body {
    padding: 0px 0px 32px 0px;
}

.likedPredictionList-user {
    margin: 0px 27px 0px 32px;
}

.notification-modal .modal-header {
    padding: 0px;
    align-items: flex-start;
    padding: 32px 32px 0px 32px;
}

.notificationModel-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.notificationModel-time {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5F69A0;
    padding-top: 4px;
}

.notificationModel-body {
    padding: 0px 3px 32px 32px !important;
}

.notificationModel-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 358px;
}

.notificationModel-scroll::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.notificationModel-scroll::-webkit-scrollbar {
    width: 5px;
    height: 124px;
}

.notificationModel-body-description {
    padding-right: 24px;
}

.notification-ticket-part {
    padding-bottom: 16px;
}

.Not-calculated-prediction {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    padding-bottom: 16px;
}

.user-detailWholikedPrediction .user-name {
    padding-left: 8px;
    padding-right: 4px;
    cursor: pointer;
}

.user-detailWholikedPrediction .user-name:hover {
    text-decoration: underline;
}

.user-detailWholikedPrediction .description {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.user-detailWholikedPrediction {
    display: flex;
    align-items: center;
}

.likedPredictionList-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background: #F2F3F7;
    border-radius: 6px;
}

.prediction-all-oddsData-contains {
    border: 1px solid #DFE1EC;
    background: #F9F9FB;
    border-radius: 6px;
}

.sportName-tournamentNAme {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
    padding-bottom: 8px;
}

.prediction-all-oddsData-detail {
    padding: 8px 16px 8px 16px;
    border-bottom: 1px solid #DFE1EC;
}

.prediction-all-oddsData-detail:last-child {
    border-bottom: none;
}

.team1-team2-name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.prediction-odds-title .both-team-league-name {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #131520;
}

.prediction-odds-title .both-team-league-name span {
    font-weight: 600;
}

.prediction-odds-title .Prediction,
.prediction-odds-title .Odd {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

.prediction-odds-title {
    display: flex;
    justify-content: space-between;
}

.prediction-odds-title .odds-score {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #131520;
    padding-bottom: 12px;
    justify-content: center;
}

.notificationModel-body-description .single-ticket {
    padding-bottom: 16px;
    display: flex;
}

.notification-avt {
    background-color: #DFE1EC;
    border: 2px solid #FFFFFF;
}


.tab-part .nav-item {
    flex-basis: unset !important;
    flex-grow: unset !important;
}

.notifiction-model-container .both-team-league-time-sport {
    padding-top: 16px;
}

.notifiction-model-container .notificationModel-scroll {
    padding-bottom: 32px;
}

.notifiction-model-container .prediction-all-oddsData-contains {
    border: none;
    background: unset;

}

.notifiction-model-container .model-prdiction-card {
    margin-left: 32px;
    margin-right: 29px;
    margin-bottom: 24px;
}

.notification-modal__wrapper {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.notification-modal__description {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
}

.notification-modal__msgs {
    border: 1px solid #DFE1EC;
    padding: 16px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.msg-delimeter {
    width: 100%;
    height: 1px;
    background-color: #F2F3F7;
}

.notification-modal__msgs .chat__message-wrapper {
    padding: 8px;
}

.answer-msg {
    cursor: pointer;
}

.answer-msg:hover {
    background-color: #F2F3F7;
    border-radius: 6px
}

@media screen and (max-width: 768px) {

    .notification-modal .modal-content,
    .notification-modal .modal-dialog {
        max-width: unset;
        width: unset;
    }
}