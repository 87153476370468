.home-page-Accordion .accordion {
    border: none !important;
    --bs-accordion-border-color: #ffffff;
    --bs-accordion-border-width: none;
    --bs-accordion-border-radius: 0px;
    --bs-accordion-active-color: #131520;
}

.home-page-Accordion .accordion-button {
    padding: 10px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.home-accordion-decription {}

.home-accordion-Header {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;

}

.home-page-Accordion .accordion-button::after {
    background-image: url("/public/black-down.svg");
}

.home-page-Accordion .accordion-button:not(.collapsed)::after {
    background-image: url("/public/black-up.svg");
    width: 20px;
    transform: none;
}

.home-page-Accordion .accordion-item:first-of-type .accordion-button {
    border-radius: 6px;
    background-color: #F2F3F7;
    padding: 24px 32px 24px 32px;
}

.home-page-Accordion .accordion-body {
    padding: 0px;
}

.home-page-Accordion .accordion-button:focus {
    z-index: 1;
    border-color: #F2F3F7;
    outline: 0;
    box-shadow: none;
}

.home-page-Accordion .accordion-button:not(.collapsed) {
    background-color: #F2F3F7;
    /* border-top-left-radius: 6px;
    border-top-right-radius: 6px; */
    /* padding: 24px 32px 8px 32px !important; */
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    /* border-radius: 6px; */
}

.home-accordion-decription {
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    background-color: #F2F3F7;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;

}

.accordion-button:hover {
    /* border-radius: 6px; */
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.Product-Management-accordion {
    margin-top: 32px;
}