/* ----- Signin Capperaudior css -------- */ 



.signinCapperauditor-card{
    /* padding : 55px 48px 64px 48px; */
    width:511px ;
    height: 491px;
}

/* signin footer */
.signin-footer{
    color: #7F87B3;
    text-align: center;
    text-decoration: none !important;
  }
