.container-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18.5px;
    width: 18.5px;
    border: 1.5px solid #DFE1EC;
    border-radius: 6px;
    margin-left: 16px;
}

.container-checkbox input:checked~.checkmark {
    background-color: #0D393A;
    border: 1.5px solid #0D393A;
}

.container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox input:checked~.checkmark:after {
    display: block;
}

.container-checkbox .checkmark:after {
    left: 5px;
    top: 1.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}