/* .profile-section,
.setting-section,
.notification-section {
    margin-top: 76px;
} */

.sportline-containt-body {
    background-color: #F9F9FB !important;
}

.extra-odds-page {
    background-color: #FFF !important;
}

/* .sportline-body-overflow {
    overflow: hidden;
}

.sportline-body-overflow-scroll {
    overflow: auto !important;
} */

.sport-line-container .container-1240,
.sport-line-container .container-1440 {
    /* max-width: 1640px; */
    max-width: 100%;
    width: 100%;
}

.sport-line-home {
    padding: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    /* background-color: #F2F3F7;
    border-radius: 6px; */
}

.sport-line-live {
    display: flex;
    justify-content: center;
}

.sport-active-button {
    background-color: #F2F3F7;
    border-radius: 6px;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #000000 !important;
}

.sport-line-top-left-nav {
    display: flex;
    align-items: flex-start;
    padding-right: 16px;
    border-right: 1px solid #F2F3F7;
}

.sport-line-live {
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
    display: flex;
    cursor: pointer;
}

.sport-line-sportBtn div {
    justify-content: space-between !important;
}

.more-dropdown-content .sport-line-sportBtn div {
    justify-content: unset !important;
}

.sport-line-sportBtn,
.More-Label-div {
    padding: 12px;
}

.More-Label-div {
    border-radius: 6px;
    margin-bottom: 5px;
    /* position: fixed;
    top: 0px;
    right: 10px; */
}

.menu-item {
    white-space: nowrap;
}

.sport-line-sportBtn:hover,
.sport-line-live:hover,
.sport-line-home:hover {
    background-color: #F2F3F7 !important;
    border-radius: 6px;
    cursor: pointer;
}

.MoreLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.sportname-tournamentname-col {
    padding-left: 0px;
    /* min-height: 100vh;
    height: 100%; */
    /* padding: 0px 16px 16px 16px; */
}

.tournamentname-tab-col .addPrediction-tab .nav-tabs .nav-link.active {
    background-color: transparent
}

.sportname-tour-back {
    background-color: #ffffff;
    border-right: 1px solid #F2F3F7;
    border-left: 1px solid #F2F3F7;
    height: 100%;
    max-height: 100vh;
}

.sportbutton-tournament-country-div,
.live-sportline-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 100vh;
    height: 100%;
}

.live-sportline-scroll {
    position: sticky;
    top: 70px;
}

.sportlineLeagues-scroll {
    /* max-height: 20rem;
    overflow-y: scroll; */
    margin-right: 5px;
    margin-left: 8px;
    overflow-x: hidden;
}

.sportname-tour-back::-webkit-scrollbar,
.sportlineLeagues-scroll::-webkit-scrollbar,
.sportline-suggestion::-webkit-scrollbar-thumb,
.sportbutton-tournament-country-div::-webkit-scrollbar,
.sportlineLeagues-scroll::-webkit-scrollbar,
.live-sportline-scroll::-webkit-scrollbar {
    width: 5px;
}

.sportbutton-tournament-country-div::-webkit-scrollbar-thumb,
.sportlineLeagues-scroll::-webkit-scrollbar-thumb,
.live-sportline-scroll::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.sportname-tournamentname-col .sport-name,
.sportline-popular-league-title,
.sportline-suggestion .sportname-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

.sportname-tournamentname-col .sportline-popular-league-div .leagues-name {
    padding: 10px 16px 10px 16px;
    cursor: pointer;
}

.sportname-tournamentname-col .sportline-popular-league-div .leagues-name:hover,
.sportname-tournamentname-col .leagues-name:hover,
.home-page-Accordion .accordion-button:hover {
    background-color: #F2F3F7 !important;
    border-radius: 6px;
}

.sportname-tournamentname-col .sportline-popular-league-div .leagues-name:hover .leagues-game-score,
.sportname-tournamentname-col .leagues-name:hover .leagues-game-score,
.sportline-country-accordion .home-page-Accordion .accordion-button:not(.collapsed) .leagues-game-score,
.home-page-Accordion .accordion-button:hover .leagues-game-score {
    color: #0D393A;
    background: #DFE1EC;
}

.sportname-tournamentname-col .leagues-name {
    padding: 12px 16px 12px 16px;
    cursor: pointer;
    width: 100%;
}

.sportname-tournamentname-col .sportline-country-accordion .leagues-name:hover {
    color: #208B8E;
}

.sportname-tournamentname-col .sportline-country-accordion .leagues-name:hover .leagues-game-score {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #208B8E;
    background: #DEF7F8;
}

.leagues-game-score {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0D393A;
    background: #F2F3F7;
    padding: 2px 8px;
    border-radius: 16px;
}

@keyframes blinking {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.blinking-animation {
    animation: blinking 2s infinite;
    font-size: 24px;
}

@keyframes text-animation {
    0% {
        content: "LIVE";
    }

    100% {
        content: "BET";
    }

}

.live-animated-text::before {
    content: "LIVE";
    animation: text-animation 2s infinite;

}

.tournamentname-tab-col {
    /* max-width: 53.432%;
    width: 100%; */
    background-color: #F9F9FB;
    padding-left: 16px;
    padding-right: 16px;
}

.BetSlip-col {
    /* max-width: 28.669%;
    width: 100%; */
    margin-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    /* position: sticky; */
    top: 100px;
    height: 100%;
}

.BetSlip-col .card {
    --bs-card-border-color: #F2F3F7;
}

.BetSlip-col .card-body {
    padding: 32px 5px 32px 32px;
}

/* add prediction table */
.addPrediction-tab {
    position: sticky;
    top: 7%;
}

.tournamentname-tab-col .addPrediction-tab .nav-tabs {
    margin-top: 24px;
}

.tournamentname-tab-col .addPrediction-tab .champions-table-Header {
    background: #DFE1EC;
}

.tournamentname-tab-col .leauge-alldata-name-score:hover {
    background: #FFFFFF;

}

.tournamentname-tab-col .leauge-alldata-name-score {
    background: #FFFFFF;
}

.tournamentname-tab-col .all-tab-scroll {

    overflow-y: unset;
    height: 100%;
}



.champions-league-table table:first-child .champions-table-Header .champions-add-btn:first-child {
    background-color: #0D393A;
}

.More-Label-div:hover {
    background: #F2F3F7;
    border-radius: 6px;
    cursor: pointer;
}

/* drop down */

.more-sport-suggestion {
    background-color: #ffffff;
    position: absolute;
    right: 0%;
    top: 100%;
    z-index: 999999999 !important;
    min-width: 240px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #DFE1EC;
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    border-radius: 6px;
    /* transition: opacity .3s, visibility .3s, transform .3s ease-in-out; */
}

.more-sport-suggestion .sport-line-sportBtn,
.More-Label-div {
    width: 100% !important;
}

/* ----------- */

.data-wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    white-space: nowrap;
}

.dropdown-visible {
    display: none;
}

.dropdown {
    display: none;
}

.container:hover .data-wrapper {
    display: none;
}

.container:hover .dropdown {
    display: inline-block;
}





.breadcrumb-main-div .breadcrumb {
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
    display: flex;
    align-items: center;
}

.breadcrumb-main-div .breadcrumb-item+.breadcrumb-item::before {
    color: #DFE1EC;
    font-size: 24px;
    font-weight: 300;
    content: url(/public/slash-divider.svg);
}

.breadcrumb-item a {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
    text-decoration: none !important;
}

.breadcrumb-item.active .active-breadcrumb {
    color: #131520;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background: #F2F3F7;
    border-radius: 6px;
    padding: 4px 8px 4px 8px;
}

/* ----------------------- */
.menu {
    display: flex;
    overflow: hidden;
}


.more-dropdown {
    position: relative;
    display: inline-block;
}

.center-sport-button {
    max-width: 1270px;
    width: 100%;
}

.sportbutton-tournament-country-div {
    padding: 24px 11px 16px 16px;
    position: sticky;
    top: 70px;
}

/* accordion */
.sportline-country-accordion .home-page-Accordion .accordion-item:first-of-type .accordion-button {
    padding: 10px 16px 10px 16px;
}

.sportline-country-accordion .home-accordion-decription {
    padding: 0px;
    background-color: #FFFFFF;
    border: 1px solid #F2F3F7;
}

.sportline-country-accordion .home-page-Accordion .accordion-button:not(.collapsed)::after,
.sportline-country-accordion .home-page-Accordion .accordion-button::after {
    background-image: none;
    width: 0px;
}

.sportline-country-accordion .home-page-Accordion .accordion-item:first-of-type .accordion-button {
    background-color: #ffffff;
}

.sportline-country-accordion .home-page-Accordion .accordion-button:not(.collapsed) {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    background-color: #F2F3F7 !important;
}

/* homePage headre */
.homepage-Sportname-nav th {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #4C5480;

}

.homepage-Sportname-nav .homepage-name-seeAll {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #4C5480;
}

.American {
    min-width: 213.15px !important;
    width: 100% !important;
}

.AmericanEnglish {
    min-width: 176px !important;
    width: 100% !important;
}

.AustralianDropDownEnglish {
    min-width: 218px;
    width: 100%;
}

.IceEnglish {
    min-width: 131px !important;
    width: 100% !important;
}

.Ice {
    min-width: 96px !important;
    width: 100% !important;
}

.AustralianEnglish {
    min-width: 178px !important;
    width: 100% !important;
}

.Australian {
    min-width: 213px !important;
    width: 100% !important;
}

.more-dropdown-content .icon-button {
    min-width: 100% !important;
    max-width: 100% !important;
}

.live-tournament-table .leaue-score {
    max-width: 38px;
}

.live-champions-score-minute-wrapper .live-champions-leaue-score-minute {
    width: 150px;
    padding-right: 0px !important;
}

.live-tournament-score,
.live-champions-leaue-score-minute {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    width: 100px;
    padding-right: 16px !important;
    justify-content: center;
    align-items: center;
    /* gap: 4px; */
}

.all-odds-score-display .live-tournament-score {
    max-width: unset;
    width: unset;
    align-items: center;
}

.live-tournament-score {
    max-width: fit-content;
    width: 100%;
}

.liveScore-selected,
.live-champions-leaue-score-minute {
    color: #D14343;
}

.liveScore-non-selected {
    color: #7F87B3;
}

/* .sport-line-container .navbar-section {
    animation: scroll 1s;
    position: fixed;
    z-index: 1;
    width: 100%;
} */
.sport-line-top-center-nav {
    display: flex !important;
}

.tournamentname-tab-col .all-odds-score-display {
    background-color: #ffffff;
}

.tournamentname-tab-col .extra-Champions-League {
    background-color: #DFE1EC;
    ;
}

.tournamentname-tab-col .champions-leaue-first-score,
.tournamentname-tab-col .champions-leaue-country-name {
    max-width: 90% !important;
    width: 100%;
}

.tournamentname-tab-col .leaue-score {
    max-width: 10%;
    width: 100%;
}

.BetSlip-col {
    display: block;
}

.mobile-sportline-sidebar-section {
    display: none !important;
}

/* media querays */


@media screen and (max-width: 1024px) {
    /* .tournamentname-tab-col .addPrediction-tab {
        height: 100% !important;
        overflow-y: unset !important;
    } */

    .mobile-sportline-sidebar-section {
        display: block !important;
    }

    .tournamentname-tab-col .addPrediction-tab .nav-tabs {
        margin-top: 0;
    }

    .All-Competitions-btn {
        padding: 10px 16px;
        border: 1px solid rgba(223, 225, 236, 1);
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
    }

    .live-data-div,
    .addPrediction-tab {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
}

/* .notification-badge-position {
    top: 23px
} */

.sportname-tournamentname-col {
    display: block;
}

.mobile-sportData-show {
    display: none;
}

.tournamentname-tab-col .addPrediction-tab {
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tournamentname-tab-col .addPrediction-tab::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tournamentname-tab-col .addPrediction-tab {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* @media screen and (min-width: 1279.99px) and (max-width: 1429.99px) {
    .tournamentname-tab-col .champions-leaue-score {
        width: 42px !important;
    }
} */

/* lep-top large screen */
@media screen and (min-width: 1024px) and (max-width: 1279.99px) {
    .BetSlip-col {
        display: none;
    }

    .sport-line-table-betSlip .responsive-addPrediction-right-panel {
        top: 0;
        padding: 0px;
        right: 0;

    }

    .sport-line-table-betSlip .responsive-addPrediction-right-panel .card {
        border: unset;
        height: 100vh;
    }
}

/* teblet mediam screen */
@media screen and (min-width: 768px) and (max-width: 1023.99px) {
    .sportname-tournamentname-col {
        display: none !important;
    }

    .BetSlip-col {
        display: none;
    }

    .tournamentname-tab-col {
        width: 100%;
    }

    .sport-line-table-betSlip .responsive-addPrediction-right-panel {
        /* top: 16.1%; */
        right: 0;
        max-width: 476px;
        width: 100%;
        padding: 0px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    }

    .sport-line-table-betSlip .responsive-addPrediction-right-panel .card {
        border: unset;
    }

    .sportline-suggestion {
        background-color: #ffffff;
        position: absolute;
        left: 0%;
        top: 50px;
        z-index: 99999;
        max-width: 240px;
        width: 100%;
        padding: 8px 3px 8px 8px;
        border: 1px solid #DFE1EC;
        box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
        border-radius: 6px;
    }



    .teblet-position {
        position: relative;
    }

    .sportline-scroll {
        max-height: 310px;
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .sportline-scroll::-webkit-scrollbar-thumb {
        background: #BFC3D9;
        border-radius: 6px;
    }

    .sportline-scroll::-webkit-scrollbar {
        width: 5px;
        height: 124px;
    }

    .sportline-leaguename {
        padding: 10px 16px 10px 16px;
        cursor: pointer;
    }

    .sportline-leaguename:hover {
        background-color: #F2F3F7 !important;
        border-radius: 6px;
    }

    .sportline-leaguename:hover .leagues-game-score {
        color: #0D393A;
        background: #DFE1EC;
    }

    .home-accordion-decription .leagues-name,
    .sportline-popular-league-div .leagues-name {
        padding: 10px 16px 10px 16px;
        cursor: pointer;
    }

}

@media screen and (min-width: 1280.99px) {
    .tournamentname-tab-col {
        max-width: 53.432%;
        width: 100%;
    }

    .sportname-tournamentname-col {
        max-width: 17.899%;
        width: 100%;
    }

    .BetSlip-col {
        max-width: 28.669%;
        width: 100%;
    }

    /* .tournamentname-tab-col {
        max-width: 53.432%;
        width: 100%;
    }

    .sportname-tournamentname-col {
        max-width: 300px;
        width: 100%;
    }

    .BetSlip-col {
        max-width: 300px;
        width: 100%;
    } */
}

.live-data-div {
    /* margin-top: 15px; */
}

@media screen and (min-width: 1025px) and (max-width: 1127px) {
    .tournamentname-tab-col .champions-leaue-score {
        min-width: unset !important;
    }
}

.mobile-sport-home-live-tab,
.mobile-Tournament-dropDown {
    display: none !important;
}

/* mobile media */
@media screen and (max-width: 767.99px) {
    .eventListOpp_2_name {
        flex-direction: column;
    }

    .top-coupon-part .notification-part {
        display: none !important;
    }

    .responsive-addPrediction-right-panel {
        position: fixed;
        z-index: 15;
    }

    .All-Competitions-btn {
        width: 100% !important;
    }

    .prediction-notifiaction-part {
        width: calc(100% - 24px);
        height: 56px;
        left: 12px;
    }

    .all-odds-score-display {
        padding-left: 16px;
        padding-right: 16px;
    }

    @supports (-webkit-touch-callout: none) {

        .home-live-sportlineData-div,
        .selected-sport-tournamentData {
            padding-bottom: 110px;
        }
    }

    .mobile-Tournament-dropDown .notification-badge-set .notification-part {
        top: -5px !important;
    }

    .mobile-Tournament-dropDown {
        display: block !important;
        position: fixed;
        top: 0;
        background-color: #ffffff;
        width: 100%;
        left: 0;
        z-index: 10;
        padding: 16px 16px;
    }

    /* .sportline-scroll {
        max-height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        height: 100%;
    } */

    .mobile-sport-home-live-tab {
        display: block !important;
        position: fixed;
        top: 0;
        background-color: #ffffff;
        width: 100%;
        left: 0;
        z-index: 18;
        padding: 16px 16px;
        border-bottom: 1px solid #F2F3F7;
    }

    .eventListOpp_1_name,
    .eventListOpp_2_name {
        max-width: 85px;
        width: 100%;
    }

    .minus-btn {
        padding: 0px 16px;
    }

    .eventListOpp_1_name {
        flex-direction: column-reverse;
    }

    .navbar-section {
        padding: 16px 0;
    }

    .empty-data-error-message {
        max-width: 285px;
        width: 100%;
        margin: 0 auto;
    }

    .topnav {
        padding: 0px 16px;
    }

    .sport-line-sportData-panel {
        padding: 8px 16px;
    }

    .sport-line-top-center-nav {
        display: none !important;
    }

    .mobile-sportData-show {
        display: block;
    }

    .sport-line-home {
        padding: 12px 15px 12px 15px;
    }

    .sport-line-live {
        padding: 12px 15px 12px 15px;
    }

    .sportname-tournamentname-col {
        display: none;
    }

    .All-Competitions-btn-section {
        padding: 24px 16px 0px 16px;

    }

    .tournamentname-tab-col .breadcrumb-item+.breadcrumb-item::before {
        padding-right: 0px;
    }

    .country-breadcrumb-name {
        white-space: nowrap;
        max-width: 65px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .breadcrumb-item.active .active-breadcrumb {
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .filter-data-icon-button div {
        white-space: nowrap;
        /* display: flex; */
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .BetSlip-col {
        display: none;
    }

    .sport-line-container {
        overflow-x: hidden;
        /* overflow-y: scroll;
        height: 100vh; */
    }

    .sportline-suggestion {
        position: fixed;
        top: 50px;
        /* position: absolute; */
        background-color: #ffffff;
        z-index: 999999;
        width: calc(100% + 16px);
        margin-left: -15px;
        height: 100rem;
        transition: opacity 0.3s ease, visibility 0s linear 0.3s;
        transition-delay: 0s;
    }

    .sportline-containt-body {
        background-color: #ffffff !important;

    }

    .top-Accordion-data {
        padding: 10px 16px 10px 16px;
    }

    .sportline-leaguename {
        padding: 10px 32px 10px 32px;
    }

    .sportline-suggestion .sportname-title {
        padding-left: 16px;
    }

    .home-live-sportlineData-div {
        padding-top: 8px;
        max-height: calc(100vh - 49px);
        overflow-y: scroll;
        height: 100%;
        -webkit-overflow-scrolling: touch;
    }

    .breadcrumb-main-div {
        padding: 0px 16px;
    }

    .fullscreen-addPredictionTable {
        display: none !important;
    }

    .smallScreen-addPredictionTable {
        display: block !important;
    }

    .tournamentname-tab-col .champions-leaue-first-score,
    .tournamentname-tab-col .champions-leaue-country-name {
        max-width: 100% !important;
    }

    .champions-leaue-name {
        /* min-width: 16rem; */
        width: 100%;
    }

    .tournamentname-tab-col .leaue-score {
        max-width: unset;
        width: 100%;
    }

    .smallScreen-addPredictionTable .champions-leaue-score {
        min-height: 73.33px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .homepage-Sportname-nav .homepage-name-seeAll {
        padding: 0px !important;
    }

    .home-sportnavbar-div {
        max-width: unset !important;
        width: unset !important;
    }

    .tournamentname-tab-col {
        max-width: calc(100% - 16px);
        margin: 0 auto;
        padding: 0;
    }

    .tournamentname-tab-col .all-tab-scroll {
        max-height: unset;
    }

    .tournamentname-tab-col .addPrediction-tab .champions-table-Header {
        background-color: rgba(242, 243, 247, 1);
    }

    .homepage-Sportname-nav {
        background-color: #DFE1EC !important;
    }

    .champions-leaue-score {
        background-color: #ffffff;
    }

    .tournamentname-tab-col .champions-league-table {
        padding-right: 16px;
        padding-left: 16px;
    }

    .addPrediction-tab {
        top: unset
    }

    .more-dropdown {
        position: unset !important;
    }

    .more-sport-suggestion {
        top: 50px;
        min-width: calc(100% + 32px);
        width: 100%;
        margin-right: -16px;
        border: unset;
        box-shadow: unset;
    }

    /* 
    .tournamentname-tab-col .smallScreen-addPredictionTable .champions-table-Header{
        border-top-right-radius: unset;
        border-top-left-radius: unset;
    } */
}

.smallScreen-addPredictionTable {
    display: none;
}

.fullscreen-addPredictionTable {
    display: block;
}

.pagination-spinner {
    width: 19.38px;
    height: 19.1px;
}

.ShowMorePredictions-btn {
    min-width: 188px;
    height: 50px;
}

.sportline-filter-text-overflow .spinner-border{
    position: relative;
    top: -4px;
}

.breadcrumb {
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.breadcrumb-item {
    display: inline !important;
    float: none !important;
    color: #7F87B3;
    text-decoration: none;
}

.breadcrumb-item a {
    display: inline !important;
}

.breadcrumb-item a div {
    display: inline !important;
}

.breadcrumb-item::before {
    float: none !important;
    width: 20px;
    height: 21px;
    display: inline-block;
    vertical-align: middle;
}

.live-tournament-table .champions-leaue-name {
    max-width: 260px;
    /* min-width: 185px; */
    width: 100%;
}

.breadcrumb-item.active-breadcrumb.active span {
    background: #f2f3f7;
    border-radius: 6px;
    color: #131520;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 4px 8px;
    pointer-events: none;
    cursor: unset;
}

.mobile-sport-home-live-tab .title,
.mobile-Tournament-dropDown .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.All-Competitions-btn-section .filter-data-icon-button {
    /* width: calc(100% - 55px); */
    width: max-content;
    padding-right: 8px;
}

.notification-badge-set {
    position: absolute;
    top: 23px;
    right: 16px;
}

.All-Competitions-btn {
    width: auto;
    display: inline-block;
}
.sportline-outer-div{
    background-color: #F9F9FB;
}

/* .sportline-header-down-scroll-fix {
    position: fixed;
    top: 77px;
    width: 100%;
    transition: all 0.5s;
}

.sportline-header-up-scroll-fix {
    position: fixed;
    top: 0px;
    width: 100%;
    transition: all 0.5s;
} */

/* .sportline-outer-div {
    margin-top: 145px;
} */

@media screen and (max-width: 767.99px) {
    .first-table {
        display: flex !important;
    }

    .All-Competitions-btn-section .filter-data-icon-button {
        width: 100%;
    }

    .other-table {
        display: none !important;
    }

    .prediction-notifiaction-part {
        bottom: 16px;
    }

    /* .sportline-header-down-scroll-fix {
        position: fixed;
        top: 65px;
        width: 100%;
        transition: all 0.5s;
    }

    .sportline-header-up-scroll-fix {
        position: fixed;
        top: 0px;
        width: 100%;
        transition: all 0.5s;
    } */
    /* 
    .sportline-outer-div {
        margin-top: 130px;
    } */

    .sport-line-tab {
        max-width: calc(100% - 156px);
    }

    .notification-badge-set .notification-part {
        top: 0 !important;
    }

    .sport-line-top-left-nav {
        width: 100%;
    }

    .sport-line-home {
        max-width: 50%;
        width: 100%;
    }

    .sport-line-live {
        max-width: 50%;
        width: 100%;
    }

    .sportline-suggestion {
        height: 100vh !important;
        top: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .sportline-scroll {
        padding-top: 50px;
    }

    /* .champions-league-table {
        height: 70vh;
        overflow-y: scroll;
    } */

    /* .mobile-sportData-show.more-dropdown-content{
        height: 100vh;
        overflow-y: scroll;
    } */
    /* html,body{
        overflow-y:hidden !important;
    } */

    .mobile-sportData-show.more-dropdown-content {
        /* position: sticky !important;
        top: unset !important; */
        /* overflow-y: scroll;
        height: 80vh; */
        height: 79vh;
        z-index: 999999999;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .more-sport-suggestion {
        height: 100vh;
        /* overflow-y: auto; */
        min-width: calc(100% + 16px);

    }

    .mobile-sport-home-live-tab .More-Label-div {
        width: unset !important;
        margin-left: 70px;
    }

    .more-info-final {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 999;
    }

    .mobile-sportData-show.more-dropdown-content::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 6px;
    }

    .mobile-sportData-show.more-dropdown-content::-webkit-scrollbar {
        width: 5px;
        height: 124px;
    }

    .mobile-sportData-show .menu-item {
        display: block !important;
    }


    .filter-data-icon-button div {
        justify-content: flex-start !important;
    }

    .smallScreen-addPredictionTable .champions-leaue-country-name {
        padding-right: 24px;
    }

    .with-mobilescreen {
        display: block !important;
    }

    .without-mobilescreen {
        display: none !important;
    }

    .sport-line-sportBtn div {
        justify-content: unset !important;
    }
}

.notification-badge-set .notification-part {
    top: 0px;
}

.with-mobilescreen {
    display: none;
}

.without-mobilescreen {
    display: block;
}

.nav-up {
    top: -40px;
}

.increased-odds-div {
    border: 1px solid #208B8E;
    background-color: #F7FDFD;
    color: #208B8E
}

.decreased-odds-div {
    border: 1px solid #D14343;
    background-color: #FDF4F4;
    color: #D14343
}

.first-table {
    display: table-row;
}

.other-table {
    display: none;
}

.totalPredictionCount-div {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding-left: 12px;
    /* padding-right: 12px; */
}

.to-top-btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0D393A;
    color: #FFF;
    cursor: pointer;
    width: 56px;
    height: 56px;
    bottom: 16px;
    right: 12px;
    border-radius: 6px;
    border: 1px solid #FFF;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.to-top-btn.hide {
    opacity: 0;
    display: none;
    transition: opacity 0.5s ease-in-out;
}

.tablet-screen {
    /* padding-top: 1.5rem; */
    padding-left: 16px;
    padding-right: 16px;
}

.breadcrumbs-wrapper {
    /* padding-top: 1.5rem; */
}

.counties-tablet-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 70px; */
    margin-bottom: 16px;
    padding-right: 10px;
}

.dropdown-input.leagues {
    width: fit-content;
    min-width: 110px;
    height: 46px;
    justify-content: center;
    z-index: 1;
}

.leagues-select {
    display: flex;
    width: fit-content;
    background-color: #fff;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    z-index: 1;
    white-space: nowrap;
}

.dd-arrow {
    position: relative;
    margin-left: 20px;
    transform: rotate(180deg)
}

.leagues-select.open .dd-arrow {
    transform: rotate(360deg)
}

.leagues-select.open {
    border: 1px solid #BFC3D9;
}

.leagues-select.selected {
    background-color: #F7FDFD;
    color: #0D393A;
    border: 1px solid #0D393A;
}

.leagues-list-items {
    background-color: #fff;
    width: 340px;
    border-radius: 6px;
    border: 1px solid #DFE1EC;
    height: 452px;
    padding-top: 5px;
    margin-top: 5px;
    box-shadow: 0 8px 8px rgba(50, 50, 71, .08), 0 8px 16px rgba(50, 50, 71, .06);
}

.leagues-list-items .sportbutton-tournament-country-div {
    padding: 8px !important;
}

.notification-badge-position {
    display: flex;
    position: relative;
    width: 44px;
    height: 44px;
    background-color: #fff;
    border: 1px solid #f2f3f7;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
}

.button__badge {
    position: absolute;
    height: 24px;
    width: 24px;
    background-color: #0d393a;
    right: -10px;
    top: -15px;
}

.tablet-screen .tournamentname-tab-col {
    padding: 0;
    margin: 0;
}

.live-champions-time {
    color: #d14343;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

.champions-table-Header {
    height: 56px;
}

.team-1-name,
.team-2-name {
    width: 180px;
}

.team-1-name {
    text-align: end;
}

.Champions-League-name {
    color: #4C5480
}

.leagues-game-score {
    margin-left: 8px;
}

.more-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.more-btn {
    width: fit-content !important;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    background-color: #FFF;
    text-align: center;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
}

.no-game-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 24px;
    gap: 24px;
    border-radius: 6px;
    border: 1px solid #F2F3F7;
}

.no-game-img {
    width: 145px;
    height: 127px;
}

.no-game-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.no-game-title {
    color: #131520;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.no-game-text {
    max-width: 480px;
    color: #7F87B3;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
}

.no-game-btn {
    width: 230px;
    height: 48px;
    border-radius: 6px;
    background-color: #0D393A;
    color: #fff;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
}

.no-game-arrow {
    position: absolute;
    left: 16px;
    top: 16px;
    cursor: pointer;
}

.addPrediction-tab {
    padding-top: 32px !important;
}

.extra-odds-page {
    border-radius: 6px;
}

.breadcrumb-item a span:hover {
    text-decoration: underline;
}

.button__badge-mobile {
    display: flex;
    height: 24px;
    width: 24px;
    border: 1px solid #fff;
    border-radius: 50%;
    font-weight: 700;
    line-height: 14px;
    justify-content: center;
    align-items: center;
    background-color: #208b8e;
}

@media screen and (min-width: 1024.1px) and (max-width: 1279.99px) {
    .notification-badge-position {
        display: flex;
        position: absolute;
        width: 44px;
        height: 44px;
        background-color: #fff;
        border: 1px solid #f2f3f7;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        right: 10px;
    }

    .extra-odds-page,
    .tournament-loader {
        /* margin-top: 60px !important; */
    }

    .prediction-notifiaction-part{
        transition: all 0.1s ease-in-out;
        bottom: 16px;
        right: 16px;
        width: 345px;
        height: 56px;
    }

    .to-top-btn{
        right: 16px;
    }

    .prediction-notifiaction-part.visible {
        transition: all 0.1s ease-in-out;
        width: 279px;
        right: 80px
    }
}


@media screen and (max-width: 1024px) {
    .breadcrumbs-wrapper {
        margin-bottom: 16px;
    }

    .prediction-notifiaction-part.visible {
        position: fixed;
        bottom: 16px;
        right: 80px
    }

    .sportlineLeagues-scroll .leagues-name {
        padding: 10px 16px;
    }

    .sport-name {
        color: #7f87b3;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
    }

    .leagues-name {
        padding-right: 10px;
        min-height: 44px;
        /* margin-bottom: 8px; */
    }

    .leagues-game-score {
        margin-left: 10px;
    }
}

@media screen and (max-width: 768px) {
    .opps-names-wrapper {
        align-items: flex-start !important;
    }

    .team-1-name,
    .team-2-name {
        margin-top: 8px;
        text-align: center;
        flex: 1;
        width: 100%;
    }

    .live-tournament-score,
    .minus-btn {
        padding-top: 26px;
    }

    #tournament-tab {
        max-width: 100vw;
    }

    .mobile-tournament-name {
        border-radius: 0 !important;
        height: 60px;
    }

    .leagues-select {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .dd-text {
        display: block;
        white-space: nowrap;
        /* width: calc(100% - 300px); */
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dd-text-wrapper {
        display: flex;
        align-items: center;
        width: calc(100% - 30px);
    }

    .dd-text-wrapper img {
        width: 22px;
        height: 14px;
        padding: 0 !important;
        margin-right: 8px;
    }

    .champions-league-table,
    .extra-odds-page {
        padding-bottom: 200px;
    }

    .extra-odds-page {
        padding-left: 10px;
        padding-right: 10px;
    }

    .counties-tablet-sidebar {
        padding: 0 16px;
    }

    .sport-line-table-betSlip {
        background-color: #fff;
    }

    .leaue-score-test {
        gap: 1rem;
    }

    .dropdown-input.leagues {
        width: 100vw;
        min-width: 110px;
        height: 46px;
        justify-content: center;
        z-index: 1;
    }

    .leagues-list-items {
        height: 390px;
        padding-bottom: 8px;
        width: 100%;
        /* overflow-x: hidden; */
    }

    .sportlineLeagues-scroll {
        overflow: hidden;
    }

    .prediction-notifiaction-part {
        font-weight: 700;
    }

    .to-top-btn {
        bottom: 80px;
    }

    .leagues-name {
        padding-left: 12px;
        font-size: 12px;
        line-height: 18px;
        min-height: 36px;
        padding: 8px;
    }

    .accordion-button {
        padding: 8px !important;
    }

    .home-accordion-Header {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .leaue-score-test {
        max-width: 100% !important;
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
        gap: 16px;
        overflow: hidden;
    }

    .champions-leaue-score-data-X {
        flex-grow: 1 !important;
        max-width: 25% !important;
        width: 100% !important;
        /* height: 74px;
        width: 200px !important; */
        /* width: 20px;
        height: 20px; */
        /* width: 100% !important; */
    }

    .champions-leaue-score {
        width: 100% !important;
    }

    .champions-table-Header {
        display: flex;
        align-items: center;
    }

}

.sport-line-sportData-panel {
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 11;
    background-color: #ffffff;
    padding: 8px 0px;
    border-bottom: 1px solid #F2F3F7;
}

.sports-tab-container {
    box-sizing: border-box;
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.sports-tab-wrapper {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.sport-line-top-center-nav {
    padding-left: 16px;
    display: flex;
    overflow: hidden;
}

.sports-more-btn-wrapper {
    width: 200px !important;
    display: flex;
    justify-content: end;
}

.more-sport-menu {
    margin-left: 8px;
    max-width: 200px;
}

.menu-item-hidden {
    visibility: hidden;
}

.More-Label-div {
    width: 100%;
}

.dropDown-sport-btn {
    background: #F2F3F7;
    width: 100%;
    max-height: 50px;
    border-radius: 6px;
    padding: 12px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    cursor: pointer;
}

.extra-time {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9DADA;
    padding: 2px;
    margin-left: 4px;
    border-radius: 3px;
}

.live-champions-score-minute-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
}

.game-period {
    color: #D14343;
    line-height: 12px;
    font-size: 12px;
    font-weight: 600;
}

.liveScore-selected-game {
    color: #D14343;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}

.sidebar-sports-accordion {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sport-accordion-item {
    border-radius: 6px;
    border: 1px solid #F2F3F7;
}


.sidebar-sports-accordion-header .accordion-button,
.sidebar-sports-accordion-header .accordion-button.collapsed {
    display: flex;
    height: 48px;
    border-radius: 6px;
    padding: 12px;
    background-color: #F2F3F7;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    justify-content: space-between;
    cursor: pointer;
}

.sidebar-sports-accordion-header .accordion-button {
    border: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.sidebar-sports-accordion-header .accordion-button:hover {
    background-color: #DFE1EC;
}

.sidebar-countries-accordion-header .accordion-button:not(.collapsed),
.sidebar-sports-accordion-header .accordion-button:not(.collapsed) {
    color: #000000;
}

.sidebar-sports-accordion-header .accordion-button:not(.collapsed){
    background-color: #dfe1ec;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.popular-leagues_list-item.active,
.sport-item__leagues-item.active {
    border-left: 2px solid #0D393A !important;
    box-shadow: none !important;

}

.sidebar-countries-accordion-header .accordion-button:hover,
.sidebar-countries-accordion-header .accordion-button:not(.collapsed) {
    background-color: #F2F3F7;
    border-radius: 0;
}

.popular-leagues_list-item:hover .leagues__item-counter,
.popular-leagues_list-item.active .leagues__item-counter,
.sport-item__leagues-item:hover .leagues__item-counter,
.sport-item__leagues-item.active .leagues__item-counter,
.sidebar-countries-accordion-header .accordion-button:hover .sidebar__country-item-wrapper .leagues__item-counter,
.sidebar-countries-accordion-header .accordion-button:not(.collapsed) .sidebar__country-item-wrapper .leagues__item-counter {
    background-color: #DFE1EC;
}

.sidebar-countries-accordion-header .accordion-button:focus,
.sidebar-sports-accordion-header .accordion-button:focus {
    box-shadow: none;
}

.sidebar-sports-accordion-body {
    padding: 0;
}

.countries-accordion-item {
    border: none;
}

.sidebar-countries-accordion-header .accordion-button,
.sidebar-countries-accordion-header .accordion-button.collapsed {
    display: flex;
    padding: 10px 12px;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    align-items: center !important;
    gap: 8px;
    border: none !important;
}

.sidebar__country-item-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

.accordion-button::after {
    background-image: url('../../public/accordion_btn_down.svg')
}

.accordion-button:not(.collapsed)::after {
    background-image: url('../../public/accordion_btn_down.svg')
}

.sidebar__country-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.sidebar-wrapper {
    gap: 8px;
    padding: 8px;
}

.sidebar__toggle-menu {
    display: flex;
    flex-direction: row;
    height: 48px;
    border-radius: 8px;
    background-color: #F9F9FB;
    align-items: center;
    gap: 8px;
    padding: 4px;
    border: 1px solid #F9F9FB;
}

.sidebar__toggle-menu-item {
    display: flex;
    height: 40px;
    padding: 8px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    flex: 1;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    justify-content: center;
}

.sidebar__toggle-menu-item.active {
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.sidebar__toggle-menu-item:hover {
    background-color: #FFF;
}

.sidebar__toggle-menu-item.item-live {
    color: #D14343;
}

.popular-leagues__title,
.sidebar__title {
    height: 40px;
    padding: 8px;
    color: #7F87B3;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 8px;
}


.sidebar__dropdown-menu {
    display: flex;
    flex-direction: column;
    gap: 4px
}

.sidebar__dropdown-menu__item-wrapper {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

.sidebar__dropdown-menu__sport-item {
    display: flex;
    height: 48px;
    border-radius: 6px;
    padding: 12px;
    background-color: #DFE1EC;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    justify-content: space-between;
    cursor: pointer;
}

.sidebar__sport-item-title,
.sport-item__country-item-title {
    display: flex;
    gap: 8px;
    align-items: center;
}

.item-counter {
    color: #7F87B3
}

.sport-item__countries,
.sport-item__leagues {
    display: flex;
    flex-direction: column;

}

.sport-item__countries.closed,
.sport-item__leagues.closed {
    display: none;
}

.sport-item__country-item {
    display: flex;
    padding: 10px 12px;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
}

.popular-leagues_list-item:hover,
.popular-leagues_list-item.active,
.sport-item__country-item:hover,
.sport-item__country-item.active,
.sport-item__leagues-item:hover,
.sport-item__leagues-item.active {
    background-color: #F2F3F7;
}

.popular-leagues_list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    gap: 8px;
    cursor: pointer;
}

.leagues__item-counter {
    border-radius: 8px;
    padding: 0 4px;
    background-color: #F2F3F7;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #7F87B3;
}

.sport-item__leagues-item {
    display: flex;
    padding: 12px 36px 12px 12px;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    height: 42px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.popular-leagues_list-item-title{
    display: flex;
    gap: 8px;
    align-items: center;
}

.popular-leagues_list-item-title-txt,
.popular-leagues_list-item-title,
.sport-item__leagues-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.all-sports__dropdown-title {
    margin-top: 8px;
    display: flex;
    padding: 10px 10px;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    gap: 8px;
    border-top: 1px solid #F2F3F7;
    cursor: pointer;
}

.all-sports__dropdown-title:hover {
    background-color: #f2f3f7;
}

.dot-live {
    margin-top: 8px !important;
    margin-right: 8px !important;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #D14343 !important;
}

.bottom-text-section h1{
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 8px;
}

.bottom-text-section p{
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
}

.bottom-text-section{
    margin-bottom: 24px;
    background-color: #fff;
    padding: 32px;
    border-radius: 6px;
}

@media screen and (max-width: 1024px) {
    .sidebar-sports-accordion {
        gap: 0;
    }

    .sidebar-sports-accordion-header .accordion-button,
    .sidebar-sports-accordion-header .accordion-button.collapsed {
        padding: 10px !important;
    }

    .sidebar-sports-accordion-header .accordion-button.collapsed {
        background-color: transparent !important;
        border: none !important;
    }

    .sidebar-sports-accordion-header .accordion-button:hover{
        background-color: #f2f3f7 !important;
    }

    .sidebar-sports-accordion-header .accordion-button:not(.collapsed) {
        background-color: #dfe1ec !important;
    }

    .accordion-item {
        border: none !important;
    }

    .mobile-top-panel-wrapper {
        display: flex;
        width: 100%;
        gap: 8px;
    }

    .leagues-list-items {
        padding: 4px;
        overflow: scroll;
    }

    .leagues-list-items::-webkit-scrollbar {
        display: none;
    }

    .popular-leagues__title{
        margin-top: 0;
    }
}

@media screen and (min-width: 768.1px) and (max-width: 1024px) {
    .tournament-loader{
        margin-top: 0 !important;
    }

    .dot-live{
        margin-top: 0 !important;
    }

    .sidebar__toggle-menu {
        border: 1px solid #DFE1EC;
        padding: 0;
        width: 200px;
        background-color: #F2F3F7;
        height: 46px;
        gap: 0;
    }

    .sidebar__toggle-menu-item.item-live {
        color: #000000;
        border-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .sidebar__toggle-menu-item {
        text-align: center;
        align-items: center;
        height: 100%;
        border-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .sidebar__toggle-menu-item:not(.active){
        border-right: 1px solid #DFE1EC;
        border-left: 0;
    }

    .sidebar__toggle-menu-item:not(.active).item-live{
        border-left: 1px solid #DFE1EC;
        border-right: 0;
    }
}

@media screen and (max-width: 768px) {
    .mobile-top-panel-wrapper {
        flex-direction: column;
    }

    .dropdown-input.leagues {
        width: 100%;
    }

    .addPrediction-tab {
        padding-top: 16px !important;
    }
}