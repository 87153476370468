.prediction-list-detail {
    padding: 24px;
    border-bottom: 1px solid #f2f3f7;
}

.addeduser-date-time {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.both-team-league-time,
.both-team-league-time-sport,
.odd-title {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

.both-team-league-time,
.odd-title {
    padding-bottom: 4px;
}

.both-team-league-time-sport {
    padding-bottom: 8px;
}

.addeduser-name,
.both-team-name,
.odd-score,
.predictionList-type,
.predictionlist-odd-div .data,
.predictionlist-Staked-coins .data,
.predictionlist-possible-win .data,
.predictionlist-type-div .data,
.PredictionStatus .title,
.score-input-feild .title,
.delete-prediction-message {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.close-squere-button {
    display: block !important;
}

.both-team-name {
    display: flex;
    flex-wrap: wrap;
}

.addeduser-name {
    cursor: pointer;
    text-decoration: none;
}

.addeduser-name:hover {
    text-decoration: underline;
    color: #000000;
}

.both-Team-logo {
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

/* avatar  */

.team1-logo,
.team2-logo {
    width: 50px;
    height: 50px;
    background-color: #DFE1EC;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.prediction-list-content .team1-logo,
.prediction-list-content .team2-logo {
    background-color: #F2F3F7;
}

.filter-user-avtar {
    width: 50px;
    height: 50px;
    background-color: #DFE1EC;
    border: 2px solid #FFFFFF;
}


.pagination-button-avtar {
    width: 100%;
    display: flex;
    width: 42px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #F2F3F7;
    cursor: pointer;
}

.pagination-button {
    margin-top: -20px;
}

.pagination-button-avtar {
    margin: 0 auto;
}

.team2-logo {
    margin-left: -12px;
}

.add-predictions {
    border: 1px solid #f2f3f7;
    border-radius: 6px;

    height: 100%;
}

.add-prediction-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 40rem;
}

.add-prediction-scroll::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.add-prediction-scroll::-webkit-scrollbar {
    width: 5px;
    height: 124px;
}

.both-team-name span,
.both-team-league-time span,
.both-team-league-time-sport span {
    padding-right: 8px;
}

.oc_teams_details{
    text-decoration: none;
}

.teams-name{
    text-decoration: none;
    color: #000;
}

.teams-name:hover{
    text-decoration: underline;
    color: #000;
}

.oc_teams_details:hover .more-events-label-div{
    text-decoration: none;
}

.both-team-oc-name{
    font-weight: 600;
}

.colouns-style {
    padding-right: 5px !important;
    padding-left: 5px;
}

.addUser-img-date-name {
    margin-bottom: 10px;
}


.ticket-img {
    padding-right: 8px;
}

.both-team-contains {
    padding-left: 8px;
    /* text-decoration: none !important; */
}

.both-team-contains:hover .more-events-label-div{
    text-decoration: none !important;
}

.team-2-logo {
    margin: 0 -12px;
    /* padding-right: 8px; */
}

.addedUser-prediction-data {
    background-color: #FFFFFF;
    /* display: none; */
    /* border-radius: 6px; */
    padding: 16px;

}

.list-odds {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

.list-odds .addedUser-prediction-data:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.list-odds .addedUser-prediction-data:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.prediction-list-date {
    text-decoration: none;
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid #f2f3f7;
}

.prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
    padding-top: 16px;
    /* justify-content: space-between; */
}



.Goals-Scored-Yes {
    padding: 8px 16px;
    /* border-left: 1px solid #DFE1EC;
    border-right: 1px solid #DFE1EC; */
}

.like-count {
    display: flex;
    align-items: center;

}

.both-team-league-name,
.prediction-slip-decription {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.totle-oddScore {
    border-left: 1px solid #DFE1EC;
    padding: 20px 16px;
}

.oc_teams_details {
    /* border-right: 1px solid #DFE1EC;
    padding-bottom: 16px;
    padding-top: 16px; */
    /* justify-content: space-between; */
}

.more-events-label-div {
    background-color: #F2F3F7;
    border-radius: 6px;
    padding: 4px 6px;
}

.single-prediction-result {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    padding: 6px 8px;
    background-color: #F2F3F7;
    border-radius: 6px;
    margin-left: 8px;
    text-wrap: nowrap;
}

.prediction-list-content {
    margin-left: 64px;
}

.like-count {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0D393A;
}

.comment-count {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #D14343;
}

.prediction-slip-decription {
    padding-bottom: 24px;
    border-bottom: 1px solid #F2F3F7;
    margin-bottom: 16px;
}

.prediction-slip-decription-modal{
    padding-left: 32px;
    padding-right: 32px;
}

.addedUser-prediction {
    padding: 24px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

.addeduser-img {
    border-radius: 50px;
    width: 48px;
    height: 48px;
}

.comments-section {
    margin-top: 24px;
}

.placeOrder-total-odds {
    padding: 4px 8px;
    background-color: #F2F3F7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    display: flex;
    align-items: center;
}

.placeOrder-total-odds-active {
    background-color: #EBCC4F;
    color: #FFFFFF;
}

.placeOrder-total-odds-unactive {
    color: #FFFFFF;
    background-color: #D14343;
}

.ShowMorePredictions-button {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 32px; */
    padding-top: 16px;
}

.predictions__bottom-text-section{
    margin-top: 64px;
}

.show-boTTom-message {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.prediction-post-img {
    padding-left: 12.5px;
}

.like-btn {
    cursor: pointer;
}

.disLike-btn {
    cursor: pointer;
}

.ShowMorePredictions-btn {
    width: 100%;
    padding: 12px 16px;
}

.mobileShowPrediction-btn {
    padding: 12px 16px;
    margin-bottom: 40px;
    width: 100%;
}

.bets-list-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.odd-title {
    text-align: center;
}

.mobile-show-more-prediction-button,
.mobile-both-team-contain,
.mobile-predictionlistLoader,
.mobile-prediction-result.single-prediction-result {
    display: none;
}

.web-PredictionListLOader {
    padding: 32px;
    border: 1px solid #f2f3f7;
    border-radius: 6px;
}

.prediction-grop-button {
    padding-top: 32px
}

.mobile-btn-openpredition-btn {
    display: none;
}

.Open-prediction-btn {
    width: 100%;
    min-width: max-content;
}

.showMorePrediction-div {
    width: 100%;
}

/* predictionlist media */
@media screen and (max-width: 768px) {
    .prediction-list-detail {
        padding: 16px 16px 40px 16px;
    }

    .earning-coin-tournament_name {
        max-width: 85px;
        /* width: 100%; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .large-screen-openpredition-btn {
        display: none !important;
    }

    .Open-prediction-btn {
        width: 100%;
        margin-top: 8px;
    }

    .mobile-btn-openpredition-btn {
        width: 100%;
        display: block;
    }

    .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
        justify-content: unset;
    }

    .prediction-grop-button {
        position: relative;
        padding-top: 16px;
        bottom: 28px;
    }

    .web-PredictionListLOader {
        display: none;
    }

    .mobile-predictionlistLoader {
        display: block;
        padding: 16px;
        border: 1px solid #f2f3f7;
        border-radius: 6px;
    }

    .mobile-both-team-contain {
        display: block;
    }

    .both-team-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #131520;
    }

    .addedUser-prediction-data .oc_teams_details {
        flex-direction: column;
        align-items: flex-start !important;
        border-bottom: 1px solid #F2F3F7 !important;
    }

    .mobile-prediction-result.single-prediction-result {
        display: flex !important
    }

    .single-prediction-result {
        display: none;
    }

    .more-prediction-model-div .modal-dialog {
        width: unset !important;
        max-width: unset !important;
    }

    .model-prediction-team-data .single-prediction-result {
        display: block !important;
    }

    .odd-title {
        text-align: unset;
    }

    .prediction-list-content {
        margin-left: 0px;
    }

    .both-team-league-name {
        display: flex;
    }

    .both-team-league-name span {
        padding-left: 8px;
    }

    .both-team-league-time-sport {
        padding-bottom: 0px;
    }

    .Goals-Scored-Yes {
        padding: 16px 0px 16px 0px;
        border-bottom: 1px solid #DFE1EC;
    }

    .addUser-img-date-name {
        margin-bottom: 8px;
    }

    .prediction-list-date {
        display: unset;
    }

    .addedUser-prediction-data .oc_teams_details {
        border-right: none;
        border-bottom: 1px solid #DFE1EC;
        width: 100%;
        /* padding-top: 16px; */
        padding-bottom: 16px;
    }

    .prediction-list-date .both-Team-logo {
        padding-bottom: 0px;
    }

    .addedUser-prediction-data .both-team-name {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start !important;
        flex-direction: column;

    }

    .more-events-label-div {
        margin-top: 8px;
    }

    .bets-list-detail {
        width: 100%;
        display: unset;

    }

    .add-predictions {
        margin-bottom: 24px;
    }

    .totle-oddScore {
        border-left: none;
        padding: 16px 0px 16px 0px;
    }

    .mobile-show-more-prediction-button {
        display: block;
    }

    .show-boTTom-message {
        margin-bottom: 65px;
    }

    .ShowMorePredictions-button {
        padding-top: 16px;
        /* padding-bottom: 32px; */
        padding-right: 16px;
        padding-left: 16px;

    }

    .showMorePrediction-div {
        width: 100%;
    }

    .ShowMorePredictions-btn {
        width: 100%;
        position: relative;
        bottom: 24px;
    }

}


/* share model */
.share-Prediction-model .modal-content {
    max-width: 654px;
    width: 100%;
    padding: 0px;
    margin-top: 198px;
}

.share-Prediction-model .modal-header {
    justify-content: flex-end;
    margin-bottom: 0px;
    z-index: 1;
    padding: 20px 20px 0 0;
}

.share-Prediction-model .modal-body {
    padding: 20px 0px 0 0;
    background-color: #F2F3F7;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.share-Prediction-model .modal-footer {
    justify-content: center;
    padding: 32px 32px 40px 32px;


}

.shareModel-logo-team-logo-group {
    padding-bottom: 32px;
}

.share-Prediction-model .modal-dialog {
    max-width: 654px;
    margin: 100px auto 0 auto;
    width: 100%;
}

.share-model-team1-logo,
.share-model-team2-logo {
    width: 50px;
    height: 50px;
    background: rgba(88, 130, 130, 0.6);
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* margin: 0 auto; */
}

.single-prediction-team-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.both-Team-logo .share-model-team2-logo {
    margin-left: -15px;
}

.prediction-list-date .both-Team-logo {
    padding-right: 0px;
}

.parley-share-team-logo .share-model-team2-logo {
    margin-left: -7px;
}

.parley-share-line {
    opacity: 0.1;
    border: 1px solid #EEFBFB;
}

.win-ocRate-panel {
    max-width: 201px;
    width: 100%;
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    justify-content: space-between;
}

.share-model-team1-name,
.share-model-team2-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

}

.shareModel-tournament-name,
.shareModel-tournament-name-date {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.shareModel-date-time {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #EEFBFB;
    /* opacity: 0.6; */
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.shareModel-tournament-name-date {
    /* max-width: 314px; */
    width: 100%;
    display: flex;
}

.shareModel-both-team-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #EEFBFB;
}

.share-model-team,
.share-model-team-odds {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #EEFBFB;
    padding-bottom: 16px;
}

.share-model-team span,
.share-model-team-odds span {
    padding: 0px 16px 0px 16px;
}

.line-prediction {
    padding: 0px !important;
}





.scnner-panel {
    padding-top: 28px;
}

/* .shareModel-gradiant-ref {
    position: relative;
    min-height: 420px;
} */
.shareModel-gradiant-ref {
    margin: -118px 67px 49px 67px;
}

.shareModel-gradiant {
    background: radial-gradient(123.32% 123.32% at 50% 0%, #1A7375 0%, #072121 100%);
    border-radius: 6px;
    padding: 32px 32px 32px 32px;
    border: 3px solid #FFFFFF;
    border-radius: 6px;
    max-width: 520px;
    width: 100%;
    /* position: absolute;
    top: -118px;
    margin: 0 67px 32px; */
}



.shareModel-tournament-name-date {
    margin-bottom: 16px;
}

.status-button {
    padding: 4px;
    color: #C09E16;
    border: 1px solid #C09E16;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    background: #FFFFFF;
    border-radius: 6px;
    max-width: max-content;
    text-align: center;
}

.status-button-lost {
    color: #C94B13;
    border: 1px solid #C94B13;
}

.status-button-won {
    color: #208B8E;
    border: 1px solid #208B8E;
}

.share-model-team-odds .odds-contains {
    max-width: 75px;
    width: 100%;
}

.share-model-team p:first-child {
    min-width: 75px;
}

.share-model-team-odds {
    padding-bottom: 4px;
}

.social-community-title,
.social-community-title-hide {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131520;
    padding-bottom: 32px;
}

.share-model-social-media-network {
    display: flex;
    justify-content: space-between;
    max-width: 240px;
    width: 100%;
    margin: 0 auto;
}

.shareModel-QrCode {
    background-color: #ffffff;
}

.joinCapperauditor-message {
    max-width: 150px;
    width: 100%;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    padding-left: 16px;
    display: flex;
    align-items: center;
}

.Multiple-share-prediction-frame {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.Multiple-share-prediction {
    display: flex;
    justify-content: space-between;
}

.Multiple-share-prediction-frame span {
    padding: 0px 16px 0px 16px;
}

.parley-share-prediction .shareModel-tournament-name-date {
    /* max-width: 268px;
    width: 100%; */
    margin-top: 23px;
}

.parley-share-team-logo {
    display: flex;
}

.parley-share-team-logo .share-model-team1-logo,
.parley-share-team-logo .share-model-team2-logo {
    width: 44px;
    height: 44px;
}

.parley-share-team-detail {
    display: flex;
    padding-bottom: 24px;
    /* border-bottom: 1px solid #EEFBFB; */
}

.parley-share-team-name {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.oc_name-oc_rate-frame {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.And-more-predictions {
    padding-top: 24px;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    cursor: pointer;
}

.line-color-share-prediction {
    color: #EEFBFB;
    opacity: 30%;
}

.share-model-header {
    padding-right: 1rem;
    padding-left: 1rem;
}

.single-share-model-team1-name,
.status-btn-mobile {
    display: none;
}

.Multiple-title,
.allEventDataLength,
.getOddsMultiply-count {
    opacity: 0.6;
}

.QRCode-div {
    max-width: 68px;
}

.share-model-odds_detail {
    display: flex;
}

.mobile-screen-odds-data {
    display: none !important;
}

.large-screen-odds-data {
    display: flex !important;
}

.share-model-result-score-status-div .result-score,
.more-prediction-eventlist-count {
    color: #131520;
    padding: 4px;
    background-color: #F2F3F7;
    border-radius: 6px;
    font-weight: 600;
}

.share-model-oc-group-name {
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: #FFFFFF;
}

.more-prediction-eventlist-count {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 24px;
    min-width: max-content;
}

.share-model-property {
    padding: 16px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.10);
}


/* single */
@media screen and (max-width: 768px) {

    .share-model-team1-name,
    .share-model-team2-name {
        text-align: center;
    }

    .share-model-odds_detail {
        justify-content: space-between;
        /* flex-wrap: wrap; */
    }

    .share-model-oc-group-name {
        text-align: center;
        padding-bottom: 16px;
    }

    .more-prediction-model-div .oc_teams_details,
    .notifiction-model-container .oc_teams_details,
    .notification-modal .oc_teams_details {
        flex-direction: column;
        width: 100%;
        align-items: flex-start !important;
    }

    .both-team-logo-odds-data {
        width: 100%;
        padding-bottom: 16px;
    }

    .mobile-screen-odds-data {
        display: flex !important;
    }

    .large-screen-odds-data {
        display: none !important;
    }

    .width-mobile-media {
        width: 100%;
    }

    .share-Prediction-model .modal-content {
        border: none;
    }

    .share-model-team,
    .share-model-team-odds {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .share-model-team-odds .odds-contains {
        max-width: 62px;

    }

    .share-model-team p:first-child {
        min-width: 59px;
    }

    .parley-share-team-name {
        flex-wrap: wrap;
    }

    .shareModel-gradiant {
        padding: 24px;
    }

    .getOddsMultiply-count,
    .parley-share-prediction .status-btn-web {
        display: none;
    }

    .line-color-share-odds {
        display: block !important;
        color: #EEFBFB;
        opacity: 0.1;
        padding: 0px 16px 0px 16px !important;
    }

    .status-btn-mobile {
        display: block;
    }

    .line-color-share-prediction,
    .joinCapperauditor-message {
        display: none;
    }

    .QRCode-div {
        max-width: 68px;
    }

    .line-color-share {
        display: block;
    }

    .single-prediction-team-list,
    .single-prediction-team-list-name-logo {
        flex-direction: column;
    }

    .both-Team-logo {
        padding-right: 0px;
        /* padding-bottom: 16px; */
    }

    .share-model-both-team-name {
        display: flex;
        padding-bottom: 16px;
    }

    .single-share-model-team1-name {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #EEFBFB;
    }

    .share-model-social-media-network {
        min-width: 240px;
    }

    .share-model-header {
        padding: 0px;
    }

    .share-model-header {
        max-width: 520px;
        width: 100%;
        margin: 0 auto;
    }

    /* .result-score {
        display: none;
    } */

    .share-Prediction-model .modal-content {
        padding: 0px 16px;
    }

    .shareModel-gradiant {
        margin: 10px auto 0px auto;
    }

    .share-Prediction-model {
        --bs-modal-bg: unset;
        padding-left: 0px !important;
    }

    .share-Prediction-model .modal-body {
        background-color: unset;
        padding: 0px;
    }

    .share-Prediction-model .modal-content {
        margin-top: 0px;
    }

    .share-Prediction-model .modal-dialog {
        margin-top: 16px;
    }

    /* .close-button {
        display: none;
    } */

    .share-Prediction-model .model-close-btn {
        border-radius: 6px;
        background-color: unset;
    }

    .shareModel-gradiant-ref {
        margin: 0px;
    }

    .shareModel-logo-team-logo-group {
        display: flex;
        justify-content: center;
    }

    .single-share-prediction .shareModel-tournament-name-date {
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
    }

    .single-share-prediction .shareModel-tournament-name {
        padding-bottom: 8px;
    }

    .single-share-prediction .date-line-color-share {
        padding-bottom: 16px;
    }

    .white-close-button {
        display: block !important;
    }

    .share-model-header .close-button {
        display: none !important;
    }

    .share-Prediction-model .modal-footer {
        max-width: 520px;
        width: 100%;
        margin: -5px auto 0px auto;
        background-color: #ffffff;
    }

    .share-Prediction-model .social-community-title-hide {
        display: none;
    }
}

.white-close-button {
    display: none;
}

.share-Prediction-model .social-community-title {
    display: flex;
}

/* odds tooltip */
.predictionList-OddsBtn-Tooltip {
    /* position: relative; */
    cursor: pointer;
}

.predictionList-type-tooltip-div {
    position: relative;
}

.odds-multiply-tooltip {
    max-width: 346px;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 40px;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    background: #131520;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 8px 12px;
}

.odds-multiply-tooltip::after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: #131520 transparent transparent transparent;
    top: 100%;
    left: auto;
    margin-left: -5px;
    right: 10px;
}

.predictionList-OddsBtn-Tooltip:hover .odds-multiply-tooltip {
    opacity: 1;
    /* right: 0; */
    visibility: visible;
}

.large-screen-openpredition-btn {
    display: block;
}

.notifiction-model-container .predictionlist-odd-div {
    padding-left: 0px;
    padding-right: 0px;
}

/* 
@media only screen and (max-width: 1063px) {


    .predictionlist-type-div,
    .predictionlist-status-div {
        margin-top: 16px;
    }
} */

@media only screen and (min-width: 1245px) {
    .addedUser-prediction-data .row {
        flex-wrap: unset;
    }
}

@media only screen and (max-width: 992.99px) {

    .predictionlist-type-div,
    .predictionlist-status-div {
        margin-top: 16px;
    }

    .predictionlist-odd-div,
    .predictionlist-Staked-coins,
    .predictionlist-possible-win,
    .predictionlist-type-div,
    .predictionlist-status-div {

        border-right: 1px solid #F2F3F7;
        padding-left: 0px;
        padding-right: 0px;
    }

    .addedUser-prediction-data .row {
        --bs-gutter-x: 0px;
    }

    .notifiction-model-container .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
        width: 100%;
        margin: 0 auto;
        padding-left: 32px;
        justify-content: unset !important;
    }

    .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
        padding-top: 16px;
    }

    .more-prediction-model-div .prediction-slip-odds-stakedCoin-possibleWin-type-status-div,
    .wallet-model-container .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
        padding: 16px 0px 0px 32px;
        justify-content: unset !important;
    }

    .predictionlist-Staked-coins,
    .predictionlist-status-div,
    .predictionlist-possible-win {
        padding-left: 24px;
    }
}

.more-prediction-model-div .prediction-slip-odds-stakedCoin-possibleWin-type-status-div,
.wallet-model-container .prediction-slip-odds-stakedCoin-possibleWin-type-status-div,
.notifiction-model-container .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    border-top: 1px solid #F2F3F7;
    border-radius: 6px;
}

.notifiction-model-container .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
    padding-left: 32px;
}

.single-user-data .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
    border-top: unset !important
}

@media only screen and (min-width: 993px) {

    /* .feed-left-sidebar {
        max-width: 272px;
        width: 100%;
    }

    .feed-right-sidebar {
        max-width: 968px;
        width: 100%;
    } */
    .predictionlist-odd-div {
        max-width: 139.2px;
        width: 100%;
        /* border-right: 1px solid #F2F3F7; */
    }

    .notifiction-model-container .predictionlist-odd-div {
        max-width: 125.2px;
    }

    .single-user-data .predictionlist-odd-div {
        max-width: 123.2px;
    }

    .more-prediction-model-div .predictionlist-odd-div,
    .more-prediction-model-div .predictionlist-Staked-coins,
    .more-prediction-model-div .predictionlist-possible-win,
    .more-prediction-model-div .predictionlist-type-div,
    .more-prediction-model-div .predictionlist-status-div,
    .wallet-model-container .predictionlist-odd-div,
    .wallet-model-container .predictionlist-Staked-coins,
    .wallet-model-container .predictionlist-possible-win,
    .wallet-model-container .predictionlist-type-div,
    .wallet-model-container .predictionlist-status-div {
        max-width: 138.2px;
        width: 100%;
    }



    .single-user-data .predictionlist-Staked-coins,
    .single-user-data .predictionlist-possible-win,
    .single-user-data .predictionlist-type-div,
    .single-user-data .predictionlist-status-div {
        max-width: 131.2px !important;
    }

    .single-user-data .prediction-slip-odds-stakedCoin-possibleWin-type-status-div {
        padding: 16px 0 0 0 !important;
    }

    .predictionlist-Staked-coins,
    .predictionlist-possible-win,
    .predictionlist-type-div,
    .predictionlist-status-div {
        max-width: 150.2px;
        width: 100%;
        border-left: 1px solid #F2F3F7;
        padding-left: 24px;
        padding-right: 0px;
    }

    .notifiction-model-container .predictionlist-Staked-coins,
    .notifiction-model-container .predictionlist-possible-win,
    .notifiction-model-container .predictionlist-type-div,
    .notifiction-model-container .predictionlist-status-div {
        max-width: 140.2px;
    }

}

.predictionlist-odd-div .odds-title,
.predictionlist-Staked-coins .predictionlist-coins-title,
.predictionlist-possible-win .win-title,
.predictionlist-type-div .type-title,
.predictionlist-status-div .status-title {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
    padding-bottom: 4px;
}



.predictionlist-status-div {
    border-right: none;
}

.predictionlist-status-div .data,
.live-type-box-div {
    padding: 6px 8px;
    border-radius: 6px;
    width: max-content;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #131520;
}

.live-type-box-div {
    background-color: #D14343 !important;
    color: #ffffff;
    padding: 4px 8px;
}

.more-prediction-model-div .modal-dialog,
.more-prediction-model-div .modal-content {
    max-width: 764px;
    width: 100%;
}

.more-prediction-model-div .modal-content {
    padding: 32px 3px 22px 0px;
}

.more-prediction-model-div .modal-header {
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 10px;
    padding-right: 29px;
    padding-left: 32px;
}

.more-prediction-model-div .model-close-btn {
    margin: 4.13px;
}

.model-prdiction-card {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    padding: 0px 16px 16px 16px;
}

.more-prediction-model-div .prediction-list-content {
    margin-left: 0px
}

.more-prediction-model-div .modal-body {
    padding: 0px;
}

.more-prediction-model-div .model-prdiction-card {
    margin-right: 27px;
    margin-left: 32px;
    margin-bottom: 24px;
}

.model-prediction-team-data {
    border-bottom: 1px solid #F2F3F7;
    padding-bottom: 16px;

}

.model-prediction-team-data:last-child {
    border-bottom: none;
    padding-bottom: 0px;
}


.more-prediction-model-div .both-team-league-time-sport {
    padding-top: 16px;
}

.prediction-list-model-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 19rem;
    height: 100%;
    overflow-x: hidden;
}

.prediction-list-model-scroll::-webkit-scrollbar {
    width: 5px;
    height: 124px;
}

.prediction-list-model-scroll::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.prediction-list-content .alert-circle-image-tooltip {
    min-width: 346px;
    bottom: 26px;
    left: 60%;
}

.predition-edit-delete-user {
    height: max-content;
}

.more-prediction-model-div .team1-logo,
.more-prediction-model-div .team2-logo,
.edit-delete-prediction-model .team1-logo,
.edit-delete-prediction-model .team2-logo {
    background-color: #F2F3F7;
}

/* edit-delete prediction */
.edit-delete-prediction-model .modal-content,
.edit-delete-prediction-model .modal-dialog,
.edit-delete-buynow-product-prediction-model .modal-content,
.edit-delete-buynow-product-prediction-model .modal-dialog {
    max-width: 484px;
}

.edit-delete-prediction-model {
    padding: 0px !important;
}

.edit-delete-prediction-model .modal-header {
    padding-bottom: 6px;
}

.edit-delete-prediction-model .selected-fliter {
    padding: 14px 16px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.editPredictionData-div {
    padding-top: 24px;
}

.editPredictionData-div:first-child {
    padding-top: 0px;
}

.drop-down-up-down {
    position: absolute;
    right: 16px;
    bottom: 10px;
}

.score-input-feild .inputfield-label {
    margin-top: 0px;
}

.score-input-feild .inputfield-tab {
    max-width: 100%;
    padding: 14px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.editPredictionData-scroll {
    max-height: 31rem;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.editPredictionData-scroll::-webkit-scrollbar {
    display: none;
}

.editPredictionData-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.share-model-property .odds-title,
.share-model-property .predictionlist-coins-title,
.share-model-property .win-title,
.share-model-property .type-title,
.share-model-property .status-title {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.share-model-property .data {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.share-model-content .status-text {
    max-width: max-content;
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

}

.share-model-content {
    border-right: 1px solid rgba(255, 255, 255, 0.10);
    ;
}

.share-model-content:nth-child(3) {
    border-right: unset
}

.share-model-divider-line {
    margin-bottom: 8px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    margin-left: 11px;
    margin-right: 11px;
    max-width: calc(100% - 22px);
}


.coef{
    display: flex;
    align-items: end;
}

.alert-circle-tooltip-div{
    display: inline-block;
}

.alert-circle-image{
    margin-bottom: 3px;
}

.coef-data{
    display: inline-block;
}

/* .prediction-slip-decription{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
} */

.no-predictions-text{
    max-width: 640px;
}