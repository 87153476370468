.game-details__main-section {
    /* max-width: 1440px; */
    margin: 0 auto;
    display: flex;
    height: calc(100vh - 70px);
}

.game-details__left-box {
    max-width: 22%;
    width: 100%;
    background-color: #fff;
    padding: 16px 24px 24px 24px;
}

.game-details_count-loader {
    width: 37px;
    height: 24px;
    border-radius: 4px;
}

.game-details_coins-count-loader {
    width: 72px;
    height: 24px;
    border-radius: 4px;
}

.game-details__central-box {
    display: flex;
    flex-direction: column;
    max-width: 54%;
    width: 100%;
    height: 100%;
    background-color: #F2F3F7;
    border: 1px solid #F2F3F7;
    border-top: none;
}

.game-details__chat {
    max-width: 24%;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.game-details__chat-title,
.game-detail__tabs {
    height: 64px;
    background-color: #fff;
    padding: 0 24px;
    display: flex;
    gap: 32px;
    border-bottom: 1px solid #F2F3F7;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    flex-shrink: 0;
}

.game-details__chat-title {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
}

.game-detail__tab {
    height: 100%;
    align-content: center;
    cursor: pointer;
    border-bottom: 2px solid #fff;
}

.game-detail__tab.active {
    border-bottom: 2px solid #0D393A;
}

.game-detail__tab:hover {
    border-bottom: 2px solid #0D393A;
}

.game-details__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    overflow-y: scroll;
    -ms-overflow-style: none;
}

.game-details__content::-webkit-scrollbar {
    display: none;
}

.game-details__content-section {
    background-color: #fff;
    border-radius: 6px;
}

.game-details__content-title {
    padding: 20px 32px;
    border-bottom: 1px solid #F2F3F7;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.game-details__overview {
    display: flex;
}

.game-details__predictions-item,
.game-details__overview-item {
    width: 100%;
    padding: 24px 32px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.game-details__overview-item:not(:last-child) {
    border-right: 1px solid #F2F3F7;
}


.predictions-item__title,
.overview-item__title {
    color: #7F87B3;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.predictions-item__title:hover,
.overview-item__title:hover {
    color: #131520;
}

.predictions-item__info,
.overview-item__info {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.game-details__predictions-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 20px 32px;
}

.game-details__predictions-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 0 32px;
}

.game-details__predictions-item {
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    min-height: 120px;
}

.game-details__predictions-item.total-count {
    grid-row: span 2;
    height: 100%;
    background-color: #0D393A;
    color: #FFF !important;
    border: none;
}

.game-details__predictions-item.total-count.loading {
    opacity: 60%;
}

.game-details__predictions-item.total-count .predictions-item__info {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.game-details__predictions-item.total-count .predictions-item__title {
    color: #fff;
}

.game-details__content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 0 32px;
    gap: 32px;
}

.game-details__content-wrapper.chat_content-wrapper {
    height: fit-content !important;
    overflow: hidden;
    padding: 0;
}

.chart-delimeter {
    width: 100%;
    height: 1px;
    background-color: #F2F3F7;
}

.game-details__overview-wrapper {
    padding: 0;
}

.game-details__video-overview-wrapper {
    padding: 32px;
}

.game-details__chat-section {
    display: none;
}

.comming {
    padding: 32px 20px;
}

.game-details__main-section .tooltip-text {
    width: 180px
}

.bottom-h1-section {
    color: #4C5480;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
}

.full-screen__chat {
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.full-screen__chat-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    display: flex;
    gap: 16px;
    background-color: #fff;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    flex-shrink: 0;
}

.full-screen__chat-title img {
    cursor: pointer
}

.full-screen__chat-section {
    padding: 24px;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.full-screen__chat-section .chat__wrapper {
    gap: 16px;
}

.full-screen__chat-section .chat__input-form_wrapper,
.full-screen__chat-section .chat__messages-wrapper {
    background-color: #fff;
    border-radius: 6px
}

.full-screen__chat-section .chat__messages-wrapper {
    /* min-height: 100%; */
    /* height: max-content; */
    flex-grow: 1;
}

@media only screen and (max-width: 1280px) {
    .game-details__main-section {
        width: 100%;
        margin: 0;
    }

    .game-details__left-box {
        max-width: 30%;
        width: 30%;
    }

    .game-details__central-box {
        max-width: 70%;
        width: 70%;
    }

    .full-screen__chat {
        max-width: 70%;
        width: 70%;
        border: 1px solid #F2F3F7;
    }

    .game-details__chat {
        display: none;
    }

    .game-details__chat-section {
        display: block;
    }
}

@media only screen and (max-width: 1024px) {
    .game-details__central-box {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        height: calc(100dvh - 70px);
        overflow-y: auto;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
    }

    .game-details__left-box {
        display: none;
    }

    .game-details__content {
        overflow-y: unset;
        height: auto;
        -ms-overflow-style: none;
    }

    .game-detail__tabs {
        width: 100%;
        overflow-x: scroll;
        /* position: sticky; */
        top: 70px;
    }

    .game-details__content-title {
        padding: 20px 24px;
    }

    .game-details__overview-item {
        padding: 24px;
    }

    .game-details__predictions-item{
        padding: 24px 24px;
    }

    .top-game-predictions,
    .game-details__predictions-content{
        padding: 0 24px;
    }

    .top-game-predictions__title{
        margin-bottom: 20px;
    }

    .game-details__predictions-title {
        padding: 0 24px;
        margin-bottom: 20px;
    }

    .full-screen__chat{
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .game-details__content {
        padding: 16px;
    }

    .game-details__content-title {
        padding: 12px 16px;
        font-size: 18px;
    }

    .game-details__overview {
        flex-direction: column;
    }

    .game-details__overview-item {
        width: 100%;
        padding: 20px 16px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .game-details__overview-item:not(:last-child) {
        border-bottom: 1px solid #F2F3F7;
    }

    .game-details__predictions-title {
        padding: 0 16px;
        margin-bottom: 20px;
    }

    .game-details__predictions-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        padding: 0 16px;
    }

    .game-details__predictions-item{
        padding: 16px;
    }

    .game-details__predictions-item.total-count {
        grid-column: span 2;
    }

    .bottom-h1-section {
        padding: 16px;
    }

    .game-details__content-wrapper{
        padding: 24px 0;
        gap: 24px;
    }

    .game-details__predictions-item.total-count .predictions-item__info{
        font-size: 18px;
        line-height: 24px;
    }

    .top-game-predictions{
        padding: 0 16px;
    }

    .full-screen__chat-title,
    .full-screen__chat-section {
        padding: 16px;
    }
}