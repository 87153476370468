.tooltip-wrapper {
  position: relative;
  width: fit-content;
}

.tooltip-wrapper .tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: #131520;
  color: white;
  text-align: center;
  border-radius: 8px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
}

@media screen and (max-width: 1024px) {
  .tooltip-wrapper .tooltip-text{
    width: 150%
  }
}

@media screen and (max-width: 768px) {
  .tooltip-wrapper .tooltip-text{
    width: 130%
  }
}