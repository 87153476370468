.sidebar-container {
    padding-top: 32px;

}

.dashBoard-user-btn {
    padding: 12px 12px 12px 16px;
    width: 100%;
    justify-content: unset;
    align-items: center;
}

.dashborad-user-active {
    padding: 12px 12px 12px 16px;
    width: 100%;
    justify-content: unset;
    background: #F2F3F7;
    color: #131520;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border-radius: 6px;
    align-items: center;
}