.User-panel-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.users-top-div {
    margin-bottom: 24px;
}

.userTable-container {
    max-width: 420px;
    width: 100%;
    margin-bottom: 24px;
}

.allUserCount span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.allUserCount {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}