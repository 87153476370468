.progress-bar-container {
    width: 100%;
    height: 8px;
    background-color: #DFE1EC;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #49D384;
    transition: width 0.5s ease-in-out;
    border-radius: 3px;
  }

  .progress-bar-title{
    display: flex;
    justify-content: space-between;
    color: #7F87B3;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
  }