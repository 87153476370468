.prediction-list-container {
    max-width: 1270px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
}

.feed-left-sidebar {
    padding-right: 32px;
}

.feed-right-sidebar {
    padding-left: 32px;
}

.prediction-list-section {
    margin-top: 24px;

}

/* 
.notlogin-user-predictionList .prediction-list-section {
    margin-top: 24px;
} */

/* .notLogedin-user {
    margin-top: 0px !important;
} */

.feed-containt {
    background-color: #ffffff !important;
}

.prediction-list-searchbar {
    max-width: 420px;
    width: 100%;
    display: flex;
    align-items: center;
}

.filter-section {
    margin-bottom: 32px;
}

.filter-searchbar-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.prediction-found-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.prediction-found-title span,
.SortBy-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.prediction-found-title span {
    padding-right: 4px;
}

.predictionfound-sortby-contains {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.sortby-section {
    /* max-width: 215px;
    width: 100%; */
    margin: auto 0;
}

.prediction-found-section {
    align-items: center;
}

.SortBy-name {
    display: flex;
    align-items: center;
    padding-right: 6px;
}

.img-padding {
    padding-right: 8px;
}

.sortby-section {
    display: flex;
}

/* dropdown ------- */
.sortby-dropdown {
    position: relative;
}

.sortby-container {
    /* max-width: 160px;
    width: 100%; */
}

.selected-fliter {
    justify-content: space-between;
    border: 1px solid #DFE1EC;
    align-items: baseline;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.fliter-type {
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    height: auto;
    padding-left: 0;
}

.filter-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.filter-menu {
    padding: 10px 8px 10px 8px;
}

.filter-menu:hover {
    background-color: #F2F3F7;
    cursor: pointer;
    border-radius: 6px;
}

/* filterDropdown */
.sport-contains,
.Tournament-contains,
.PredictionType-contains,
.Status-contains,
.OddsRange-contains,
.All-tournament-contains,
.All-upcoming-contains {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #F2F3F7;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 8px;
    color: #131520;
    justify-content: space-between;
    margin-right: 8px;
    cursor: pointer;
}

.All-tournament-contains,
.All-upcoming-contains {
    font-weight: 600;
}

.Clear-filters-contains {
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    cursor: pointer;
}

.Clear-filters-DropDown {
    display: flex;
    align-items: center;
}

.sport-data,
.Tournament-data,
.PredictionType-data,
.Status-data,
.OddsRange-data,
.tournament-data,
.upcoming-data {
    padding-right: 8px;
}

.popular-league-title,
.country-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    margin-bottom: 4px;
}

.country-title {
    margin-bottom: 12px;
}

/* range-silder */
.range-container {
    min-width: 280px;
    width: 100%;
    padding: 16px;
    background-color: #ffffff;
    border: 1px solid #DFE1EC;
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    border-radius: 6px;
    margin-top: 5px;
    position: absolute;
    z-index: 1;
}


.range-min-value,
.range-max-value {
    max-width: 85px;
    width: 100%;
    padding: 8px 16px;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    text-align: center;
}

.range-min-value:focus,
.range-max-value:focus {
    border: 1px solid #DFE1EC !important;
    box-shadow: none;
}



.filter-searchbar-section .searchbar-feild,
.filter-searchbar-section .searchbar span {
    padding: 12px 16px;
}

.feed-left-sidebar {
    padding-left: 0px;
}

.Recomended-following-btn-style {
    justify-self: unset !important;
}

@media screen and (max-width: 1024px) {
    .feed-left-sidebar {
        padding-right: 0px;
    }
}

@media screen and (max-width: 767.99px) {
    .Recomended-following-div {
        display: flex;
        width: 100%;
    }

    .notification-modal .modal-dialog {
        max-width: unset;
        width: unset;
    }

    .windata-title,
    .lostdata-title,
    .returndata-title,
    .tooltip-title-date {
        font-weight: 300;
        font-size: 8px;
        line-height: 12px;
        color: #7F87B3;
    }

    .windata-count,
    .lostdata-count,
    .returndata-count {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #131520;
    }

    .feed-left-sidebar {
        padding-left: 0px;
        padding-right: 0px;
    }

    .custom-tooltip {
        padding: 12px !important;
    }

    .following-part,
    .Recomended-part-btn {
        padding: 0;
    }

    .Recomended-following-div {
        margin: 0px;
    }

    .prediction-list-section {
        margin-top: 16px;
    }

    .Recomended-following-button {
        margin-bottom: 0px;
    }

    .prediction-list-container {
        padding: 0 0px;
    }

    .prediction-list-btn {
        width: calc(100% - 32px);
        position: fixed;
        bottom: 16px;
        text-align: center;
        left: 16px;
        right: 16px;
        z-index: 10;
    }

    .feedsidebar-buttons {
        padding-bottom: 16px !important;
        padding-right: 16px;
        padding-left: 16px;
    }

    .propular-event,
    .Popular-leagues {
        display: none;
    }

    .feed-right-sidebar {
        padding-left: 0px;
        padding-right: 0px;
    }

    .filter-searchbar-section {
        margin-top: 16px;
    }

    /* .searchbar-feild {
        max-width: 244px;
        width: 100%;
    } */

    .sortby-section {
        display: none;
    }

    .filter-section {
        margin-bottom: 16px;
    }

    .filter-searchbar-section,
    .predictionfound-sortby-contains {
        padding-left: 16px;
        padding-right: 16px;
    }

    .sport-contains,
    .Tournament-contains,
    .PredictionType-contains,
    .Status-contains,
    .OddsRange-contains {
        margin-right: 0px;
        padding: 8px;
    }

    .sport-contains,
    .Tournament-contains,
    .PredictionType-contains,
    .Status-contains {
        margin-bottom: 8px;
    }

    .filtered-items {
        flex-direction: column;
        padding: 0 16px;
    }

    .filter-btn {
        margin-left: 16px;
    }

    .modal-dialog {
        max-width: 551px;
        width: 100%;
    }

    .Recomended-following-btn-style {
        justify-content: center !important;
    }

    .sport-DropDown .showSuggetions-filter,
    .tournament-DropDown .showSuggetions-filter,
    .PredictionType-DropDown .showSuggetions-filter,
    .Status-DropDown .showSuggetions-filter,
    .range-container {
        width: 100%;
        padding-left: 16px;
        padding-right: 13px;
        top: 40px;
        /* left: 16px;
        right: 16px;
        max-width: calc(100% - 32px); */
    }

    /* .sport-DropDown .showSuggetions-filter {
        top: 16rem;
    } */

    /* .tournament-DropDown .showSuggetions-filter {
        top: 19rem;
    } */

    .Recomended-following-sidebar-btn,
    .Recomended-following-sidebar-btn-active {
        justify-content: center;
    }

    /* .PredictionType-DropDown .showSuggetions-filter {
        top: 22rem;
    } */

    /* .Status-DropDown .showSuggetions-filter {
        top: 25rem;
    } */

    .filter-data-icon-button {
        width: 100%;
        margin-bottom: 8px;
    }

    .filter-sport-container {
        padding: 0px 0px;
    }

    .tournament-DropDown,
    .PredictionType-DropDown,
    .Status-DropDown,
    .OddsRange-DropDown {
        margin-left: 0;
    }

    .slider {
        max-width: calc(100% - 16px) !important;
        width: 100% !important;
    }

    .thumb {
        max-width: calc(100% - 32px) !important;
        width: 100% !important;
    }
}

.groupOftournamentUpcoming {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tournament-filter-notificatrionPart {
    display: flex;
    justify-content: space-between;
}


.allTournament-scrollbar {
    max-height: 170px;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 8px 3px 8px 8px;
}

.allTournament-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 124px;
}

.allTournament-scrollbar::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.Add-tournament-button {
    border-top: 1px solid #DFE1EC;
}

.groupOftournamentUpcoming .showSuggetions-filter {
    padding: 0px !important;
}

.Add-tournament-button {
    padding: 10px 16px 10px 16px;
    display: flex;
    cursor: pointer;
}

.Add-tournament-button .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-left: 8px;
    width: 100%;
}

.ads-container{
    width: 200px;
    position: fixed;
    top: 280px;
}