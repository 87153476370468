.addPrediction-tab .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 16px;
}

.all-tab-table {
    margin-top: 16px;
}


.addPrediction-tab .nav-tabs .nav-link.active {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #131520;
    padding: 0;
}

.live-tab-data,
.sheduled-tab-data {
    min-height: 30rem;
    height: 100%;
}

.back-btn {
    padding-right: 21px;
    cursor: pointer;
}

.addPrediction-tab .nav-item {
    padding-right: 24px;
}

.edit-button-tooltip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    /* gap: 10px; */
    /* width: 43px; */
    height: 32px;
    background: #131520;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #FFFFFF;
    padding: 8px 12px;
}

.tooltip-edit-div {
    position: relative;
}

.edit-button-tooltip {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 123%;
    left: 50%;
    transform: translateX(-50%);
}

.fade.modal-backdrop.show {
    z-index: 1055;
}

.live-link-empty-msg {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 300;
    margin-top: 16px;
    margin-bottom: 16px;
}

.edit-button-tooltip:after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: #131520 transparent transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;

}

.tooltip-edit-div:hover .edit-button-tooltip {
    opacity: 1;
    visibility: visible;
}

.addPrediction-tab .nav-link {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    padding: 0;
}

.addPrediction-tab .tab-content .tab-pane {
    max-width: 100%;
    width: 100%;
}

.champions-table-Header th,
.smallScreen-addPredictionTable .champions-table-Header {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}


.champions-leaue-country-name {
    text-align: unset !important;
    padding-left: 24px;
}

.champions-leaue-country-name span {
    padding-right: 8px;
}

.champions-add-btn {
    padding-right: 24px;
}

.champions-leaue-first-score {
    padding-left: 16px !important;
    padding-top: 18px;
    padding-bottom: 18px;
}

.champions-leaue-last-score {
    padding-right: 24px !important;
}

.tournamentname-tab-col .champions-leaue-score {
    width: 65px;
}

.champions-leaue-score {
    /* padding: 12px;
    max-width: 54px;
    width: 100%;
    margin: 0 auto; */
    padding: 12px;
    background-color: #F9F9FB;
    /* width: 55px; */
    justify-content: center;
    display: flex;
    margin: 0 auto;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    cursor: pointer;
}

.champions-leaue-score-active {
    background-color: #0D393A !important;
    color: #FFFFFF;
}

.champions-leaue-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.champions-top {
    padding-bottom: 4px;
}

.leaue-score {
    border-bottom: 1px solid #F2F3F7;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 80px
}

.score-0-1 {
    padding-right: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.add-some-predication-model .model-extra-css {
    height: 10 0vh !important;
}

.add-some-predication-model .modal-dialog {
    margin-top: 0px;
    margin-bottom: 0px !important;
}

.sportButtons {
    display: flex;
    flex-wrap: wrap;
}

.last-score-box {
    border: 1px solid #DFE1EC;
    background-color: #ffffff;
    /* max-width: 70px;
    width: 100%; */
}

.champions-leaue-logo {
    padding-right: 8px;
    width: 32px;
    height: 24px;
}

.champions-leaue-score-timer-alert {
    color: #D14343;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    padding-right: 24px;
    max-width: 34px;
    width: 100%;
}

.champions-leaue-score-timer,
.champions-leaue-score-date {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
    padding-right: 24px;
}

.champions-leaue-score-timer {
    text-align: center;
}

.champions-leaue-score-date-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.champions-leaue-score-date {
    padding-bottom: 4px;
}

.champions-leaue-score {
    text-align: center;
    max-width: 85px;
}

.addPrediction-tab .champions-table-Header {
    background-color: #F2F3F7;
}

/* .champions-table-Header div {
    background-color: #F2F3F7;
} */
.champions-leaue-country-name-div {
    border-radius: 6px;
}

.champions-leaue-score-body {
    border-left: 1px solid #F2F3F7;
    border-right: 1px solid #F2F3F7;
    /* border-bottom: 1px solid #F2F3F7; */
}

.leauge-alldata-name-score:hover {
    background-color: #F2F3F7;
    cursor: pointer;
}

.notification-part {
    display: none;
}

.buttons-container {
    border-bottom: 1px solid #F2F3F7;
    margin-bottom: 24px;
}

.addPrediction-tab .tab-content .tab-pane svg {
    width: 100%;
}

.userProfile-prediction-tab .tab-content .tab-pane {
    max-width: 100%;
    width: 100%;
}

.add-some-predication-model .modal-content {
    margin-top: 0px;
    border: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.mediumScreen-addPredictionTable {
    display: none;
}

.smallScreen-addPredictionTable {
    display: none;
}

.responsive-addPrediction-right-panel,
.mediumBetSlip-icon,
.mobileBetSlip-icon,
.addPrediction-right-panel-hide {
    display: none;
}

@media screen and (min-width: 1281px) {
    .champions-leaue-score {
        max-width: 65px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1127px) {
    .champions-leaue-score {
        min-width: 70px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1311px) {


    .leaue-score {
        max-width: 60px;
    }
}

/* media query for addPrediction */
/* large scrren */

@media screen and (min-width: 1024px) and (max-width: 1280px) {
    .live-data-div {
        margin-top: 60px !important;
    }

    .add-some-predication-model .modal-content {
        padding: 32px;
    }

    .addPrediction-tab .tabs {
        margin-top: 0px
    }

    .mediumBetSlip-icon {
        display: block;
    }

    .responsive-addPrediction-right-panel {
        max-width: 476px;
        width: 100%;
    }

    .addPrediction-tab .nav-tabs {
        position: relative;
    }

    .mediumScreen-addPredictionTable,
    .mobileBetSlip-icon {
        display: none;
    }

    .addPrediction-right-panel {
        display: none;
    }

    .responsive-addPrediction-right-panel {
        display: block;
        position: absolute;
        top: 70px;
        right: -25px;
        z-index: 1;
        /* height: 100%; */
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    }

    .responsive-addPrediction-right-panel .card-body {
        padding-left: 24px;
        padding-right: 5px;
        padding-bottom: 24px;
        padding-top: 24px;
    }

    .responsive-addPrediction-right-panel .card {
        /* height: 100vh; */
    }

    .addPrediction-panel-farme,
    .betSlip-scroll,
    .betSlip-title,
    .total-odds-section,
    .predication-decription-section {
        padding-right: 19px;
    }

    .responsive-addPrediction-right-panel .card {
        --bs-card-border-color: #F2F3F7;
        --bs-card-border-width: 1px;
    }

    .smallScreen-addPredictionTable {
        display: none;
    }

    .addPrediction-left-panel {
        max-width: 100%;
        width: 100%;
        margin-right: 0px;
    }

    .top-panel {
        margin-bottom: 24px;
    }

    .top-coupon-part .notification-part {
        cursor: pointer;
        width: 44px;
        z-index: 1;
        height: 44px;
        justify-content: center;
        display: flex;
        align-items: center;
        padding-top: 13.75px;
        padding-bottom: 13.75px;
        padding-right: 12.75px;
        padding-left: 12.75px;
        border: 1px solid #F2F3F7;
        border-radius: 6px;
        position: relative;
    }


}

/*  medium screen / teblet */
@media screen and (min-width: 768px) and (max-width: 1023.99px) {
    .largeScreen-addPredictionTable {
        display: none;
    }

    .addPrediction-right-panel {
        display: none;
    }

    .add-some-predication-model .modal-content {
        padding: 32px;
    }

    .mediumScreen-addPredictionTable {
        display: block;
    }

    .addPrediction-left-panel {
        max-width: 100%;
        width: 100%;
        margin-right: 0px;
    }

    .smallScreen-addPredictionTable {
        display: none;
    }
}

.add-prediction-operation-button,
.addPrediction-prediction-title {
    display: flex;
}

.add-prediction-operation-buttonFor-mobile {
    display: none;
}

.predictionData-Count {
    margin-left: 8px;
    padding: 0px 6px 0px 6px;
    background-color: #0D393A;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border-radius: 6px;

}

.responsive-addPrediction-right-panel-hide {
    display: none;
}

.betSlip-textArea {
    max-width: 100%;
}

@media screen and (max-width:1023.99px) {
    .addPrediction-tab .nav-tabs {
        position: relative;
    }

    .addPrediction-tab .tabs {
        margin-top: 0px
    }

    .mobileBetSlip-icon {
        display: block;
    }

    .mediumBetSlip-icon {
        display: none;
    }


    .betSlip-textArea {
        max-width: 100%;
    }

    .responsive-addPrediction-right-panel {
        display: block;
        position: absolute;
        z-index: 10;
        right: 0;
        /* top: 360px; */
        height: 100%;
        bottom: 0;
        width: 100%;
        background-color: #FFFFFF;
    }

    .responsive-addPrediction-right-panel .card-body {
        padding-left: 24px;
        padding-right: 5px;
        padding-bottom: 45px;
        padding-top: 24px;
    }



    .addPrediction-panel-farme,
    .betSlip-scroll,
    .betSlip-title,
    .total-odds-section,
    .predication-decription-section {
        padding-right: 19px;
    }

    .responsive-addPrediction-right-panel .card {
        --bs-card-border-color: #F2F3F7;
        --bs-card-border-width: 0px;
        width: 100%;
        /* overflow-y: scroll;
        height: 35rem; */
    }

    /* .responsive-addPrediction-right-panel .card::-webkit-scrollbar-thumb {
        background: #BFC3D9;
        border-radius: 6px;
    }

    .responsive-addPrediction-right-panel .card::-webkit-scrollbar {
        width: 5px;
        height: 124px;
    } */

    .top-coupon-part .notification-part {
        cursor: pointer;
        z-index: 1;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 13.75px;
        padding-bottom: 13.75px;
        padding-right: 12.75px;
        padding-left: 12.75px;
        border: 1px solid #F2F3F7;
        border-radius: 6px;
        position: relative;
    }


    .notification-badge-set .notification-part {
        top: 0 !important;
    }


}

.Chelsea-title-mobile,
.mobile-Total-div-box {
    display: none;
}

.extra-odds-data-list {
    padding-left: 16px;
}

/* mobile - small device */
@media screen and (max-width:767.99px) {
    .smallScreen-addPredictionTable {
        display: block;
    }

    .Over-title,
    .Under-title,
    .Real-Madrid-title,
    .Chelsea-title {
        padding: 0px !important;
    }

    .mobile-Real-Madrid-title,
    .Chelsea-title {
        justify-content: center;
        padding-bottom: 16px !important;
    }

    .Chelsea-title {
        padding-top: 11px !important;
    }

    .extra-full-time-team-1 {
        margin-left: 14px;
    }

    .extra-odds-data-list {
        padding-left: 0px;
    }

    .extra-full-time-team-1 {
        /* width: calc(100% - 28px); */
        margin-left: 0px !important;
    }

    .Handicap-data-box {
        flex-direction: column;
    }

    .Handicap-team1-score {
        margin-right: 0px;
    }

    .full-time-result-data-box,
    .Double-chance-data-box {
        flex-direction: column;
    }

    .Chelsea-title-desktop,
    .desktop-Total-div-box {
        display: none;
    }

    .Chelsea-title-mobile,
    .mobile-Total-div-box {
        display: block;
    }

    .full-time-team-1,
    .full-time-team-2,
    .full-time-team-3,
    .Double-chance-team-1,
    .Double-chance-team-2,
    .Double-chance-team-3,
    .Handicap-team1-score,
    .Handicap-team2-score {
        margin-left: 0px !important;
    }

    .leaue-score-box {
        max-width: 25% !important;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px !important;
    }

    .champions-leaue-score {
        max-width: unset;
    }

    .last-score-box {
        max-height: 73.33px;
        height: 100%;
        display: flex !important;
        align-items: center;
    }

    .leaue-score {
        margin-left: -8px !important;
        margin-right: -8px !important;
    }


    .smallScreen-addPredictionTable .champions-table-Header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .addPrediction-right-panel,
    .mediumScreen-addPredictionTable,
    .largeScreen-addPredictionTable {
        display: none;
    }

    .add-some-predication-model .modal-dialog {
        margin-left: 0px;
    }

    .addPrediction-left-panel {
        max-width: 100%;
        width: 100%;
        margin-right: 0px;
    }

    .add-some-predication-model .modal-content {
        padding: 24px 16px 24px 16px;
    }

    .smallScreen-addPredictionTable .champions-league-detail {
        padding-bottom: 16px;
        max-width: 253px;
        width: 100%;
        justify-content: space-between;
    }

    .add-prediction-operation-buttonFor-mobile img {
        padding: 12px 18px 12px 18px;
        cursor: pointer;
    }

    .add-prediction-operation-buttonFor-mobile {
        display: flex;
    }

    .add-predication-model .modal-dialog,
    .shareprofile-model .modal-dialog {
        max-width: 100%;
        width: unset;
    }

    .add-prediction-operation-button {
        display: none;
    }

    .smallScreen-addPredictionTable .champions-table-containts {
        border-left: 1px solid #F2F3F7;
        border-bottom: 1px solid #F2F3F7;
        border-right: 1px solid #F2F3F7;
        padding: 16px;
    }

    .smallScreen-addPredictionTable .champions-leaue-score {
        display: block;
        padding: 12px 20px 12px 20px;
        width: unset;
    }

    .smallScreen-addPredictionTable .champions-leaue-last-score {
        padding-right: 0px;
    }

    .leaue-score {
        border-bottom: none;
    }

    .leaue-score-test {
        max-width: 100%;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media screen and (max-width:411.99px) {
    .smallScreen-addPredictionTable .champions-leaue-score {
        padding: 4px;
    }

    .leaue-score-box {
        padding-left: 0px;
    }

    .leaue-score {
        padding-right: 0px;
    }
}


.champions-league-table table:first-child .champions-table-Header .champions-leaue-country-name:first-child {
    border-top-left-radius: 6px;
}

.champions-league-table table:first-child .champions-table-Header .champions-add-btn:last-child {
    border-top-right-radius: 10px;
}

/* .champions-league-table table:last-child .champions-leaue-score-body .leaue-score:first-child {
    border-bottom-left-radius: 10px;
}

.champions-league-table table:last-child .champions-leaue-score-body .leaue-score:last-child {
    border-bottom-right-radius: 10px;
} */

/* .add-some-predication-model .modal-content {
    overflow: hidden;
} */

.delete-sport-model .modal-content {
    max-width: 341px;
    margin: 100px auto 0 auto;
    width: 100%;
}

.delete-sport-model .delete-sport-Msg {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.extra-Champions-League {
    display: flex;
    padding: 16px 24px;
    background-color: #F2F3F7;
    border: 1px solid #F2F3F7;
    border-radius: 6px 6px 0px 0px;
}

.back-btn {
    padding-right: 21px;
    cursor: pointer;
}

.Champions-League-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.Champions-League-name span {
    padding-right: 8px;
}

.teamName-data-time {
    padding-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 32px;
    border-bottom: 1px solid #F2F3F7;
    border-left: 1px solid #F2F3F7;
    border-right: 1px solid #F2F3F7;
}

.match-date-time {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    text-align: center;
    margin-bottom: 24px;
}

.minus-btn {
    font-weight: 300;
    font-size: 42px;
    line-height: 42px;
    color: #131520;
    display: flex;
    align-items: center;
}

.team-1-name,
.team-2-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #131520;
}

.full-time-result,
.Double-chance,
.total-odds-contains,
.Handicap-contains,
.RealMadridTotal-contains,
.Teamtoscorethegoal-contains,
.Bothteamstoscore-contains,
.Correctscore-contains {
    padding-bottom: 19px;
    border-bottom: 1px solid #F2F3F7;
}

.total-odds-contains,
.Handicap-contains,
.RealMadridTotal-contains,
.Teamtoscorethegoal-contains,
.Bothteamstoscore-contains,
.Correctscore-contains {
    padding-bottom: 8px;
}

.full-time-result-title,
.Double-chance-title,
.total-odds-title,
.Handicap-title,
.RealMadridTotal-title,
.Teamtoscorethegoal-title,
.Bothteamstoscore-title,
.Correctscore-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    margin-bottom: 16px;
    margin-top: 24px;
}

.extra-full-time-team-1 {
    margin-left: 16px;
}

.total-teams {
    padding: 16px;
}

.Over-title,
.Under-title,
.Real-Madrid-title,
.Chelsea-title {
    padding: 16px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
}

.full-time-team-1,
.full-time-team-2,
.full-time-team-3,
.Double-chance-team-1,
.Double-chance-team-2,
.Double-chance-team-3,
.Handicap-team1-score,
.Handicap-team2-score {
    margin-left: 16px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F9F9FB;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    cursor: pointer;
    margin-bottom: 5px;
}

.extra-full-time-team-1 {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background: #F9F9FB;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    cursor: pointer;
    margin-bottom: 10px;
}

.full-time-team-1-active,
.Double-chance-team-1-active,
.Handicap-team1-score-active,
.extra-full-time-team-1-active {
    background-color: #0D393A;
    color: #FFFFFF;
}

.total-teams-score,
.Over-score,
.Under-score,
.RealMadridTotal-teams-score,
.teams-score,
.RealMadrid-score,
.None-score,
.Chelse-score {
    text-align: center;
    padding: 16px;
    background: #F9F9FB;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    cursor: pointer;
}

.total-teams-score-active,
.Over-score-active,
.Under-score-active,
.RealMadridTotal-teams-score-active,
.teams-score-active,
.RealMadrid-score-active,
.None-score-active,
.Chelse-score-active {
    background-color: #0D393A;
    color: #FFFFFF;
}

.total-teams-score,
.RealMadridTotal-teams-score {
    background: #FFFFFF;
}

.addSome-prediction-container {
    position: relative;
}


.addPrediction-right-panel {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100%;
}

@media (min-width: 768px) {
    .extra-odds-data-list .col-md-4 {
        flex: 0 0 auto;
        width: 31%;
    }
}

@media (min-width: 992px) {
    .extra-odds-data-list .col-lg-6 {
        flex: 0 0 auto !important;
        width: 48% !important;
    }
}

.groupOftournamentUpcoming .filter-data-icon-button {
    padding: 8px 12px 8px 12px;
}

.ThereAreNoMatches-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;
    text-align: center;
}

.tournamentOrDay-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    text-align: center;
}

.empty-data-error-message {
    padding-top: 40px;
}

@media screen and (min-width: 1281px) {
    .champions-leaue-last-score {
        min-width: 80px;
        width: 80px;
    }

    .last-score-box {
        min-width: 65px;
        width: 65px;
    }
}

.home-page-header {
    background: #F2F3F7 !important;
}


@media screen and (max-width: 1280px) {
    .notification-part {
        display: flex;
    }

    .prediction-notifiaction-part {
        display: flex;
        padding: 16px;
        background-color: #0D393A;
        border-radius: 6px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        position: fixed;
        /* bottom: 16px; */
        bottom: -200px;
        width: max-content;
        right: 80px;
        cursor: pointer;
        justify-content: space-between;
        transition: bottom 0.5s ease-in-out;
        border: 1px solid #FFFFFF;
    }

    .prediction-notifiaction-part.visible {
        bottom: 16px;
        /* Move the coupon into view */
    }

    .button__badge {
        background-color: #208B8E;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        color: #ffffff;
        padding: 1px 3px;
        font-size: 10px;
        display: flex;
        font-weight: 700;
        width: 20px;
        height: 20px;
        font-size: 12px;
        line-height: 14px;
        justify-content: center;
        align-items: center;
    }

    .top-coupon-part .button__badge {
        background-color: #0D393A;
        border-radius: 50%;
        color: #ffffff;
        padding: 1px 3px;
        font-size: 10px;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 77.27%;
        right: -31.82%;
        top: -25.73%;
        bottom: 68.18%;
        display: flex;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        justify-content: center;
        align-items: center;
    }
}