.HomePageFooter-gradient {
    background-color: #072121;
    min-height: 316.86px;
    height: 100%;
}

.HomePageFooter-container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    color: #ffffff;
    padding: 0px 15px;
}

.footer-border {
    opacity: 0.1;
    border: 1px solid #FFFFFF;
    transform: rotate(-0.27deg);
}

.footer-top-contains {
    padding-top: 90px;
    padding-bottom: 32px;
    display: flex;
    align-items: flex-start;
    /* border-bottom: 1px solid #FFFFFF; */
    /* opacity: 0.1; */
    transform: rotate(-0.27deg);

}

.footer-top-first-frame {
    max-width: 237px;
    width: 100%;
    margin-right: 200px;
}

.first-frame-decription {
    padding-top: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.Navigation-title,
.Get-in-touch-titl,
.Follow-Us-title,
.Get-in-touch-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.homePage-footer-main-div {
    /* padding-top: 24px; */
    z-index: 25;
}

.Instagram-icon:hover .Instagram-img-path,
.Twitter-img:hover .twitter-img-path,
.Tiktok-img:hover .tiktok-img-path,
.telegram-img:hover .telegram-img-path {
    stroke: #EF8352;
}

.second-frame-decription,
.third-frame-decription,
.feed-frame-decription,
.sportline-frame-decription,
.leaderboard-frame-decription,
.Privacy,
.Cookies-link,
.privacypolicy-link,
.Reserved-label,
.first-frame-2decription {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #EEFBFB;
}

.first-frame-2decription {
    font-size: 12px;
    line-height: 16px;
}

.feed-frame-decription:hover,
.sportline-frame-decription:hover,
.leaderboard-frame-decription:hover,
.privacypolicy-link:hover,
.Cookies-link:hover {
    text-decoration: underline !important;
}

.feed-frame-decription,
.sportline-frame-decription,
.leaderboard-frame-decription,
.Cookies-link,
.privacypolicy-link {
    text-decoration: none;
}

.Cookies-link:hover,
.privacypolicy-link:hover {
    color: #FFFFFF;
}

.second-frame-decription,
.third-frame-decription {
    text-decoration: none !important;
    display: flex;
    flex-direction: column;
}

.feed-frame-decription:hover,
.sportline-frame-decription:hover,
.leaderboard-frame-decription:hover,
.third-frame-decription:hover {
    color: #EEFBFB;
}

.footer-top-second-frame {
    margin-right: 83px;
}

.footer-top-third-frame {
    margin-right: 80px;
}

.Instagram-img,
.Twitter-img,
.Tiktok-img,
.telegram-img {
    margin-right: 24px;
}

.footer-copy-rights {
    padding-top: 32px;
    padding-bottom: 40px;
}

.Privacy,
.Cookies {
    padding-right: 32px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .footer-top-contains {
        flex-direction: column;
        align-items: unset;
        padding-top: 64px;
        padding-bottom: 36px;
    }

    .footer-top-first-frame {
        max-width: 100%;
        padding-bottom: 64px;
    }

    .footer-copy-rights {
        padding-bottom: 40px;
    }
}

/* mobile */
@media screen and (max-width: 767.99px) {
    .HomePageFooter-gradient {
        min-height: 677.63px;
        height: 100%;

    }

    .HomePageFooter-container {
        padding: 0 16px;
    }

    .footer-top-contains,
    .footer-bottom-second-frame {
        flex-direction: column;
    }

    .footer-top-contains {
        padding-top: 64px;
    }

    .footer-top-first-frame {
        margin: 0;
        max-width: 100%;
    }

    .first-frame-decription {
        padding-bottom: 64px;
    }

    .footer-top-second-frame,
    .footer-top-third-frame {
        margin-bottom: 40px;
    }

    .footer-copy-rights {
        flex-direction: column;
    }

    .footer-bottom-second-frame {
        align-items: unset !important;
        width: 100%;
    }

    .Privacy {
        padding-bottom: 16px;
    }

    .Cookies {
        padding-bottom: 32px;
    }

    .first-frame-2decription{
        padding-bottom: 32px;
    }

}