.learge-screen-coin-section {
    padding: 8px 10px;
}

.wallet-bosy-background {
    background-color: #ffffff !important;
}

.learge-screen-coin-section:hover {
    background-color: #F2F3F7;
    border-radius: 6px;
}

.mobile-coin-section,
.learge-screen-coin-section {
    cursor: pointer;
}

.card-coin-image {
    max-height: 64px;
    height: 100%;
}

.walletFooter .HomePageFooter-container {
    max-width: 1270px;
}

.wallet-conitener {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.wallet-section {
    padding: 32px 0px 100px 0px;
}

.wallet-title-tab {
    padding-bottom: 32px;
}

.wallet-title-tab .title,
.collect-cpa-coin-title .title,
.Earning-history-title .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #131520;
}

.wallet-title-tab .sub-title,
.collect-cpa-coin-title .sub-title,
.Earning-history-title .sub-title {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #7F87B3;
    max-width: 640px;
    width: 100%;
}

.balance-card {
    padding: 26px 26px 32px 32px;
    border-radius: 6px;
    background-image: url("/public/wallet-coin/bg.png");
    background-size: cover;
}

/* .setgradiant-background {
    background-image: url("/public/wallet-coin/bg.png");
    background-size: cover;
} */

.balance-card-left-side .title {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #DEF7F8;
}

.user-coin-count {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: #FFFFFF;
}

.last-week-coin-count {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #DEF7F8;
}

.Collected-coins-card .title,
.Earned-coins .title {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #7F87B3;
}

.Collected-coins-card {
    padding: 32px;
    border-right: 1px solid #F2F3F7;
}

.Earned-coins {
    border-right: 1px solid #F2F3F7;

}

.spent-coin {
    border-right: unset !important;
}

.Collected-coins-card .coin-count,
.Earned-coins .coin-count {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: #131520 !important;
}

.Collected-coins-card .last-week-coin-count {
    color: #208B8E;
}

.Earned-coins {
    padding: 32px;
}

.Earned-coins .last-week-coin-count {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #7F87B3;
}

.collect-cpa-coin-title,
.Earning-history-title {
    padding-top: 64px;
    padding-bottom: 32px;
}

.daily-coin-box {
    padding: 14.75px 4px;
    background-color: #F9F9FB;
    border-radius: 6px;
    max-height: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.daily-coin-box .day-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #7F87B3;
    padding-bottom: 8px;
}

.coin-qty {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #131520;
}

.collect-coin-btn {
    background-color: #0d393a !important;
    min-width: unset;
    padding: 8px 0;
    width: 100%;
    margin-top: 0px;
}

.collected-btn {
    padding: 8px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #208B8E;
}

.to-collected-btn {
    color: #208B8E;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    background-color: #F7FDFD;
    border: 1px solid #208B8E;
    border-radius: 6px;
    min-width: 100%;
    padding: 8px 0px;
}

.daily-coin-card {
    padding-right: 16px;
}

.daily-coin-card:last-child {
    padding-right: 0px;
}

/* tabbbb */
.earning-history-tab .nav-tabs {
    --bs-nav-tabs-border-color: unset;
    max-width: fit-content !important;
    ;
    width: 100%;
    background: #F9F9FB;
    padding: 5px;
    border-radius: 8px;
    justify-content: space-between;
}

.earning-ua-history-tab .nav-tabs {
    max-width: fit-content !important;
}

.earning-history-tab .nav-item {
    /* width: calc(33.33% - 4px); */
    width: max-content;
}

.wallet-bosy-background #uncontrolled-tab-example-tab-collected-coins:hover,
.wallet-bosy-background #uncontrolled-tab-example-tab-earned-coins:hover,
.wallet-bosy-background #uncontrolled-tab-example-tab-spent-coins:hover {
    background-color: #FFFFFF;
    border-radius: 6px;
    color: #131520 !important;
}

#uncontrolled-tab-example-tab-collected-coins,
#uncontrolled-tab-example-tab-earned-coins,
#uncontrolled-tab-example-tab-spent-coins {
    width: 100%;
    border: unset;
    padding: 8px 12px;
    color: #7F87B3;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

.earning-history-tab .nav-tabs .nav-link.active {
    --bs-nav-tabs-link-active-bg: #ffffff;
    border-color: #ffffff;
    border-radius: 6px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    color: #131520 !important;
    padding: 8px 12px;

}

.earning-history-tab .tab-content .tab-pane {
    max-width: 100%;
}

.collected-coins-table,
.earned-coins-table {
    padding: 32px 32px 8px 32px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}


/* -------collect items table---- */
.Activity-table-title,
.activity-data {
    max-width: 50%;
    width: 100%;
}

.activity-data,
.purchase-data,
.spent-coin-data,
.coin-amount-data,
.date-div,
.Staked-coins-data,
.Odd-table-data,
.Earned-coins-table-data {
    padding: 29px 0;
    color: #131520;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

.Coins-amount-Predictiontype,
.coin-amount-data {
    max-width: 35%;
    width: 100%;
    padding-left: 24px;
}

.Date-table-Odds,
.date-table {
    max-width: 15%;
    width: unset;
    padding: 0 16px;
}

.date-div {
    padding: 0 16px;
}

/* 
.date-div {
    max-width: 124px;
} */
.Purchase-table-title,
.purchase-data {
    max-width: 70%;
    width: 100%;
}

.purchase-data {
    padding: 34px 0px 34px 2px;
}

.SpentCoins-Predictiontype,
.spent-coin-data {
    max-width: 15%;
    width: 100%;
    padding-left: 24px;
}

.date-div-data {
    text-align: center;
    color: #4C5480;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    background-color: #F2F3F7;
    padding: 6px 10px;
    border-radius: 16px;
}

.collected-coins-table .Top-predictions-row {
    padding: 0px;
}

.day-sceduled {
    color: #7F87B3;
}

/* earn table data */
.Eventdata-table-title,
.Eventdata-table-data {
    max-width: 45%;
    width: 100%;
}

.Staked-coins-title,
.Odd-table,
.Earned-coins-table,
.Odd-table-data,
.Staked-coins-data,
.Earned-coins-table-data {
    max-width: 13%;
    width: 100%;
    padding-left: 24px;
}

.earned-coins-table .oc_teams_details {
    border-right: 0px;
    padding: 0px;
}

.Eventdata-table-data {
    padding: 18px 24px 18px 0;
}

.earned-coins-table .Top-predictions-row {
    padding: 0px;
}

.earned-coins-table .both-Team-logo {
    padding-right: 0px;
}

.earned-coins-table .team1-logo,
.earned-coins-table .team2-logo {
    width: 32px;
    height: 32px;
    background-color: #F2F3F7;
    border: 1px solid #ffffff;
}

.collect-coin-model-div .modal-content {
    padding: 16px 16px 32px 16px;
    max-width: 484px;
}

.collect-coin-model-div .modal-dialog {
    max-width: 484px;
    width: 100%;
}

.collect-coin-model-div .modal-header {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.coin-model-collected-message {
    color: #131520;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.coin-model-subtitle,
.table-empty-state .sub-title {
    color: #7F87B3;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}

.thankYou-btn {
    margin-top: 0px;
    background-color: #0d393a;
}

.table-empty-state .title {
    color: #131520;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.table-empty-state {
    padding-top: 56px;
    padding-bottom: 32px;
}

.table-empty-state .collect-coin-btn {
    width: unset;
    padding: 8px 12px;

}

.earning-history-tab .ShowMorePredictions-btn {
    min-width: 100px;
}



/* teblet mobile */
@media screen and (max-width: 1024px) {
    .balance-card {
        margin-left: 10px !important;
    }

    .date-div {
        width: unset;
    }

    .Eventdata-table-data .earning-coin-tournament_name {
        width: 85px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media screen and (min-width : 768px) and (max-width : 900px) {}

@media screen and (min-width: 1023px) and (max-width: 1095px) {

    .Coins-amount-Predictiontype,
    .coin-amount-data {
        max-width: 30%;
    }
}

@media screen and (min-width: 1024.99px) {
    .daily-coin-card {
        max-width: calc(12.5% - 16px);
        width: 100%;
        margin-right: 16px;
        padding: 0px;
    }
}

.large-screen-coin-btn {
    display: block;
}

/* teblet design */
@media screen and (min-width: 768.1px) and (max-width: 1024px) {
    .collect-coin-earn-coin-card {
        max-width: calc(100% - 353px);
    }

    .Staked-coins-title,
    .Odd-table,
    .Earned-coins-table,
    .Odd-table-data,
    .Staked-coins-data,
    .Earned-coins-table-data {
        max-width: 14%;
    }

    .setgradiant-background {
        max-width: 353px;
        width: 100%;
    }

    /* .Collected-coins-cards,
    .earn-coins-card {
        max-width: 28.05%;
        width: 100%;

    } */

    .balance-card {
        padding-left: 24px;
        margin-left: 10px !important;
    }


    .Eventdata-table-title,
    .Eventdata-table-data {
        max-width: 39%;
        width: 100%;
    }

    .Collected-coins-card,
    .Earned-coins {
        padding: 32px 16px;
    }

    .daily-coin-box {
        min-width: 12.5%;
        padding: 14.75px 4px !important;
    }

    .daily-coin-card {
        max-width: calc(12.5% - 8px);
        width: 100%;
        margin-right: 8px;
        padding: 0px;
    }

    .daily-coin-card:last-child {
        margin-right: 0;
    }

    .Activity-table-title,
    .activity-data {
        max-width: 40%;
    }

    /* .Coins-amount-Predictiontype,
    .coin-amount-data {
        max-width: 40%;
    } */

    .Date-table-Odds,
    .date-table {
        max-width: 19%;
    }

    .Staked-coins-title,
    .Odd-table,
    .Earned-coins-table,
    .Odd-table-data,
    .Staked-coins-data,
    .Earned-coins-table-data {
        padding-left: 12px;
    }

    .Eventdata-table-data {
        padding: 24px 12px 24px 0px;
    }
}

.largescreen-data {
    display: block;
}

.mobile-coin-odds-dateData,
.mobile-collect-coin-btn {
    display: none;
}

.wallet-tabs-section {
    display: flex;
    gap: 8px;
    border: 1px solid #F9F9FB;
    background-color: #F9F9FB;
    border-radius: 8px;
    padding: 4px;
    color: #344054;
    width: fit-content;
}

.wallet-tabs-item {
    padding: 12px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #344054;
    /* transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out; */
}

.wallet-tabs-item.active {
    background-color: #fff;
    border-radius: 6px;
    color: #131520;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.wallet-tabs-item:hover {
    background-color: #fff;
    border-radius: 6px;
    color: #131520;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

@media screen and (min-width: 768.1px) and (max-width:1023.99px) {

    .Purchase-table-title,
    .purchase-data {
        max-width: 60%;
        width: 100%;
    }
}

/* mobile */
@media screen and (max-width: 768.1px) {
    .mobile-coin-collected-btn-sign {
        position: absolute;
        right: 16px;
        top: 10px;
    }

    .mobile-collect-coin-earn-coin-data {
        max-width: calc(100% - 29%);
        /* flex-direction: row-reverse; */
        width: 100%;
    }

    .mobile-collect-coin-earn-coin-data .coin-amount-data,
    .mobile-collect-coin-earn-coin-data .Coins-amount-Predictiontype {
        max-width: 40% !important;
    }

    .mobile-collect-coin-earn-coin-data .Date-table-Odds,
    .mobile-collect-coin-earn-coin-data .date-div {
        max-width: 60%;
    }

    .mobile-collect-coin-earn-coin-data .Date-table-Odds,
    .mobile-collect-coin-earn-coin-data .date-div {
        /* max-width: 50%; */
        width: 100%;

    }

    .earning-history-tab .nav-item {
        width: calc(33.33% - 4px);
    }

    .earning-history-tab .nav-tabs {
        max-width: 100% !important;
    }

    .collect-coin-model-div .modal-dialog {
        max-width: unset;
        width: unset;
    }

    .mobile-collect-coin-btn .icon-button {
        width: 100%;
    }

    .mobile-collect-coin-btn {
        display: block;
    }

    .daily-coin-card:nth-child(1),
    .daily-coin-card:nth-child(4),
    .daily-coin-card:nth-child(7) {
        padding-left: 0px;
    }

    .daily-coin-card:nth-child(3),
    .daily-coin-card:nth-child(6),
    .daily-coin-card:nth-child(8) {
        padding-right: 0px;
    }

    .daily-coin-card:nth-child(7),
    .daily-coin-card:nth-child(8) {
        width: 50%;
    }

    .large-screen-coin-btn {
        display: none;
    }

    .daily-coin-card {
        padding: 0px 8px;
        padding-bottom: 12px;
        position: relative;
    }

    .daily-coin-box {
        padding-top: 16px;
    }

    .SpentCoins-Predictiontype,
    .spent-coin-data {
        max-width: 30%;
    }

    .Purchase-table-title,
    .purchase-data {
        max-width: 30%;
    }

    /* .earning-history-tab .nav-tabs {
        max-width: 100%;
    } */

    .earning-history-tab .ShowMorePredictions-button {
        padding: 24px 0px 0px 0px;
    }

    .balance-card {
        margin-right: 10px !important;
    }

    .collected-coins-table .Top-predictions-tabletitle {
        display: flex;
    }

    .collected-coins-table .Top-predictions-row {
        flex-direction: unset;
        align-items: unset;
    }

    .Activity-table-title,
    .activity-data,
    .Date-table-Odds,
    .date-div {
        max-width: 35%;
    }

    .Coins-amount-Predictiontype,
    .coin-amount-data {
        max-width: 30%;
    }

    .Activity-table-title,
    .activity-data {
        max-width: 29%;
    }

    .Date-table-Odds {
        padding: 0 10px;
    }

    .coin-amount-data {
        display: flex;
        align-items: center;
    }

    .date-div {
        display: flex;
        align-items: center;
        padding: 0 8px;
    }


    .collected-coins-table {
        padding: 16px 16px 8px 16px;
    }

    .earned-coins-table {
        padding: 16px;
    }

    .Coins-amount-Predictiontype,
    .coin-amount-data {
        padding-left: 8px;
    }

    .earning-history-tab .both-team-contains {
        display: block;
    }

    .earned-coins-table .oc_teams_details {
        border-bottom: unset;
        padding-top: 12px;
    }

    .Eventdata-table-data {
        padding: 24px 0px;
    }

    .Eventdata-table-title,
    .Eventdata-table-data {
        max-width: 100%;
    }

    .Eventdata-table-data .both-Team-logo {
        padding-bottom: 0px
    }

    .largescreen-data {
        display: none;
    }

    .mobile-coin-odds-dateData {
        display: block;
        padding: 16px 0px;
    }

    .Staked-coins-data {
        padding-top: 12px;
        padding-left: 0px;
        padding-bottom: 0px;
        max-width: 100%;
    }

    .date-div-data {
        margin-top: 12px;
    }

    .mobile-coin-odds-dateData .Top-predictions-tabletitle,
    .mobile-coin-odds-dateData .Staked-coins-data,
    .mobile-coin-odds-dateData .date-div {
        padding-left: 12px !important;

    }

    .Staked-coins-title {
        padding-left: 12px;
        max-width: 100%;
    }

    .date-div {
        max-width: unset;
    }

    .mobile-coin-odds-dateData .Top-predictions-tabletitle {
        display: block;
    }

    .spent-purchase-data,
    .spent-table-title {
        width: 50%;
    }

    .spent-coins-data,
    .spent-coins-title,
    .spent-date,
    .spent-date-div {
        width: 25% !important;
    }

    .date-div-data {
        width: unset !important;
    }
}

.wallet-model-container .modal-dialog,
.wallet-model-container .modal-content {
    max-width: 764px;
}

.wallet-model-container .modal-content {
    padding: 32px 3px 22px 0;
}

.wallet-model-container .modal-header {
    padding: 0 29px 0 32px;
    margin-bottom: 20px;
}

.wallet-model-container .model-prdiction-card {
    margin-left: 32px;
    margin-right: 27px;
    margin-bottom: 24px
}

.walllet-model-description .notification-ticket-part {
    margin-left: 32px;
    margin-right: 27px;

}

.wallet-model-container .both-team-league-time-sport {
    padding-top: 16px;
}

.wallet-model-container .modal-body {
    padding: 0;
}

.mobile-collect-coin-earn-coin-data {
    max-width: calc(100% - 29%);
    width: 100%;
}

.mobile-collect-coin-earn-coin-data .coin-amount-data,
.mobile-collect-coin-earn-coin-data .Coins-amount-Predictiontype,
.mobile-collect-coin-earn-coin-data .Date-table-Odds {
    max-width: 50%;
}

.reward-title,
.reward-title-data {
    width: 55%;
}

.reward-title-data {
    gap: 16px;
}

.reward-level,
.reward-level-data,
.reward-award,
.reward-award-data,
.reward-date-div {
    width: 15% !important;
}

.wallet-history-table-titles,
.collect-table-title-row,
.spent-table-title-row {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #4C5480;
    text-transform: capitalize;
}

.wallet-tabs-content {
    margin-top: 16px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    padding: 16px;
    padding-bottom: 8px;
}


.wallet-history-table-row,
.collect-table-data-row,
.spent-table-data-row {
    height: 85px;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid #F2F3F7;
}

.collect-table-title,
.collect-activity-data {
    width: 60%;
}

.spent-purchase-data,
.spent-table-title {
    width: 60%;
}

.coin-amount-data,
.Date-table-Odds,
.coins-amount-title {
    width: 20%;
}

.spent-coins-data,
.spent-coins-title,
.spent-date,
.spent-date-div {
    width: 20%;
}

.date-div-data {
    width: max-content;
}

.mobile-reward-history-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #F2F3F7;
    gap: 24px;
    padding: 24px 0;
}

.mobile-reward-history-title {
    display: flex;
    gap: 12px;
}

.mobile-reward-history-title img {
    width: 52px;
    height: 48px;
}

.mobile-reward-history-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mobile-reward-history-text-wrapper h1 {
    font-size: 14px;
    font-weight: 600;
}

.mobile-reward-history-value-title,
.mobile-reward-history-level {
    font-size: 14px;
    font-weight: 300;
    color: #4C5480;
}

.mobile-reward-history-values {
    display: flex;
}

.mobile-reward-history-value {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}

.date-value {
    border-left: 1px solid #F2F3F7;
    padding-left: 32px;
}

.mobile-reward-history-value-data {
    font-size: 14px;
    font-weight: 600;
}

.mobile-reward-history-value-data {
    display: flex;
    align-items: center;
    gap: 8px;
}

.walet-coins-value-img {
    width: 17px;
    height: 20px;
}

.reward-award-data {
    display: flex;
    gap: 8px;
}