.rewards-page {
    background-color: #fff;
}

.rewards__page-wrapper {
    display: flex;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    /* height: 100vh; */
    padding-top: 32px;
    gap: 64px;
}

.rewards__main-section {
    display: flex;
    flex-direction: column;
    max-width: 916px;
    gap: 64px;
}

.rewards__ad-section {
    width: 260px;
    /* border: 1px solid; */
}

.rewards__ad-section .ad-wrapper {
    margin-top: 0 !important;
}

.rewards__top-total-section {
    height: 314px;
    display: flex;
    gap: 16px;
}

.rewards__top-total-left {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 420px;
    padding: 32px 16px 16px 16px;
    background-color: #FCF7E4;
    border-radius: 6px;
    border: 1px solid #F8ECBF;
    justify-content: space-between;
}

.rewards__top-total-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
    height: 154px;
}

.rewards__top-total-title-text {
    height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rewards__top-total-title-text h1 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
}

.rewards__top-total-left-description {
    width: 200px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.rewards__top-total-count {
    font-size: 44px;
    line-height: 44px;
    font-weight: 800;
}

.rewards__top-total-title img {
    width: 171px;
    height: 180px;
    position: absolute;
    right: 0;
    top: 0;

}

.rewards__raiting-section {
    height: 64px;
    display: flex;
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    gap: 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;

}

.rewards__top-total-right {
    height: 100%;
    width: 480px;
    border-radius: 6px;
    border: 1px solid #F2F3F7;
}

.rewards__top-total-right-rewards-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    height: 104.67px;

}

.reward-analyst {
    border-bottom: 1px solid #F2F3F7;
    border-top: 1px solid #F2F3F7;
    border-right: 1px solid #F2F3F7;
}

.reward-collector {
    border-bottom: 1px solid #F2F3F7;
    border-top: 1px solid #F2F3F7;
}

.reward-influencer {
    border-right: 1px solid #F2F3F7;
}

.reward-exclusive {
    position: relative;
}

.medal_2 {
    position: absolute;
    top: -3px;
    right: 24px;
    width: 48px;
    height: 48px;
}

.rewards__top-total-right-rewards-item-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #7F87B3;
}

.rewards__top-total-right-rewards-item-total {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #131520;
}

.rewards__top-total-right-all-rewards_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.rewards__list-section {
    border-radius: 6px;
    border: 1px solid #F2F3F7;
    border-top: 0;
}

.rewards__list-item-category {
    height: auto;
    background-color: #FBFBFB;
    border-top: 1px solid #F2F3F7;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 4px;
}

.rewards__category-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.rewards__category-sub-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #4C5480;
}

.rewards__category-sub-item {
    height: auto;
    display: flex;
    padding: 32px 24px;
    gap: 32px;
    border-top: 1px solid #F2F3F7;
}

.rewards__category-sub-item_img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 124px;
    background-color: #FCF7E4;
    border-radius: 6px;
    border: 1px solid #F8ECBF;
    flex-shrink: 0;
}

.medal_1 {
    position: absolute;
    top: -3px;
    left: 4px;
    width: 24px;
    height: 24px;
}

.rewards__category-sub-item_title-wrapper {
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.rewards__category-sub-item_title-wrapper h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.rewards__category-sub-item_title-wrapper h4 {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #4C5480;
}

.rewards__category-sub-item_right-wrapper {
    display: flex;
    max-width: 720px;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
}

.rewards__category-sub-item_info-wrapper {
    display: flex;
    height: 52px;
    gap: 40px;
}

.rewards__category-sub-item_info-item {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #F2F3F7;
    padding: 0 40px 0 0;
    justify-content: space-between;
}

.rewards__category-sub-item_info-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #7F87B3;
    text-transform: uppercase;
}

.rewards__category-sub-item_info-result {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #131520;
    text-transform: uppercase;
}

.rewards_progress {
    width: 240px;
}

.rewards__coin-icon {
    width: 17.33px;
    height: 20px;
    margin-right: 8px;
}

.rewards_award {
    border: 0;
}

.claim-revards-btn {
    margin-top: 16px;
    width: 182px;
    /* height: 48px; */
    padding: 12px 16px;
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
    background-color: #0D393A;
    transition: all .3s ease-in-out;
}


.claim-revards-btn:hover {
    background-color: #09292A;
    transition: all .3s ease-in-out;
}

.reward-modal .modal-content {
    padding: 16px 32px 32px 32px;
}

.reward-modal .modal-header {
    padding: 0;
}

.rewards-modal-img {
    max-height: 200px;
    width: 210px;
}

.claim-btn {
    min-width: 100% !important;
    font-size: 16px;
    margin-top: 0;
    padding: 16px 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coint-award {
    font-size: 16px;
    font-weight: 700;
    color: #7F87B3;
}

.reward-wait,
.reward-completed {
    position: relative;
    background-color: #fff;
    /* width: 180px; */
    height: 40px;
    display: flex;
    gap: 4px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #DFE1EC;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s;
    /* line-height: 24px; */
}

.reward-wait:hover,
.reward-completed:hover{
    border: 1px solid #BFC3D9;
    transition: all .3s;
}

.sub-item_title-wrapper_with_timer .exclusive-reward__timer-wrapper {
    /* position: absolute; */
    right: 0;
}

.exclusive-reward__timer {
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;
    background-color: #FCF7E4;
    border: 1px solid #F8ECBF;
    border-radius: 6px;
    cursor: pointer;
}

.exclusive-reward__timer p {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
}

.highlighted {
    font-weight: 600;
    color: #208B8E;
}

.wallet-rewards-section {
    width: 100%;
}

.wallet-rewards-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 64px;
    padding-bottom: 32px;
}

.wallet-rewards-title h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding-bottom: 0.25rem;
}

.wallet-rewards-title h4 {
    color: #7F87B3;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
}

.wallet-rewards__show-all-btn {
    height: 48px;
    border-radius: 6px;
    border: 1px solid #DFE1EC;
    padding: 12px 16px;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #131520;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.wallet-rewards__show-all-btn:hover {
    color: #131520;
    border: 1px solid #BFC3D9;
    transition: all .3s ease-in-out;
}

.wallet-rewards-items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.wallet-rewards-item_wrapper {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #F2F3F7;
}

.wallet-rewards-item_wrapper .rewards__category-sub-item {
    max-width: 612px;
    border-top: none;
    gap: 24px
}

.wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper {
    max-width: 414px;
}

.wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper .rewards__category-sub-item_info-wrapper {
    gap: 24px;
    width: 100%;
}

.wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper .rewards__category-sub-item_info-wrapper .rewards_progress {
    width: 174px;
}

.user-rewards-section {
    /* width: 368px; */
    height: fit-content;
    border-radius: 6px;
    /* padding: 48px 24px; */
    border: 1px solid #F2F3F7;
}

.user-rewards-title {
    font-weight: 700;
    font-size: 18px;
}

.user-rewards-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.user-rewards-list-item {
    /* display: flex;
    flex-direction: column; */
    align-items: center;
}

.user-rewards-item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #F8ECBF;
    background-color: #FCF7E4;
}

.user-rewards-item-img img {
    height: 64px;
}

.user-rewards-item-img span {

    /* position: absolute; */
    /* top: -5px;
    right: -5px */
}

.user-reward-level {
    position: absolute;
    top: -7px;
    right: -7px;
}

.level-span {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #F8ECBF;
    background-color: #fff;
    color: #937911;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.user-reward-level .tooltip-wrapper .tooltip-text {
    width: 68px;
    height: 32px;
    padding: 0;
    text-transform: lowercase;
}

.user-rewards-item-name {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}

.user-rewards-section .wallet-rewards__show-all-wrapper {
    width: 100%;
}

.user-rewards-section .wallet-rewards__show-all-wrapper .wallet-rewards__show-all-btn {
    display: block;
    width: 100%;
}

.no-rewards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 48px 24px;
}

.user-rewards-wrapper{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
}

.no-rewards-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: #F9F9FB;
}

.no-rewards-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.no-rewards-title {
    font-size: 14px;
    font-weight: 600;
}

.no-rewards-sub-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #7F87B3;
    text-align: center;
}

.no-rewards-btn {
    width: 176px;
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #DFE1EC;
    color: #131520;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    transition: all .3s ease-in-out;
}

.no-rewards-btn:hover {
    text-decoration: none;
    color: #131520;
    border: 1px solid #BFC3D9;
    transition: all .3s ease-in-out;
}

.wallet-rewards-item-loader .rewards__category-sub-item {
    /* max-width: 100%; */
}

.wallet-rewards-info-item-progress {
    width: 140px !important;
}

.wallet-rewards-item-progress {
    width: fit-content;
}

.rewards-loader-subitem_info-wallet-section {
    gap: 16px;
}

.user-rewards-modal .modal-content {
    padding: 24px 32px;
    max-width: 1600px !important;
    max-height: 520px;
    height: 100%;
    /* overflow-y: scroll; */
    width: 100%;
}

.user-rewards{
    height: 424px;
    padding-top: 8px;
    overflow-y: scroll;
}

.user-rewards-modal .modal-header {
    margin-bottom: 12px;
}

.user-rewards::-webkit-scrollbar{
    display: none;
}

.user-rewards-modal .modal-dialog {
    max-width: 620px !important;
    width: 100%;
}

.user-rewards-modal .user-rewards-list {
    gap: 24px;
}

.user-rewards-modal .user-rewards-list-item {
    width: 160px;
    gap: 12px;
}

.user-rewards-modal .user-rewards-item-name{
    font-size: 14px;
    line-height: 24px;
}

.user-rewards-modal .user-rewards-item-img {
    width: 160px;
    height: 160px;
}

.user-rewards-modal .user-rewards-item-img img {
    height: 100px;
}

@media screen and (min-width: 1024.1px) and (max-width: 1280px) {
    .rewards__page-wrapper {
        max-width: 1020px;
    }

    .rewards__main-section {
        max-width: 748px;
    }

    .rewards__ad-section {
        width: 220px;
    }

    .wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper .rewards__category-sub-item_info-wrapper {
        gap: 16px;
    }

    .wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper .rewards__category-sub-item_info-wrapper .rewards_progress {
        width: 126px;
    }

    .wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper .rewards__category-sub-item_info-wrapper .rewards__category-sub-item_info-item {
        padding-right: 12px;
    }
}

/* @media screen and (max-width: 1280px) {
    .wallet-rewards-items {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
} */


@media screen and (max-width: 1024px) {
    .rewards__main-section {
        max-width: 100%;
        width: 100%;
    }

    .rewards__category-sub-item_right-wrapper {
        max-width: unset;
    }

    .rewards__page-wrapper {
        max-width: 100%;
        width: 100%;
        padding: 24px 16px 100px 16px;
        gap: unset;
    }

    .rewards__top-total-left,
    .rewards__top-total-right {
        width: 100%;
    }

    .rewards__main-section {
        width: 100%;
    }

    .rewards__ad-section {
        display: none;
    }

    .wallet-rewards-items {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .user-rewards-section {
        width: 100%;
    }

    .user-rewards-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        box-sizing: border-box;
        gap: 12px;
    }

    .wallet-rewards-item_wrapper .rewards__category-sub-item {
        max-width: 100%;
    }

    .user-rewards-list-item{
        width: unset;
        gap: 12px;
    }

    .user-rewards-item-img{
        width: 100%;
        height: 120px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .user-rewards-item-img img{
        height: unset;
    }

    .user-rewards-item-name{
        line-height: 16px;
    }

    .no-rewards-btn{
        font-size: 14px;
        line-height: 24px;
        width: auto;
    }
}

@media screen and (max-width: 768.1px) {
    .rewards__main-section {
        gap: 48px;
    }

    .rewards-modal-img{
        max-height: 160px;
    }

    .rewards__page-wrapper {
        gap: 48px;
    }

    .rewards__raiting-section {
        margin-top: 16px;
        line-height: unset;
        align-items: center;
    }

    .rewards__top-total-section {
        flex-direction: column;
        height: max-content;
    }

    .rewards__top-total-right-rewards-item {
        padding: 16px 14px;
    }

    .rewards__category-sub-item {
        flex-direction: column;
        height: max-content;
        padding: 14px;
        gap: 16px;
    }

    .rewards__category-sub-item_img-wrapper {
        width: 100%;
        height: 315px;
    }

    .reward-img {
        width: 164px;
        height: 200px;
    }

    .rewards__category-sub-item_right-wrapper {
        width: 100%;
        gap: 0;
    }

    .rewards__category-sub-item_info-wrapper {
        gap: 12px;
        height: max-content;
        margin-bottom: 12px;
    }

    .rewards__category-sub-item_info-item {
        background-color: #FBFBFB;
        border-radius: 4px;
        border: 1px solid #F2F3F7;
        width: 100%;
        height: 72px;
        padding: 12px;
    }

    .reward__status-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .exclusive-reward__timer {
        background-color: #FFF;
    }

    .claim-revards-btn {
        width: 100%;
    }

    .modal-dialog {
        width: 96%;
    }

    .rewards__list-item-category {
        padding: 14px;
        height: fit-content;
    }

    .rewards__category-sub-item_title-wrapper {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .wallet-rewards-section {
        height: fit-content;
    }

    .wallet-rewards__show-all-wrapper {
        height: fit-content;
        display: flex;
        align-items: center;
    }

    .wallet-rewards-item_wrapper .rewards__category-sub-item {
        max-width: 100%;
    }

    .wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper {
        max-width: 100%;
    }

    .wallet-rewards-item_wrapper .rewards__category-sub-item .rewards__category-sub-item_right-wrapper .rewards__category-sub-item_info-wrapper {
        gap: 12px;
    }

    .wallet-rewards__show-all-btn {
        margin-top: 16px;
        text-align: center;
        width: 100% !important;
    }

    .medal_1 {
        width: 48px;
        height: 48px;
        top: -6px;
    }

    .rewards__top-total-title img {
        height: 130px;
    }

    .reward-modal .modal-content {
        padding: 16px 24px 32px 24px;
    }

    .reward-modal .successfully-message {
        font-size: 16px;
        line-height: 24px;
    }

    .reward-modal .decription-message {
        font-size: 14px;
        line-height: 20px;
    }

    .user-rewards-wrapper{
        padding: 24px 16px;
    }

    .user-rewards-list{
        grid-template-columns: repeat(3, 1fr);
    }

    .reward__status-wrapper .tooltip-wrapper .tooltip-text{
        width: 150%
      }
}