.Marketplace-backgroung-color-section {
    /* background-color: #0D393A; */
    /* background-image: url("../../../public/Marketplace-img/backgroundImage.png"); */
    height: 445px;
    /* background-size: cover; */
    width: 100%;
    background: #0D393A;
    box-shadow: 0px 16px 32px 0px rgba(5, 6, 15, 0.05);
    position: relative;
}

.Marketplace-banner-image-gradiant {
    border-radius: 428px;
    background: #208B8E;
    filter: blur(100px);
    width: 428px;
    height: 428px;
    position: absolute;
    right: -25px;
    bottom: -15px;

}

.Marketplace-banner-image {
    z-index: 1;
}

.banner-image-data {
    max-width: 534.22px;
    width: 100%;
    max-height: 360px;
    height: 100%;
}

.product-card-section {
    position: relative;
    z-index: 1;
    background-color: #FFFFFF;
    padding-top: 32.5px;
}

.Marketplace-all-section {
    overflow-x: hidden;
}

.Marketplace-container,
.product-card-container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}

.Marketplace-top-banner {
    padding: 32px 0px;
}

.product-card-container {
    padding: 0 10px;
}

.marketplace-left-div .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #FFFFFF;

}

.marketplace-left-div {
    position: relative;
    z-index: 1;
}

.marketplace-left-div .sub-title {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #FFFFFF;

}



/* .product-card-container {
    margin-bottom: 100px;
} */

.marketplace-left-div {
    max-width: 555px;
    width: 100%;
}

.product-card-div {
    padding: 24px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

.product-name,
.successfully-message {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
}

.costTitle-name {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #7F87B3;
}

.product-cost-amount,
.main-imformation {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #131520;
}

.MarketplaceBtn {
    margin-top: 0px;
    background-color: #0D393A;
    min-width: 165.33px;
    cursor: pointer;
}

.sold-out-btn {
    background-color: #F9DADA;
    color: #D14343;
}

.sold-out-btn:hover {
    background-color: #F9DADA !important;
    color: #D14343;
}

.product-card-container .predition-edit-delete-user {
    position: absolute;
    padding: 8px 0px 0px 8px;
}

.product-card-container .delete-user-button-tooltip {
    top: -37px !important;
    left: 20px !important;
}

.product-card-container .edit-button-tooltip {
    min-width: max-content
}

.gift-image {
    padding: 118px 0px
}

.add-product-btn {
    width: 100%;
    margin-left: 0px;
    padding: 12px 0px;
}

/* model */
.edit-delete-buynow-product-prediction-model .modal-content {
    padding: 16px;
}

.decription-message {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #7F87B3;
}

.purchaseModek-btn {
    padding: 16px 24px;
}

.deleteProduct-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #131520;
}

.edit-add-product-model .modal-content,
.edit-add-product-model .modal-dialog {
    max-width: 464px;
    width: 100%;
}

.edit-add-product-model .modal-header {
    padding: 6px 0px 6px 0px;
}

.purchase-status-title,
.upload-image-button,
.imagepathName {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #131520;
}

.selected-fliter {
    transition: all 0.3s ease-in-out;
}

.selected-fliter:hover,
.upload-image-button:hover {
    border: 1px solid #BFC3D9;
}

.upload-image-button {
    padding: 6px 16px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    max-width: max-content;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.upload-image-input-feild {
    position: absolute;
    visibility: visible;
    opacity: 0;
    width: 122.5px;
    height: 38.48px;
    z-index: 2;
    top: 0;
    left: 0;
    cursor: pointer;
}

.product-div {
    max-width: 300px;
    width: 100%;
    min-height: 300px;
    height: 100%;
}

/* teblet media */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .Marketplace-container {
        max-width: calc(100% - 30px);
    }

    .gradiant-single-border {
        display: none !important;
    }

    .product-card-container {
        max-width: calc(100% - 10px);
        padding: 0 16px
    }

    .product-Data-card {
        width: 50%;
    }
}

/* mobile */
@media only screen and (max-width: 767.99px) {
    .Marketplace-banner-image-gradiant {
        width: 345px;
        height: 345px;
    }

    .Marketplace-backgroung-color-section {
        height: max-content;
    }

    .Marketplace-container {
        max-width: calc(100% - 30px);
    }

    .edit-add-product-model .modal-dialog {
        max-width: unset;
        width: 100%;
    }

    .product-card-container {
        max-width: calc(100% - 10px);
        padding: 0 16px
    }

    .Marketplace-top-banner {
        flex-direction: column-reverse;
    }

    .Marketplace-banner-image {
        display: flex;
        justify-content: center;
        padding-bottom: 32px;
    }

    .marketplace-left-div {
        max-width: unset;
    }

    .gradiant-single-border {
        display: none !important;
    }

}

.gradiant-single-border {
    display: block;
    position: absolute;
    top: -8rem;
    right: 0;
}

.logedInUser-navbar {
    z-index: 25;
}

/* .navbar-expand .navbar-collapse .Marketplace-banner-image {
    z-index: unset;
} */