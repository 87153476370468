.dropdown-input {
    min-width: 15em;
    position: relative;
    z-index: 20;
}

.select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dfe1ec;
    border-radius: 6px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    transition: all .3s ease-in-out;
}

.select.open{
    border: 1px solid #bfc3d9;
}

.select:hover {
    border: 1px solid #bfc3d9;
    transition: all .3s ease-in-out;
}

.caret-rotate {
    transform: rotate(180deg);
}

.list-items {
    position: absolute;
    width: 100%;
    list-style: none;
    border: 1px solid #dfe1ec;
    border-radius: 6px;
    background-color: #fff;
    padding: 8px;
    transition: 0.2s;
    display: none;
    box-sizing: border-box;
    box-shadow: 0 8px 8px rgba(50,50,71,.08), 0 8px 16px rgba(50,50,71,.06);
}

.list-items li {
    padding: 10px 8px;
    cursor: pointer;
}

.list-items li:hover {
    background-color: #f2f3f7;
    border-radius: 6px;
}

.active-item {
    font-weight: 600;
}

.list-items.open{
    display: block;
}