.propular-event {
    padding-top: 26px;
    padding-bottom: 18px;
    border-bottom: 1px solid #F2F3F7;
}

.propular-event-title,
.Popular-leagues-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    padding-bottom: 16px;
}

.selectSport-button-div {
    display: flex;
    width: 100%;
}

.feedsidebar-buttons {
    padding-bottom: 26px;
}

/* .Recomended-part-btn,
.following-part {
    padding-left: 0px;
} */

.team-2-event-logo {
    margin-top: -12px;
}

.team-1-event-name,
.team-2-event-name,
.Popular-leagues-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.teams-event-name {
    padding-left: 8px;
}

.propular-events-contains {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 8px;
}

.Popular-leagues {
    padding-top: 26px;
}

.leagues-logo {
    padding-right: 8px;
}

.leagues-section {
    padding: 12px 8px;
    cursor: pointer;
}

.leagues-section:hover {
    background-color: #F2F3F7;
    border-radius: 6px;
}

/* tooltip */
.tooltip-prediction-div {
    position: relative;
}

.Ticket-Star-icon {
    cursor: pointer;
}

.prediction-button-tooltip {
    width: max-content;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 90%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 32px;
    background: #131520;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #FFFFFF;
    padding: 8px 12px;
}

.prediction-button-tooltip:after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: #131520 transparent transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
}

.tooltip-prediction-div:hover .prediction-button-tooltip {
    opacity: 1;
    visibility: visible;
}


.icon-container img:nth-child(2) {
    display: none;
}

.icon-container:hover img:nth-child(1) {
    display: none;
}

.icon-container:hover img:nth-child(2) {
    width: 24px;
    height: 24px;
    display: block;
}


/* design model */

.userLoginModel .btn-containt {
    margin-top: 0px;
}

@media screen and (max-width: 768px) {
    .feedsidebar-buttons {
        border-bottom: 1px solid #F2F3F7;
    }
}