.home-page-gradient {
    background: radial-gradient(96.93% 448.75% at 50% 0%, #0B3132 0%, #061919 100%);
    min-height: 738px;
    height: 100%;
}

.home-containt {
    background-color: #FFFFFF !important;
}

.homepage-First-container {
    max-width: 1470px !important;
}

.home-page-container-1240,
.Client-First-gradient-container-1240 {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 4px;
    color: #ffffff;
}

.home-page-container-1240 {
    padding-left: 16px;
}

.home-page-left-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 738px;
    height: 100%;
}

.Handicappers-sports-fans {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
}

.Handicappers-sports-description {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.or-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.home-page-right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0px;
    padding-left: 0px;
}

.home-page-container-bottom-1240 {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
}

.resources-section {
    padding-top: 100px;
    padding-bottom: 36px;
}

.resources-title {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #131520;
    padding-bottom: 64px;

}

.different-sports-card,
.Large-community-card,
.Detailed-analytics-card,
.Realtimesportsdataodds-card,
.BiggestLineofevents-card {
    max-width: 448px;
    padding-bottom: 64px;
    /* width: 100%; */
}

.different-sports-img,
.Large-community-img,
.Detailed-analytics-img,
.Realtimesportsdataodds-img,
.BiggestLineofevents-img {
    margin-bottom: 24px;
}

.different-sports-title,
.Large-community-title,
.Detailed-analytics-title,
.Realtimesportsdataodds-title,
.BiggestLineofevents-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131520;
}

.different-sports-contains,
.Large-community-contains,
.Detailed-analytics-contains,
.Realtimesportsdataodds-contains,
.BiggestLineofevents-card-contains {
    padding-left: 53.70px;
    padding-right: 53.70px;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    margin-top: 8px;
    margin-bottom: 4px;
}

.Browse-Predictions-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 150px;
}

.Browse-Predictions-section,
.Analytics-section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.DetailedStatisticsfor-div {
    padding-top: 24px;
}

.Browse-Predictions-containts,
.Special-list-containts,
.Browse-Predictions-title {
    max-width: 495.31px;
    width: 100%;
    /* margin: 0 auto; */
}

.Browse-Predictions-title,
.Analytics-title,
.Special-list-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #081D34;
    margin-top: 53.5px;
    padding-bottom: 40px;
    display: flex;
}

.Browse-Predictions-decription,
.Special-list-decription {
    padding-bottom: 40px;
}

.Browse-Predictions-decription,
.message-lines-decription,
.Special-list-decription {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #081D34;

}

.tikmark-containt {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #081D34;

}

.tikmark-containt:last-child {
    margin-bottom: 0px;
}

.message-lines-quotes {
    margin-left: 47px
}

.tikmark-img {
    margin-right: 10px
}

.Browse-predictions-favorite-sport {
    padding-top: 60px;
    padding-bottom: 100px;
}

.favorite-sport-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #131520;
    text-align: center;
    padding-bottom: 32px;
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
}

.TrustpilotScoreLogo-subtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #7F87B3;

}

.TrustpilotScoreLogo-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #05060F;

}

.favorite-sport-btn-group {
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
}

.favorite-sport-btn span {
    padding-right: 16px;
}

.favorite-sport-btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Client-First-gradient {
    background: radial-gradient(96.93% 448.75% at 50% 0%, #0B3132 0%, #061919 100%);
    min-height: 398.98px;
    height: 100%;

}

.Client-First-image {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.community-review {
    padding-top: 100px;
}

.Client-First-section {
    max-width: 515.73px;
    width: 100%;
    padding-top: 75.49px;
}

.Client-First-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
}

.community-review-container {
    max-width: 1310px;
    width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    padding: 0px 15px;
}

.community-review-title,
.Accordion-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #0B0D13;
    text-align: center;
    padding-bottom: 32px;
    padding-top: 100px;
}

.community-review-gradiant {
    background-color: #F2F3F7;
}

.add-prediction-img {
    /* margin-bottom: -363px;
    width: 100%; */
    margin-bottom: -275px;
    width: 100%;
    margin-top: 60px;
}

.Accordion-container {
    /* padding-top: 100px; */
    /* padding-bottom: 100px !important; */
    max-width: 660px;
    width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
}

.mobile-home-page-image {
    display: none;
}

.home-page-image {
    display: block;
    width: 100%;
    padding-left: 50px;
}

.Analytics-img,
.Browse-Predictions-img,
.addPrediction-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Analytics-section-row .col {
    max-width: 50%;
    width: 100%;
    flex: auto;
}

.more-step-first-row .more-sport-data-col-8,
.more-step-first-row .more-sport-data-col-4 {
    min-height: 483px;
}

.mobile-Browse-Predictions-title,
.mobile-Analytics-title,
.mobile-Special-list-title,
.mobile-add-prediction-img {
    display: none;
}

.home-page-step2-tab #uncontrolled-tab-example-tab-earned-coins:hover,
.home-page-step2-tab #uncontrolled-tab-example-tab-collected-coins:hover {
    color: #131520;
}



/* teblet media */
@media only screen and (min-width: 1025px) and (max-width: 1497px) {
    .add-prediction-img {
        margin-bottom: -57px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    /* updated home page teblet design */
    .review-sectionTitle {
        text-align: center;
    }

    .home-page-main-top-section {
        padding: 64px 0px !important;
    }

    .silder-image-background {
        max-width: 100% !important;
    }

    /* .home-page-teligram-image {
        top: 90px !important;
    } */

    .more-step-second-row .more-sport-data-col-8,
    .more-step-second-row .more-sport-data-col-4 {
        min-height: unset;
    }

    .sportline-Predictions-section-row,
    .Get-bonus-section {
        flex-direction: column-reverse;
    }

    .Get-bonus-description-image {
        padding: 0px !important;
    }

    .Get-bonus-description-image {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .home-page-main-container {
        max-width: unset !important;
        width: 100% !important;
    }

    .Get-bonus-description-part {
        padding: 64px 73px 120px 73px !important;
        width: 100%;
    }

    .step-two-section .sportline-Predictions-section-row,
    .more-step-first-row,
    .more-step-second-row,
    .more-step-three-row {
        flex-direction: column;
    }

    .more-sport-data-col-8,
    .more-sport-data-col-4 {
        width: 100%;
        max-width: calc(100% - 32px) !important;
        min-height: max-content !important;
    }

    .home-page-main-decription-section {
        max-width: 100% !important;
    }


    .review-section-title {
        flex-direction: column;
        align-items: center;
    }

    .review-average {
        display: flex;
        justify-content: center;
    }

    .TrustpilotScoreLogo {
        border-left: unset !important;
        border-top: 1px solid rgba(5, 6, 15, 0.06);
        padding: 32px 0px 0 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        width: 100%;
    }

    .review-data-card-col {
        width: 50%;
    }

    .wallapaer-image {
        width: 100%;
        max-width: calc(100% - 16px) !important;
        padding-right: 0px !important;
        margin: 0 auto;
        padding-left: 16px;
    }

    .more-step-first-row .more-sport-data-col-8,
    .more-step-second-row .more-sport-data-col-4,
    .more-step-three-row .more-sport-data-col-8 {
        margin-bottom: 32px;
    }

    .more-step-three-row .prediction-image {
        width: 100%;
        max-height: 256px;
        height: 100%;
    }

    .more-step-first-row,
    .more-step-second-row {
        padding-bottom: 32px !important;
    }

    .step-two-section .step-decription-sub-title {
        padding-bottom: 40px;
    }

    .step-container-section {
        max-width: 100% !important;
    }

    .home-page-grid {
        width: 100%;
    }

    .step-container-section .Signup-to-continue {
        margin-bottom: 64px !important;
    }

    .main-section-primary-secondary-btn {
        padding-bottom: 96px;
    }

    .sportsKnowledgeIntroTitle-subtitle {
        padding: 64px 0px !important;
    }


    .counter-section-div {
        max-width: 15% !important;
        width: 100% !important;
    }

    .other-div-paddings,
    .sportline-Predictions-section {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .homepageHeader-topnav .test.navbar-toggler.collapsed {
        content: '';
        background-image: url('../../../../public/toggle-button.svg');
        width: 24px;
        height: 24px;
        display: inline-block;
        border: none;
    }

    .homePage-nav {
        flex-direction: column !important
    }

    .homepageHeader-topnav .test.navbar-toggler {
        background-image: url('../../../../public/toggle-close.svg');
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        border: none;
    }

    .toggle-button-responsive .navbar-toggler .navbar-toggler-icon {
        display: none;
    }

    .homepageHeader-topnav .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        position: absolute;
        align-items: center;
        top: 100%;
        left: 0px;
        width: 100%;
        z-index: 1025;
        background: #ffffff;
        height: 100vh;
        flex-direction: column;
        align-items: baseline;
        padding: 16px
    }



    .homepageHeader-topnav .navbar-expand .navbar-toggler {
        display: flex;
        border: none !important;
    }

    .homepageHeader-topnav .navbar-toggler-icon {
        display: block;
    }

    .homepageHeader-topnav .navbar-expand .navbar-toggler {
        display: flex;
        border: none !important;
    }

    .homepageHeader-topnav .collapse:not(.show) {
        display: none !important;
    }

    .home-page-flexes {
        flex-direction: column;
    }

    .mobile-add-prediction-img {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .silder-image-background {
        max-width: 100% !important;
    }

    .mobile-Client-First-image {
        padding-left: 16px;
        padding-right: 16px;
    }

    .home-page-left-section,
    .home-page-right-section {
        width: unset;
    }

    .Accordion-title,
    .Accordion-container {
        padding-top: 60px;
    }

    .Client-First-section {
        text-align: center;
    }

    .mobile-add-prediction-img {
        display: block;
    }

    .home-page-left-section {
        min-height: 0px;
        /* height: 0%; */
        max-width: 540px;
        width: 100%;
        margin: 0 auto;
        padding-top: 88px;
        padding-bottom: 40px;
    }

    .Analytics-section-row {
        flex-direction: column-reverse;
    }

    .button-group {
        justify-content: center;
    }

    .Handicappers-sports-fans {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }

    .home-page-image,
    .add-prediction-img {
        display: none;
    }

    .Browse-Predictions-title,
    .Analytics-title,
    .Special-list-title {
        display: none;
    }

    .Browse-Predictions-containts,
    .Special-list-containts {

        max-width: 100%;
        /* width: 100%; */
    }

    .mobile-Browse-Predictions-title,
    .mobile-Analytics-title,
    .mobile-Special-list-title {
        display: block;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #131520;
        text-align: center;
    }

    .favorite-sport-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #131520;
    }

    .favorite-sport-btn-group {
        padding: 0px 18px;
    }

    .Client-First-gradient {
        min-height: 850.88px;
        height: 100%;
    }

    .mobile-add-prediction-img {
        margin-top: 32px;
        margin-bottom: 32px;
    }


    .Client-First-gradient-container-1240 {
        flex-direction: column;
        max-width: 540px;
        width: 100%;
        margin: 0 auto;
        padding: 0px 15px;
    }

    .mobile-home-page-image {
        display: block;
        padding-bottom: 20px;
    }

    .Browse-Predictions-section,
    .Analytics-section {
        padding-top: 40px;
        padding-bottom: 120px;
    }

    .Browse-Predictions-img,
    .Analytics-img,
    .addPrediction-img {
        width: 100%;
        /* height: 736px; */
        padding-bottom: 40.50px;
    }

    .Browse-Predictions-section-row {
        flex-direction: column;
    }

    .add-prediction-img {
        width: 736px;
        height: 478.51px;
    }

    .Browse-predictions-favorite-sport {
        padding-top: 0px;
        padding-bottom: 60px;
    }

    .different-sports-card,
    .Large-community-card,
    .Detailed-analytics-card,
    .Realtimesportsdataodds-card,
    .BiggestLineofevents-card {
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 36px;
    }

    .different-sports-contains,
    .Large-community-contains,
    .Detailed-analytics-contains,
    .Realtimesportsdataodds-contains,
    .BiggestLineofevents-card-contains {
        padding-left: 0px;
        padding-right: 0px;
    }



}

.counter-section-div {
    max-width: unset;
    width: unset;
}

.navbar-toggler {
    color: #FFFFFF;
}

.deskTop-SportData {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.Mobile-SportData {
    display: none;
}

/* large scrren */
@media screen and (max-width: 1024px) {
    .resources-section {
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .resources-title {
        padding-bottom: 40px;
    }
}

.web-step-3-image {
    display: block;
}

.mobile-step-3-image {
    display: none;
}

.dotlottie-player-div {
    width: 710px !important;
}

.span-border-bottom {
    border-bottom: 6.91px solid;
}

@media only screen and (min-width: 1025px) and (max-width: 1124px) {
    .dotlottie-player-div {
        width: 510px !important;
    }
}

/* mobile media */
@media screen and (max-width: 767.99px) {

    .web-step-3-image {
        display: none !important;
    }

    .silder-image-background {
        max-height: 640px;
    }

    .silder-image-background img {
        top: 0px
    }

    .mobile-step-3-image {
        display: block !important;
        max-height: 640px;
        height: 100%;
    }

    /* mobile updated home design */
    .mobile-freeRegistrationEmail {
        width: 100%;
    }

    .review-sectionTitle,
    .TrustpilotScoreLogo-title,
    .TrustpilotScoreLogo-subtitle {
        text-align: center;
    }

    .silder-image-background,
    .more-sport-data-col-8,
    .more-sport-data-col-4 {
        max-width: 100% !important;
    }

    .more-step-three-row .more-sport-data-col-8,
    .more-step-second-row .more-sport-data-col-8,
    .more-step-first-row .more-sport-data-col-8 {
        min-width: unset !important;
    }

    .home-page-main-top-section {
        padding: 64px 0px !important;
    }

    .get-bonus-image {
        max-width: 280px;
        width: 100%;
    }

    .main-section-primary-secondary-btn {
        flex-direction: column;
    }

    .main-section-primary-secondary-btn .Signup-to-continue {
        width: 100%;
        margin-bottom: 16px;
    }

    .main-section-primary-secondary-btn .Sign-in-continue {
        width: 100%;
    }

    /* .home-page-teligram-image {
        top: 90px !important;
    } */

    .more-step-second-row .more-sport-data-col-8,
    .more-step-second-row .more-sport-data-col-4 {
        min-height: unset;
    }

    .more-step-three-row .more-sport-data-col-8,
    .more-step-second-row .more-sport-data-col-8,
    .more-step-first-row .more-sport-data-col-8,
    .more-step-first-row .more-sport-data-col-4,
    .more-step-second-row .more-sport-data-col-4,
    .more-step-three-row .more-sport-data-col-4 {
        padding: 32px !important;
    }

    .Get-bonus-description-section {
        max-width: 100%;
    }

    .intruction-forCoin-div {
        max-width: 100% !important;
    }

    .sportline-Predictions-section-row,
    .Get-bonus-section {
        flex-direction: column-reverse;
    }

    .Get-bonus-description-image {
        padding: 0px !important;
    }

    .Get-bonus-description-image {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .home-page-main-container {
        max-width: unset !important;
        /* width: calc(100% - 50px) !important; */
    }

    .Get-bonus-description-part {
        padding: 32px 32px 64px 32px !important;
        width: 100%;
    }

    .step-two-section .sportline-Predictions-section-row,
    .more-step-first-row,
    .more-step-second-row,
    .more-step-three-row {
        flex-direction: column;
    }

    .more-sport-data-col-8,
    .more-sport-data-col-4 {
        width: 100%;
        margin: 0px !important;
        min-height: max-content !important;
    }

    .home-page-main-decription-section {
        max-width: 100% !important;
    }


    .review-section-title {
        flex-direction: column;
        align-items: center;
    }

    .review-average {
        display: flex;
        justify-content: center;
    }

    .TrustpilotScoreLogo {
        border-left: unset !important;
        border-top: 1px solid rgba(5, 6, 15, 0.06);
        padding: 32px 0px 0 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        width: 100%;
    }

    .review-data-card-col {
        width: 100%;
    }

    .wallapaer-image {
        width: 100%;
        max-width: calc(100% - 16px) !important;
        padding-right: 0px !important;
        margin: 0 auto;
        padding-left: 16px;
    }

    .more-step-first-row .more-sport-data-col-8,
    .more-step-second-row .more-sport-data-col-4,
    .more-step-three-row .more-sport-data-col-8 {
        margin-bottom: 32px !important;
    }

    .more-step-three-row .more-sport-data-col-8 {
        max-height: max-content;
    }

    .more-step-three-row .prediction-image {
        width: 100%;
    }

    .more-step-first-row,
    .more-step-second-row {
        padding-bottom: 32px !important;
    }

    .step-two-section .step-decription-sub-title {
        padding-bottom: 40px;
    }

    .step-container-section {
        max-width: 100% !important;
    }

    .home-page-grid {
        width: 100%;
    }

    .step-container-section .Signup-to-continue {
        margin-bottom: 64px !important;
    }

    .main-section-primary-secondary-btn {
        padding-bottom: 48px;
    }

    .sportsKnowledgeIntroTitle-subtitle {
        padding: 64px 0px !important;
    }

    .silder-image-background {
        padding: 52px 19px !important;
    }

    .earning-history-tab .nav-item {
        width: 50% !important;
    }

    .counter-section-div {
        max-width: 50% !important;
        width: 100% !important;
        padding-bottom: 32px;
    }

    .step-container-section .Signup-to-continue,
    .get-Bonus-Btn {
        width: 100%;
    }

    .count-down-section {
        padding-top: 32px !important;
        padding-bottom: 64px !important;
    }

    .counter-section-div:last-child {
        max-width: 100% !important;
        width: 100%;
        padding-bottom: 0px;
    }

    .other-div-paddings,
    .sportline-Predictions-section {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .home-page-flexes {
        flex-direction: column-reverse;
    }

    .Analytics-section-row .col {
        max-width: 100%;
    }

    .homepageHeader-topnav .navbar-collapse {
        align-items: baseline;
    }

    .Analytics-img,
    .addPrediction-img,
    .Browse-Predictions-img {
        /* min-width: 346px; */
        width: 100%;
        min-height: 346px;
        height: 100%;
    }

    .Accordion-title,
    .community-review-title {
        padding-top: 60px;
    }

    .Accordion-container {
        padding: 0px 16px 60px 16px !important;
    }

    .community-review-container {
        padding: 0px 16px;
    }

    .community-group-contains {
        padding-bottom: 60px !important;
    }

    .Client-First-gradient-container-1240 {
        flex-direction: column;
    }

    .Client-First-gradient {
        min-height: 683.18px;
        height: 100%;
    }

    .add-prediction-img {
        margin-top: 32px;
        margin-bottom: -92px
    }

    .Client-First-section {
        padding-top: 60px;
    }

    .favorite-sport-btn-group {
        flex-direction: column;
    }

    .Client-First-section {
        max-width: 100%;
    }

    .Get-Started-for-FREE-btn {
        width: 100%;
    }

    .Browse-predictions-favorite-sport {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .favorite-sport-btn {
        width: 100%;
        justify-content: unset;
        margin-left: 0px;
        margin-right: 0px;
    }

    .Special-list-title {
        display: none;
    }

    .mobile-Browse-Predictions-title,
    .mobile-Analytics-title,
    .mobile-Special-list-title {
        display: block;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #131520;
        text-align: center;
    }

    .Browse-Predictions-title,
    .Analytics-title,
    .Special-list-title {
        display: none;
    }

    .home-page-container-bottom-1240 {
        padding: 0px 16px;
    }

    .Browse-Predictions-section {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    .Browse-predictions-main-class,
    .Browse-predictions {
        width: 100%;
    }

    .Analytics-img,
    .addPrediction-img,
    .Browse-Predictions-img {
        padding-bottom: 40.19px;
    }

    .Analytics-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .Analytics-section-row {
        flex-direction: column-reverse;
    }

    .Browse-Predictions-section-row {
        flex-direction: column;
    }

    .Browse-Predictions-containts,
    .Special-list-containts {
        max-width: 100%;

    }

    .different-sports-card,
    .Large-community-card,
    .Detailed-analytics-card,
    .Realtimesportsdataodds-card,
    .BiggestLineofevents-card {
        max-width: 100%;
    }

    .home-page-right-section,
    .home-page-left-section {
        width: 100%;
    }

    .home-page-image {
        padding-top: 84.73px;
        margin: 0px;

    }

    .home-page-left-section {
        min-height: unset;
    }

    .home-page-container-1240 {
        padding: 0 16px;
    }

    .button-group {
        flex-direction: column;
        padding-bottom: 80px;
    }

    .google-face-book-btn,
    .or-name {
        justify-content: center;
    }

    .or-name {
        padding-bottom: 16px;
    }

    .default-button {
        margin-bottom: 16px;
    }

    .default-button-main-class,
    .default-button,
    .Browse-all-sport-predictions {
        width: 100%;
    }

    .different-sports-contains,
    .Large-community-contains,
    .Detailed-analytics-contains,
    .Realtimesportsdataodds-contains,
    .BiggestLineofevents-card-contains {
        padding-left: 0px;
        padding-right: 0px;
    }

    .Show-more-sports-btn {
        display: block !important;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .deskTop-SportData {
        display: none;
    }

    .Mobile-SportData {
        display: block;
    }

}

.Show-more-sports-btn {
    display: none;
}

.tab-mob-image {
    display: none;
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .Browse-Predictions-col {
        padding-left: 15px !important;
    }

    .tab-mob-image {
        display: block;
        margin-left: 0;
        height: 100%;
        object-fit: contain;
    }

    .home-page-image {
        display: none;
    }

    .Analytics-section-row .col {
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 767.98px) {
    .Browse-Predictions-col {
        padding-left: 15px !important;
    }

    .tab-mob-image {
        display: block;
        margin-left: 0;
        height: 100%;
        object-fit: contain;
    }

    .home-page-image {
        display: none;
    }

    .Analytics-section-row .col {
        max-width: 100%;
        width: 100%;
    }
}

.start-button-free {
    margin-top: 25px !important;
}

/* updated page */
.home-page-main-top-section {
    padding-top: 100px;
    padding-bottom: 64px;
}

.wallapaer-image {
    max-width: 710px;
    width: 100%;
    padding-right: 64px;
}

.home-page-main-decription-section {
    max-width: 570px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.notLogedIn-userNavbar .container-1440 {
    max-width: 1470px
}

.home-page-main-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #131520;
}

.home-page-main-subtitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
}

.intruction-forCoin-div {
    background-color: rgba(32, 139, 142, 0.2);
    max-width: max-content;
    color: #208B8E;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 20px 16px 20px;
    border-radius: 64px;
}

.main-section-primary-secondary-btn {
    padding-top: 40px;
}

.main-section-primary-secondary-btn .Signup-to-continue {
    padding: 16px 24px 16px 24px;
    margin-right: 16px;
    font-size: 16px;
}

.main-section-primary-secondary-btn .Sign-in-continue {
    margin-left: 0px;
    padding: 16px 24px 16px 24px;
    color: #131520;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.main-section-primary-secondary-btn .Sign-in-continue:hover {
    border: 1px solid #bfc3d9;
}

.home-page-main-container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.get-Bonus-main-container {
    padding: 0 32px;
}

.count-down-section {
    padding-top: 32px;
    padding-bottom: 100px;
}

.counter-title {
    color: #7F87B3;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.counter-data,
.k-count,
.sportsKnowledgeIntroTitle-subtitle .title,
.more-sport-data-col-8 .title,
.more-sport-data-col-4 .title,
.step-decription-title,
.review-sectionTitle {
    color: #131520;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}

.more-sport-data-col-4 .title {
    padding-bottom: 29px;
}

.more-sport-data-col-8 .sub-title {
    color: #131520;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 29px;
}

.sportsKnowledgeIntroTitle-subtitle .sub-title {
    color: #131520;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.sportsKnowledgeIntroTitle-subtitle {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0 64px 0;
}

.step-btn {
    color: #208B8E;
    background-color: rgba(32, 139, 142, 0.2);
    font-weight: 700;
    max-width: max-content;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 31px 16px 31px;
    border-radius: 64px;
    transform: rotate(357deg);
}

.step-container-section .Signup-to-continue {
    padding: 16px 24px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
}

.step-container-section {
    max-width: 512px;
    width: 100%;
    margin: auto 0 auto auto;
}

.step-decription-sub-title {
    color: #7F87B3;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
}

.other-div-paddings {
    padding-top: 100px;
    padding-bottom: 100px;
}

.step-2-col .step-container-section {
    margin: 0;
}

.home-page-step2-tab .nav-tabs {
    max-width: max-content !important;
    background: unset !important;
}

.step-container-section #uncontrolled-tab-example-tab-collected-coins,
.step-container-section #uncontrolled-tab-example-tab-earned-coins {
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}


.step-container-section .earning-history-tab .nav-tabs .nav-link.active {
    background-color: #0D393A;
    color: #FFFFFF !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.more-step-title {
    color: #7F87B3 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 40px !important;
    padding-bottom: 32px;
}

.more-step-first-row,
.more-step-second-row {
    padding-bottom: 72px;
}

.more-step-three-row .more-sport-data-col-8,
.more-step-second-row .more-sport-data-col-8,
.more-step-first-row .more-sport-data-col-8 {
    min-width: 55%;
    padding: 64px 79px 64px 64px;
    min-height: 489.59px;
}

.more-step-three-row .more-sport-data-col-8,
.more-step-three-row .more-sport-data-col-4 {
    min-height: 449.59px;
    max-height: 449.59px;

}

.more-step-second-row .more-sport-data-col-8,
.more-step-second-row .more-sport-data-col-4 {
    min-height: 481.59px;
    max-height: 481.59px;
}

.more-sport-data-col-4 {
    max-width: 496px t;
    padding: 64px;
    min-height: 489.59px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.more-step-three-row .more-sport-data-col-4 {
    padding: 45px;
}

.prediction-image {
    position: relative;
}

/* .home-page-teligram-image {
    position: absolute;
    top: 140px;
    right: 20px;
} */

.home-page-teligram-image {
    position: absolute;
    top: -10px;
    right: -25px;
}

.home-page-twitter-image {
    position: absolute;
    bottom: -40px;
    left: 0px;
}

.home-page-facebook-image {
    position: absolute;
    bottom: -20px;
    right: 35px;
}

/* silder animation image */
.silder-image-background,
.more-sport-data-col-8,
.more-sport-data-col-4 {
    background-color: #F2F3F7;
    max-width: 605px;
    width: 100%;
    justify-content: space-around;
    min-height: 640px;
    height: 100%;
    border-radius: 24px;
    cursor: pointer;
    padding: 52px 68px 52px 64px;
    position: relative;
}



.step-1-section .sportline-Predictions-section {
    padding-top: 36px;
    padding-bottom: 100px;
}

.silder-image-background:hover .grid-card-arr {
    transform: scale(1.2);
}


.grid-card-arr {
    width: 36px;
    height: 36px;
    background-color: rgba(5, 6, 15, .08);
    background-image: url("/public/arrow-top-right.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 50%;
    transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 2;
}

.silder-image-background {
    display: flex;
    /* width: 300px;
    height: 350px; */
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.earning-history-tab-margin {
    margin-bottom: 42px;
}

.silder-image-background img {
    max-width: 473px;
    width: 100%;
    bottom: -285px;
    height: auto;
    position: absolute;
    display: flex;
    z-index: 0;
    margin: 0;
    padding: 0;
    -webkit-transition: top 5s;
    -moz-transition: top 5s;
    -ms-transition: top 5s;
    -o-transition: top 5s;
    transition: bottom 5s;
}

.silder-image-background .step-3-image {
    bottom: -190px;
}

.silder-image-background .slider-step-2 {
    bottom: -450px;
}

.silder-image-background .collected-coins-img {
    /* bottom: -481px; */
    /* right: 53px; */
}

.silder-image-background:hover .collected-coins-img {
    bottom: 40px;
}

/* .silder-image-background .step-3-image */
.silder-image-background:hover img {
    bottom: 40px;
    -webkit-transition: all 5s;
    -moz-transition: all 5s;
    -ms-transition: all 5s;
    -o-transition: all 5s;
    transition: all 5s;
}

/* horizontal */


/* --------------- */

.more-sport-data-col-8,
.more-sport-data-col-4 {
    display: block;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    margin: 0px 16px;
}

.more-sport-data-col-4 .Sports-line-data-image,
.more-sport-data-col-8 .Sports-line-data-image {
    animation: scrollImage 10s linear infinite;
    animation-play-state: paused;
    width: 100000px;
    transform: translateX(0px);
}

.more-sport-data-col-4 .large-community-2-image {
    animation: largeCommunity 10s linear infinite !important;
    animation-play-state: paused !important;
    /* transform: translateX(-100px); */
}

.popular-container-body .Sports-line-data-image {
    animation: scrollImage 30s linear infinite;
    animation-play-state: running;
    /* width: 100000px; */
    transform: translateX(0px);
    cursor: pointer;
}

.popular-container-body .large-community-2-image {
    animation: largeCommunity 30s linear infinite !important;
    /* width: 100000px; */
    animation-play-state: running !important;
    cursor: pointer;

}

.popular-container-body:hover .large-community-2-image,
.popular-container-body:hover .Sports-line-data-image {
    animation-play-state: paused !important;

}

.more-sport-data-col-4:hover .large-community-2-image {
    animation-play-state: running !important;
}

@keyframes scrollImage {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes largeCommunity {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.more-sport-data-col-8:hover .Sports-line-data-image,
.more-sport-data-col-4:hover .Sports-line-data-image {
    animation-play-state: running;
}

.more-step-second-row .more-sport-data-col-4 .Sports-line-data-image {
    position: relative;
    left: -115px;
    height: 90px;

}

.Get-bonus-section {
    background-color: #09292A;
    border-radius: 24px;
    /* padding: 80px 120px; */
}

.Get-bonus-description-part {
    padding: 120px 0px 120px 80px;
}

.Get-bonus-description-image {
    padding: 0px 120px 80px 0px;
}

.Get-bonus-description-section {
    max-width: 590px;
    width: 100%;
}

.Get-bonus-description-section .title {
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    line-height: 40px;
}

.Get-bonus-description-section .sub-title {
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    line-height: 24px;
}

.get-Bonus-Btn {
    margin-top: 32px;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
    background-color: #EF8352;
}

.get-Bonus-Btn:hover {
    background-color: #ED723B !important;
}

.Accordion-container {
    padding-bottom: 68px;
}

.home-page-Accordion {
    margin-bottom: 32px;
}

/* Youtube player */

.youtube-player .modal-dialog {
    max-width: 1280px;
    max-height: 725.33px
}

.youtube-player .modal-content {
    max-width: 1280px;
    max-height: 725.33px;
    padding: unset;
    background-color: transparent;
    border: none;
}

.youtube-player .model-extra-css {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-height: 725.33px;
    height: 725.33px;
}

.youtube-player iframe {
    width: 100%;
    height: 100%;
}

.youtube-player .modal-header {
    padding: 0px 0px 0px 25px;
}

.popular-container {
    background: var(--neutral-n-50, #F2F3F7);
    padding: 80px 0px 100px 0px;
    flex-direction: column;
    position: relative;
}

.prediction-btn-data {
    position: relative;
    top: -115px;
}

.popular-title {
    /* font-family: Lota Grotesque; */
    font-size: 32px;
    line-height: 40px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 16px;
    text-align: center;
}

.popular-subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px
}

.more-step-three-row .prediction-image {
    object-fit: contain;
}


.more-step-first-row .more-sport-data-col-8 .Sports-line-data-image {
    animation: scrollImage 20s linear infinite;
    animation-play-state: paused;
}

.more-step-first-row .more-sport-data-col-8:hover .Sports-line-data-image {
    animation-play-state: running;
}

/* .popular-container-body {
    margin: 0 auto;
    max-width: 1640px;
    width: 100%;
} */

.popular-container-header {
    padding-bottom: 64px;
}

.prediction-images {
    display: flex;
    padding: 0px 21px;
    align-items: center;
    /* gap: 24px; */
    overflow: hidden;
    margin-bottom: 32px;
}

.prediction-container-image {
    background-color: white;
    padding: 24px;
    border-radius: 16px;
    width: 402px;
}

.prediction-images:nth-child(2) {
    margin-left: -116px;
    margin-bottom: unset;
}

.prediction-images-overlay {
    position: relative;
    top: 75px;
}

.prediction-overlay-gradient {
    max-width: 100%;
    width: 100%;
    height: 175px;
    position: absolute;
    bottom: 57px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 0.01%, #F2F3F7 100%);
    filter: blur(10px);
}

.TrustpilotScoreLogo {
    padding-left: 72px;
    border-left: 1px solid rgba(5, 6, 15, 0.06);
}

.review-section-title {
    padding-bottom: 32px;
}

/* review */
.review-data-card {
    padding: 24px 32px;
    border: 1px solid #DFE1EC;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
}

.review-data-card .step-btn {
    padding: 14.24px 24.65px;
}

.title-section {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #333333;
    display: flex;
    align-items: center;
}

.review-description {
    padding-top: 16px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #0B0D13;
    /* padding-bottom: 134px; */
}

.title-section .title {
    padding-right: 12px;
}

.reviewer-name {
    font-weight: 700;
    line-height: 24px;
    font-size: 18px;
    color: #333333;
    display: flex;
}

.review-pagination {
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-page-left,
.review-page-right {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    border: 1px solid #DFE1EC;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: #ffffff;

}

.review-page-left:disabled,
.review-page-right:disabled {
    background: #F2F3F7;
    pointer-events: none;
}

.review-page-left:hover,
.review-page-right:hover {
    border: 1px solid #BFC3D9;
}

.review-page {
    font-weight: 700;
    line-height: 24px;
    font-size: 18px;
    color: #7F87B3;
    padding: 0px 30px;
}