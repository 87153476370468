@font-face {
  font-family: "SF UI Display Ultralight";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Ultralight"),
    url("/public/assets/sf-ui-display-ultralight-58646b19bf205.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Thin";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Thin"),
    url("/public/assets/sf-ui-display-thin-58646e9b26e8b.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Light";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Light"),
    url("/public/assets/sf-ui-display-light-58646b33e0551.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"),
    url("/public/assets/sf-ui-display-medium-58646be638f96.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Semibold"),
    url("/public/assets/sf-ui-display-semibold-58646eddcae92.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Bold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Bold"),
    url("/public/assets/sf-ui-display-bold-58646a511e3d9.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Heavy"),
    url("/public/assets/sf-ui-display-heavy-586470160b9e5.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Black";
  font-style: normal;
  font-weight: 900;
  src: local("SF UI Display Black"),
    url("/public/assets/sf-ui-display-black-58646a6b80d5a.woff") format("woff");
}

/* ----------- header logo ------------ */
@media screen and (min-width: 280px) and (max-width: 1024px) {
  .desktop-logo {
    text-align: center;
  }
}

/* ------ button icon -----  */

.btn-google-facebook {
  border: 1px solid #dfe1ec;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.btn-google-facebook:hover {
  border: 1px solid #dfe1ec;
}

/* 
@media only screen and (min-width: 1024px) {
  .btn-google {
    max-width: 193px !important;
    width: 100%;
  }
  .btn-facebook {
    max-width: 203px !important;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-google {
    max-width: 196px !important;
    width: 100%;
  }
  .btn-facebook {
    max-width: 207px !important;
    width: 100%;
  }
} */
.fa-google {
  background: conic-gradient(from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  margin-right: 14px;
  font-size: 13px;
  /* max-width: 191px;
    width: 100% */
}

.fab.fa-facebook-f {
  margin-right: 14px;
  font-size: 16px;
}

.btn-containt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
}

/* ---- eye icon ------- */
.toggle-icon {
  position: absolute;
  right: 16px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #bfc3d9;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.toggle-icon:hover {
  color: #9fa5c6 !important;
}

.toggle-icon svg {
  cursor: pointer;
  right: 0 !important;
  top: 0;
  margin: 0;
}

.toggle-icon svg path {
  fill: #bfc3d9;
  transition: all 0.2s ease-in-out;
}

.toggle-icon svg path:hover {
  fill: #9fa5c6 !important;
}

/* ---- text divider ----- */

.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
  margin: 24px 0;
  color: #7f87b3;
  font-weight: 300;
  font-size: 14px;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: #f2f3f7;
  flex-grow: 1;
  position: relative;
  top: 1px;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}

/* --------- form css ------ */
.form-input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
  color: #131520;
}

.form-input-field {
  padding: 15px 16px;
  line-height: 20px;
  border: 1px solid #dfe1ec;
}

.form-input-field-button[disabled] {
  background-color: #0d393a !important;
  opacity: 40% !important;
}

.form-input-field-button {
  background-color: #0d393a;
  color: #fff;
  font-weight: 600;
  outline: none;
  border: none !important;
  line-height: 24px;
  border-radius: 6px;
  font-size: 14px;
  padding: 14px 16px;
  max-height: 53px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.form-input-field-button:hover {
  background-color: #09292a;
}

.form-input-field-button:focus {
  background-color: #09292a;
  box-shadow: none;
  color: #fff;
}

.form-input-field-button:focus-visible {
  background-color: #09292a;
  box-shadow: none;
  color: #fff;
  outline: none;
}

.form-input-field {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.form-input-field::placeholder {
  color: #7f87b3 !important;
}

.form-input-field::placeholder {
  color: #7f87b3 !important;
}

.form-input-field:-moz-placeholder {
  color: #7f87b3 !important;
}

.form-input-field::-moz-placeholder {
  color: #7f87b3 !important;
}

.input-icons {
  position: relative;
}

/* media */
@media only screen and (max-width: 768px) {
  .btn-google-facebook {
    margin-top: 10px;
    padding: 14px 16px;
    max-width: 100% !important;
    width: 100% !important;
  }

  .btn-google {
    margin-left: 10px;
  }

  .btn-facebook {
    margin-right: 9px;
  }
}

@media screen and (min-width: 100px) and (max-width: 768px) {
  .eye-icon {
    right: 15px !important;
  }
}

/* Register CSS */
.setting-profile-part {
  display: none !important;
}

.card.card-register,
.card.resetnewpassword-card,
.card.returnlogin-card,
.card.card-settingaccount,
.card.checkmail-card,
.card.signinCapperauditor-card,
.card.resetpassword-card {
  padding: 64px 48px 64px 48px;
  max-width: 555px;
  height: 100%;
  width: 100%;
  border: 1px solid #dfe1ec;
}

.card-title p {
  color: #131520;
}

.welcome-carl {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.signincapperauditor-bottom a {
  text-decoration: none;
  font-weight: 700;
}

.signincapperauditor-bottom a:hover {
  text-decoration: underline;
}

.form-section {
  padding: 114px 15px;
}

header {
  padding: 21px 0;
  max-height: 66px;
}


.password-suggestion .list-unstyled {
  padding-left: 24px;
  padding-right: 24px;
}

.password-suggestion {
  min-height: 241px;
  height: 100%;
  position: absolute;
  right: -64%;
  bottom: -368%;
  background: #fff;
  max-width: 275px;
  width: 100%;
  padding: 24px;
  border: 1px solid #dfe1ec;
  border-radius: 6px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease-in-out;
}

.password-suggestion p {
  color: #7f87b3;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.password-suggestion li {
  color: #131520;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 24px;
}

.password-suggestion li:last-child {
  margin-bottom: 0;
}

.password-suggestion li i {
  color: #bfc3d9;
  margin-right: 8px;
}

.text-green {
  color: #208b8e !important;
}

/* .form-error label{
  color: #D14343;
} */
.form-error input {
  border: 1px solid #d14343;
  color: #d14343;
}

.form-error input::placeholder {
  color: #d14343 !important;
}

.form-error p {
  color: #d14343;
  margin-top: 4px;
}

.form-error input:hover {
  border-color: #d14343;
}

.form-error-line-show {
  display: block;
}

.form-error-line-hide {
  display: none;
}

.reset-subtitle {
  line-height: 24px;
  font-weight: 300;
}

.signinCapperauditor-card .btn-google-facebook {
  padding: 13px 16px;
}

.text-divider {
  line-height: 20px;
}

.button-responsive {
  margin-top: 20px;
}

@media screen and (max-width: 991.98px) {
  .password-suggestion .list-unstyled {
    padding: 0px;

  }

  .button-responsive {
    margin-top: 0px;
  }

  .password-suggestion {
    right: 0;
    position: relative;
    max-width: 100%;
    width: 100%;
    bottom: 0;
    box-shadow: none;
    border: navajowhite;
    padding: 16px 0;
    padding-bottom: 0px;
  }


  .card-settingaccount .input-icons {
    display: block;
  }

  .password-suggestion {
    min-height: 210px;
    height: 100%;
  }

  .card-settingaccount .setting-password-acc {
    right: 0;
    /* top: 41px; */
    position: relative;
    max-width: 100%;
    width: 100%;
    bottom: 0;
    box-shadow: none;
    border: navajowhite;
    padding: 16px 0;
    padding-bottom: 0px;
  }

  .password-suggestion ul {
    margin-bottom: 0;
  }

  .password-suggestion ul li {
    margin-bottom: 8px;
  }

  .password-suggestion ul li:last-child {
    margin-bottom: 0;
  }

  .signup-main-card .input-icons {
    margin-bottom: 0 !important;
    flex-wrap: wrap;
  }
}

/* .profile-part  */
@media screen and (max-width: 767.98px) {
  .form-section {
    padding: 24px 16px;
  }

  .navbar-profile-detail {
    padding-left: 0px !important;
  }

  .setting-profile-part {
    display: block !important;
  }

  .right-side {
    padding: 0px !important;
  }

  .card.card-register,
  .card.resetnewpassword-card,
  .card.returnlogin-card,
  .card.card-settingaccount,
  .card.signinCapperauditor-card,
  .card.resetpassword-card,
  .card.checkmail-card {
    padding: 32px 24px !important;
  }

  .btn.btn-google-facebook,
  .btn.btn-google {
    max-width: 100%;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .btn.btn-google-facebook:first-child {
    margin-top: 0 !important;
  }

  .card.signup-main-card,
  .card.login-card {
    padding: 32px 24px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .form-section {
    padding: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-google-facebook {
    padding: 13px !important;
  }

  .card.card-register,
  .card.resetnewpassword-card,
  .card.returnlogin-card,
  .card.card-settingaccount,
  .card.signinCapperauditor-card,
  .card.resetpassword-card,
  .card.checkmail-card {
    padding: 50px 35px !important;
  }

  .card.signup-main-card,
  .card.login-card {
    padding: 50px 35px !important;
  }
}

.btn.btn-google {
  max-width: calc(48.2% - 8px);
  width: 100%;
}

.btn.btn-facebook {
  max-width: calc(51.8% - 8px);
  width: 100%;
}

.container-511 {
  max-width: 555px;
  width: 100%;
  margin: 0 auto;
}

.rounded-circle p {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: SF UI Display Heavy;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #0d393a;
}

.country-symbol {
  margin-right: 8px;
}

/* Setting up account css*/
.setting-acc-form {
  position: relative;
}

.setting-password-acc {
  bottom: 0;
}

.setting-up-page {
  bottom: -368% !important;
}

/* ------- navbar css ----- */

.navbar-section {
  padding: 14px 0px;
  background-color: #ffffff;
  border-bottom: 1px solid #f2f3f7;
  z-index: 11;
  height: 71px;
}

/* .navbar-logo {
  margin-right: 64px;
} */

.navbar-logo img {
  height: 32px;
  max-width: 202.07px;
  width: 100%;
}

.navbar-avtar {
  height: 44px;
  width: 44px;
  border: 2px solid transparent;
  background-color: #f2f3f7;
  position: static;
}

/* .navbar-letter {
  margin-top: 11px;
} */
.navbar-user-name {
  margin-left: 16px;
}

.right-side {
  display: flex;
  margin: 0;
}

.navlink {
  margin-right: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #131520;
  text-decoration: none;
}

.navlink:hover {
  color: #131520;
}

.container-1240 {
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.topnav-container {
  max-width: unset !important;
}

.right-side {
  max-width: 292px;
  width: 100%;
  justify-content: space-evenly;
  /* margin: 0 auto; */
}

.navigation-avatar {
  /* max-width: 76px;
    width: 100%; */
  justify-content: end;

}

.coin-side-border {
  display: block !important;
  border-left: 1px solid #F2F3F7;

  padding-left: 10px;
}

.country-language {
  display: flex;
  max-width: 125px;
  width: 100%;
  padding-right: 8px;
  /* border-left: 1px solid #F2F3F7; */
}

.country-name,
.language-name {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #7f87b3;
  margin-top: 2px;
  font-family: 'SF UI Display';
}

.country-part {
  max-width: 58px;
  width: 100%;
  margin: 0px auto;
  padding-left: 8px;
  padding-right: 8px;
  justify-content: space-between;
}

.language-part {
  max-width: 69px;
  width: 100%;
  margin: 0px auto;
  padding-left: 8px;
  padding-right: 8px;
  justify-content: space-between;
}

.country-part:hover {
  background-color: #f2f3f7;
  border-radius: 6px;
  cursor: pointer;
}

.language-part:hover {
  background-color: #f2f3f7;
  border-radius: 6px;
  cursor: pointer;
}

.navbar-suggestion {
  max-width: 240px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  z-index: 15;
  margin-top: 5px;
  right: 15px;
  top: 100%;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 1px solid #f2f3f7;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1024px) {
  .navbar-suggestion {
    right: 3%;
  }
}

.coin-side-border {
  display: none;
}

/* .nav-avt:hover .navbar-suggestion {
  opacity: 1;
  visibility: visible;
} */

.navbar-avatar-top {
  padding-left: 16px;
  padding-right: 16px;
}

.profile-part {
  padding: 12px;
  text-decoration: none;
}

.setting-profile-part {
  padding: 12px 0;
  text-decoration: none;
}

.profile-part:hover {
  background-color: #f2f3f7;
  border-radius: 6px;
}

.logout-part {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 7px;
}

.navbar-profile-detail {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding-left: 13px;
}

hr {
  margin: 0;
  margin-top: 9px;
  border: 1px solid #f2f3f7;
  flex-grow: 0;
  z-index: 1;

}

.navbar-suggestion hr {
  opacity: 1;
}

.logout-full-part {
  padding: 12px;
}

.logout-full-part:hover {
  background-color: #f2f3f7;
  border-radius: 6px;
}

/* navbar currency */

.currency-suggestion {
  max-width: 201px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  right: 16%;
  top: 89%;
  width: 100%;
  padding: 16px;
  border: 1px solid #f2f3f7;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.currency-part {
  padding: 12px 8px 12px 8px;
  align-items: center;
}

.currency-part:hover {
  background-color: #f2f3f7;
  border-radius: 6px;
  cursor: pointer;
}

.currency-part-show {
  display: block;
}

.currency-part-hide {
  display: none;
}

.currency-part img,
.all-languages img {
  width: 16px;
  height: 16px;
}

.navbar-currency-name {
  margin-left: 8px;
}

.navbar-currency-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

/* navbar language  */
.all-languages {
  padding: 12px 8px 12px 8px;
  text-decoration: none;
  color:#000;
}

.all-languages-hide {
  display: none;
}

.all-languages-show {
  display: block;
}

.country-language {
  position: relative;
}

.language-suggestion {
  min-width: 201px;
  background-color: #ffffff;
  position: absolute;
  right: 42%;
  top: 100%;
  z-index: 99999;
  min-width: 201px;
  padding: 16px;
  border: 1px solid #f2f3f7;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.navbar-language-name {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  line-height: 24px;
}

.all-languages:hover {
  background-color: #f2f3f7;
  border-radius: 6px;
  cursor: pointer;
  color:#000
}


/* loader css */
.Polaris-Frame {
  min-height: 0vh !important;
}

/* custome loader */
/* .customeLoader-Border {
  padding: 32px;
  border: 1px solid #f2f3f7;
  border-radius: 6px;
} */

.league-model-loader {
  padding-top: 10px;
  padding-left: 32px;
  padding-right: 32px;
}


/* notofication */
.notification-img {
  display: flex;
  cursor: pointer;
}

.notification-button__badge {
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
  background-color: #D14343;
  border-radius: 50%;
  color: #ffffff;
  padding: 3.5px 9px;
  font-size: 10px;
  position: absolute;
  left: 7px;
  bottom: 11px;
  display: flex;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFFFFF;
}

.notificationIcon-icon,
.navbar-notification-part {
  position: relative;
}

.notification-suggestion {
  z-index: 99999;
  min-width: 450px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  opacity: 0;
  z-index: 15;
  visibility: hidden;
  /* display: none; */
  right: 12%;
  top: 2rem;
  border: 1px solid #f2f3f7;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}


.navbar-notification-part:hover .notification-suggestion {
  opacity: 1;
  visibility: visible;
  /* display: block !important; */
}

.navbar-notification-part {
  display: flex;
  align-self: center;
  margin: 0px 16px 0px 16px;
}

.notificationIcon-icon:hover .notificationIcon-icon-path {
  fill: #0D393A
}

.viewAll-div {
  cursor: pointer;
}

.new-reward-modal .modal-header {
  display: none;
}

.new-reward-modal .modal-dialog {
  max-width: 544px !important;
  width: 100%;
}

.new-reward-modal .modal-content {
  padding: 24px 24px 32px 24px;
}

.new-reward-modal-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.new-reward-modal-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FCF7E4;
  height: 300px;
  /* padding: 24px 24px 32px 24px; */
}

.new-rewards-modal-img{
  max-height: 240px;
  height: 100%;
}

.new-reward-modal-text h1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.new-reward-modal-text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: #7F87B3
}

.new-reward-modal-text-control {
  display: flex;
  gap: 16px;
}

.new-reward-modal-text-control button {
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  font: 14px;
  font-weight: 600;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
}

.new-reward-modal-close-btn {
  background-color: transparent;
  border: 1px solid #DFE1EC;
  color: #000;
}

.new-reward-modal-close-btn:hover {
  border: 1px solid #BFC3D9;
  transition: all 0.3s ease-in-out;
}

.new-reward-modal-open-btn {
  background-color: #0D393A;
  color: #FFF;
  border: none;
}

.new-reward-modal-open-btn:hover {
  background-color: #09292A;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 768.1px) {
  .new-reward-modal .modal-dialog {
    max-width: 96% !important;
  }

  .new-reward-modal-text-control {
    flex-direction: column;
    gap: 8px;
  }

  .new-reward-modal .modal-content {
    padding: 16px 16px 24px 16px;
  }

  .new-reward-modal-img-wrapper{
    height: 260px;
  }

  .new-rewards-modal-img{
    height: 210px;
  }

  .new-reward-modal-open-btn{
    order: -1;
  }
  
}