.chart-tooltip-wrapper {
    position: relative;
    /* width: fit-content; */
    width: 50%;
    cursor: pointer;
  }
  
  .chart-tooltip-wrapper .chart-tooltip-text {
    visibility: hidden;
    width: fit-content;
    background-color: #131520;
    color: white;
    text-align: center;
    border-radius: 8px;
    padding: 8px 12px;
    position: absolute;
    z-index: 10;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* transition: all .3s ease-in-out; */
  }

  .chart-tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .chart-tooltip-wrapper:hover .chart-tooltip-text {
    visibility: visible;
    pointer-events: none;
    /* transition: all .3s ease-in-out; */
  }

  .chart-tooltip__value-wrapper{
    display: flex;
    align-items: flex-end;
    gap: 4px;
  }

  .chart-tooltip__main-value{
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .chart-tooltip__second-value{
    line-height: 24px;
  }
  
