.results-page_main-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
}

/* .sports-tab-wrapper{
    height: 64px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #F2F3F7;
} */

.results_leagues-section,
.results_games-section,
.results_ad-section{
    border: 1px solid;
}

.results_leagues-section{
    max-width: 18%;
    width: 100%;
}

.results_games-section{
    max-width: 62%;
    width: 100%;
}

.results_ad-section{
    max-width: 20%;
    width: 100%;
}