.error-image-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 140px;
}

.error-image {
    max-width: 512px;
    width: 100%;
}

.error-page-container {
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.error-content {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #131520;
    margin-top: 84px;
}

.error-desc {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #131520;
    margin-top: 16px;
}

.take-me-home-btn-desc {
    padding: 16px 24px;
    background: #0D393A;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.take-me-home-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}