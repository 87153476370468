#myChart {
    /* width: 723.31px !important;
    height: 243.12px !important; */
    width: 840px !important;
    height: 424px !important;
    /* padding: 32px; */
    padding-top: 11px;
    position: relative;
}

#myBarChart {
    padding: 0px 32px 0px 21px
}

.line-chart-part {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    margin-bottom: 40px;
    /* max-width: 840px;
    width: 100%; */
    margin: 0px 16px;
}

.pai-chart-graph,
.Average-Odds-div {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    padding: 32px;
}

.shape-data-title-icon-div {
    padding: 32px 32px 0px 32px;
}

.balance-data-div {
    padding: 32px;
}

.wallet-balance-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #0D393A;
}

.chart-summary-collectCoin .title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.chart-summary-collectCoin .sub-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0D393A;
}

.Shape-data-div,
.balance-data-div {
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

.Average-Odds-div,
.Shape-data-div {
    margin-bottom: 24px;
}

.pai-chart-part {
    min-width: 376px;
    width: 100%;
}

/* #myPaiChart {
    padding: 32px;
    padding-right: 40px;
} */
.profit-border-panel-list {
    padding-left: 40px;
}

.chart-summary-sub-div {
    max-width: 70%;
    width: 100%;
}

.pai-chart-main {
    max-width: 30%;
    width: 100%;
}

.Return-border-div,
.Lost-border-div,
.won-border-div,
.Pending-border-div {
    display: flex;
}

.won-title-main-div,
.Lost-title-main-div,
.Return-title-main-div,
.Pending-title-main-div {
    padding-left: 15px;
}

.without-padding-paichart {
    width: 176px !important;
    height: 176px !important;
}

.padding-pai-chart {
    width: 170px !important;
    height: 170px !important;
}

.map-part {
    flex-wrap: wrap;
    padding-top: 32px;
    padding-bottom: 11px;
    padding-left: 32px;
    padding-right: 32px;
}

.pai-chart-score {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #131520;
}

.Latest-5-predictions {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    padding: 0px 32px 32px 32px;
}

.pai-chart-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3
}

.myChart-title-part,
.Top-predictionstitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131520;
}

.myChart-title-part {
    min-width: max-content;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 20px;
}

.Total-predictions-title,
.Average-Odds-title,
.Shape-data-title {
    padding-bottom: 22px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131520;
    padding-right: 10px;
}

.Average-Odds-title {
    padding-bottom: 24px;

}

.Shape-data-title {
    padding-bottom: 0px;

}

.Average-Odds-count {
    font-weight: 700;
    font-size: 24px;
    color: #0D393A;
    line-height: 32px;
    display: flex;
}

.chart-timer-duration {
    padding: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    cursor: pointer;
}

.chart-prediction-status {
    max-width: 370px;
    width: 100%;
    justify-content: space-between;
}

.won-border {
    width: 30px;
    height: 0px;
    border-radius: 100px;
    background-color: #0D393A;
    border: 3px solid #0D393A;
    transform: rotate(180deg);
    margin-bottom: 13px;
}

.Lost-border {
    width: 30px;
    height: 0px;
    border-radius: 40px;
    background-color: #D14343;
    border: 3px solid #D14343;
    transform: rotate(180deg);
    margin-bottom: 13px;
}

.pai-chart-border {
    margin-top: 8px;
}

.paiChart-side-border-list {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    min-width: calc(312px - 32px) !important;
    width: 100%;
}

.Return-border {
    width: 30px;
    height: 0px;
    border-radius: 40px;
    background-color: #EBCC4F;
    border: 3px solid #EBCC4F;
    transform: rotate(180deg);
    margin-bottom: 13px;
}

.Pending-border {
    width: 30px;
    height: 0px;
    border-radius: 40px;
    background-color: #F2F3F7;
    border: 3px solid #F2F3F7;
    transform: rotate(180deg);
    margin-bottom: 13px;
}

.duration-part {
    margin-top: 4px;
    margin-bottom: 4px;
    align-items: center;
}

.won-title,
.Lost-title,
.Return-title,
.Pending-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    /* text-align: center; */
}

.paichart-won-title {
    margin-bottom: 4px;
}

.chart-summary-div {
    /* min-width: 1240px; */
    width: 100%;
}

.paichart-score {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}


/* Not-enough-data-design */
.Not-enough-data-div {
    height: 384px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 47%;
    flex-wrap: wrap;
    text-align: center;
}

.Notenoughdata-msg {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 8px;
    text-align: center;

}

.tryAgainLine-msg {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    text-align: center;
}

.pai-chart-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.padding-pai-chart-div {
    position: relative;
}

.pending-paichart-center-div {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.padding-pai-chart-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyData-graph {
    padding: 60px 32px 92px 32px;
}

.custom-tooltip {
    z-index: 1;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    /* width: 151px; */
    padding-left: 11.76px !important;
    padding-top: 18px !important;
    padding-right: 15.24px !important;
    padding-bottom: 16px !important;
    cursor: pointer;
    border-radius: 20px;
    opacity: 0;
}

.custom-tooltip:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    border-top: solid 10px #FFFFFF;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.alert-circle-image {
    cursor: pointer;
}

.alert-circle-image:hover .alert-circle-image-path {
    stroke: #0D393A;
}

.alert-circle-image:hover .alert-circle-image-circle {
    fill: #0D393A;
}

/* tooltip design */
.alert-circle-tooltip-div {
    position: relative;
}

.alert-circle-image-tooltip {
    min-width: 314px;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 23px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    background: #131520;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 8px 12px;
}

.alert-circle-image-tooltip::after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: #131520 transparent transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
}

.alert-circle-tooltip-div:hover .alert-circle-image-tooltip {
    opacity: 1;
    visibility: visible;
}

.windata-count,
.lostdata-count,
.returndata-count {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131520;

}

.line-chart-left-panel {
    /* flex-wrap: wrap; */
}

.windata-title,
.lostdata-title,
.returndata-title,
.tooltip-title-date {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #7F87B3;
}

@media screen and (max-width: 1023.99px) {
    .chart-summary-div {
        flex-direction: column-reverse;
        min-width: 100%;
        max-width: 100%;
    }
}

/* .profile-bottom-section {
    overflow-x: hidden;
} */

.pai-chart-main {
    /* padding: 0px 16px; */
    /* overflow-x: hidden; */
}

.pai-chart-part {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    /* padding: 24px 16px 24px 16px; */
    margin-bottom: 16px;
}

#myChart {
    width: 100% !important;
    height: 425px !important;
}

.line-chart-part {
    /* min-width: 840px;
    width: 100%; */
    margin: 0px;
}


.pai-chart-menu {
    /* padding-right: 49.67px; */
}

.chart-summary-sub-div {
    padding: 0px 16px 0px 0px;
}

/* 
.chart-timer-duration {
    margin-right: 17px;
} */

.Not-enough-data-div {
    z-index: 1;
}

.Shape-data-div .Notenoughdata-msg {
    color: #131520;
}

.Shape-data-div .emptyData-graph {
    padding: 42px 32px 74px 32px;
}

@media screen and (max-width: 400px) {
    .alert-circle-image-tooltip {
        min-width: 188px;
    }
}

@media screen and (min-width: 401px) and (max-width:1024px) {
    .alert-circle-image-tooltip {
        min-width: 250px;
    }
}

@media screen and (max-width: 767.99px) {
    .paiChart-side-border-list {
        justify-content: space-around;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .paiChart-side-border-list {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: center;
        max-width: 346px;
        width: 100%;
        margin: 0 auto;
        padding-top: 22px;
    }



    .pai-chart-1div {
        display: flex;
        justify-content: space-between;
    }

    .pai-chart-2div {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1023.99px) {
    .line-chart-part {
        position: relative;
    }

    .chart-prediction-status {
        justify-content: space-between;
    }

    .chart-summary-sub-div {
        max-width: 100%;
        width: 100%;
    }

    .pai-chart-main {
        max-width: 100%;
        width: 100%;
    }

    .line-chart-part,
    .chart-summary-sub-div .topPredictionList-div {
        min-width: unset !important;
        max-width: unset !important;
        width: 100%;
        margin: 0px;
    }


    .chart-summary-sub-div {
        padding: 0px;
    }

    .duration-part {
        justify-content: space-between;

        max-width: 311px;
        width: 100%;
    }

    .chart-timer-duration:last-child {
        margin-right: 0px;
    }

    .line-chart-left-panel {
        flex-direction: column;
    }

    .map-part {
        flex-direction: column;
    }

    .map-part {
        padding: 24px 16px 11px 16px;
    }

    .chart-prediction-status {
        max-width: 383px;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .pai-chart-menu:last-child,
    .pai-chart-menu {
        padding-right: 16px !important;
    }

    .chart-timer-duration {
        margin-right: 0px !important
    }

    .Not-enough-data-div {
        height: auto;
        left: 57%;
        font-size: 6px;
        text-align: center;
        top: 60%;
        transform: translateX(-50%);
        width: 66%;
        justify-content: center;
    }

    #myChart {
        min-height: 214px !important;
        height: 100% !important;
        min-width: 314px !important;
        width: 100% !important;
        padding-left: 0;
        padding-right: 0;
        object-fit: contain;
    }

    .userprofile-containt {
        overflow-x: hidden;
    }

    .chart-prediction-line {
        margin-right: 15px !important;
    }

    .chart-prediction-line:last-child {
        margin-right: 0px !important;
    }


    /* .Lost-title {
        margin-right: 30px;

    }

    .won-title {
        margin-right: 30px;
    } */

    /* .container-1240 {
        overflow-x: hidden;
        overflow-y: hidden;
    } */
}

@media only screen and (min-width: 1025px) and (max-width: 1260px) {
    /* .chart-summary-div {
        min-width: 840px;
    } */

    .line-chart-part {
        /* min-width: 750px;
        width: 100%; */
        margin: 0px;
    }

    .pai-chart-menu {
        padding-right: 12.67px;
    }

    .padding-pai-chart {
        width: 150px !important;
        height: 150px !important;
    }
}

.paiChart-side-border-list {
    display: none !important;
}

@media screen and (max-width: 400px) {

    .pai-chart-1div,
    .pai-chart-2div {
        flex-direction: column;
    }

    .paiChart-side-border-list {
        display: flex !important;
        justify-content: center;
    }

    .profit-border-panel-list {
        display: none;
    }

    .mobile-paiChartCount-border-div {
        flex-direction: column;
    }

    .paiChart-side-border-list {
        justify-content: space-between !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 767.99px) {
    /* .pai-chart-part {
        padding: 0px 16px;
    } */

    .paiChart-side-border-list {
        display: flex !important;
        justify-content: center;
    }

    .pai-chart-1div,
    .pai-chart-2div {
        display: flex !important;
        justify-content: center;
    }

    .Pending-border-pai-chart-menu {
        padding-right: 0px;
    }

    .profit-border-panel-list {
        display: none;
    }

    .mobile-paiChartCount-border-div {
        flex-direction: column;
    }

    .pai-chart-graph {
        padding: 24px 16px 24px 16px;
    }
}

@media screen and (max-width: 1024px) {}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .pai-chart-1div {
        display: flex;
        justify-content: center;
    }

    .pai-chart-2div {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .paiChart-side-border-list {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: center;
        max-width: 346px;
        width: 100%;
        margin: 0 auto;
        padding-top: 22px;
    }


}


/* top prediction list css */
.chart-summary-sub-div .topPredictionList-div {
    /* min-width: 840px;
    max-width: 840px; */
    padding: 32px 32px 8px 32px;
    width: 100%;
    margin: 24px 0px 24px 0px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    width: 100%;
}

.topPredictionList-div .emptyData-graph {
    padding: 42px 32px 42px 32px;
}

.topPredictionList-div .Notenoughdata-msg {
    color: #131520;
}

.Top-predictions-tabletitle {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4C5480;
}

.Event-table-title,
.Event-table-decription {
    max-width: 390px;
    width: 100%;
}

.Event-table-Predictiontype,
.Predictiontype-decription {
    max-width: 260px;
    width: 100%;
    padding-left: 24px;
}

/* .Event-table-Predictiontype {
    padding-left: 8px;
} */


.Event-table-Odds,
.Odds-decription {
    max-width: 160px;
    width: 100%;
    padding-left: 16px;
}



.Top-predictions-teams-detail {
    padding-right: 24px
}

.Top-predictions-row {
    padding: 24px 0px 24px 0px;
    /* border-bottom: 1px solid #F2F3F7; */
    align-items: center;
}

.Top-predictions-main-row:last-child {
    border-bottom: none;
}

.Top-predictions-main-row {
    border-bottom: 1px solid #F2F3F7;
}

.Top-predictions-teams-detail .team1-logo,
.Top-predictions-teams-detail .team2-logo {
    width: 44px;
    height: 44px;
    background: #F2F3F7;
}


.dot {
    margin-top: 3px;
    height: 6px;
    width: 6px;
    background-color: #208B8E;
    border-radius: 50%;
    display: inline-block;
}

.Odds-decription .oddsScore,
.mobile-oddsScore-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0D393A;
}

.Odds-decription {
    display: flex;
    align-items: center;
}

.oddsScore-button {
    /* max-width: 63px;
    width: 100%; */
    display: flex;
    align-items: center;
    padding: 2px 8px 2px 6px;
    background: #EEFBFB;
    border-radius: 16px;
}


.pridictionCountScore-button {
    margin-left: 8px;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 6px;
    color: #4C5480;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #F2F3F7;
    border-radius: 16px;
    cursor: pointer;
}

.pridictionCountScore-tooltip {
    min-width: 208px;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 35px;
    left: 61%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    background: #131520;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 8px 12px;
}

.pridictionCountScore-tooltip:after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: #131520 transparent transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
}

.pridictionCountScore-div:hover .pridictionCountScore-tooltip {
    opacity: 1;
    /* right: 0; */
    visibility: visible;
}

@media screen and (max-width: 805.99px) {
    .pridictionCountScore-tooltip {
        min-width: 130px;
        width: 100%;
    }
}


.mobile-oddsScore-button {
    display: none !important;
}

@media screen and (max-width: 767.99px) {
    .Top-predictions-tabletitle {
        display: none;
    }

    .Top-predictions-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .topPredictionList-div {
        padding: 24px 16px 0px 16px !important;
    }

    .Predictiontype-decription,
    .Odds-decription {
        padding-left: 0px;
    }

    .Event-table-title,
    .Event-table-decription,
    .Event-table-Predictiontype,
    .Predictiontype-decription {
        max-width: 100%;
    }

    /* .profile-bottom-section {
        overflow-x: auto;
    } */

    .Top-predictions-teams-bitton-div {
        flex-direction: column;
    }

    .mobile-oddsScore-button {
        display: flex !important;
    }

    .Top-predictions-teams-bitton-div .both-Team-logo {
        justify-content: space-between;
        Padding: 2px 0px 10px 0px
    }

    .Predictiontype-decription .both-team-league-name {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .Top-predictions-teams-detail {
        padding: 0px;
    }

    .oddsScore-button {
        display: none;
    }

    .pridictionCountScore-button {
        margin-left: 0px;
    }

    .chart-summary-div {
        padding-left: 16px;
        padding-right: 16px;
    }
}