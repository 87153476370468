.leaderboard__table table {
    width: 100%;
    margin-top: 24px;
}

.table-header {
    position: sticky;
    top: 0;
    background-color: #ffff;
    z-index: 1;
    color: #7F87B3;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    height: 43px;
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5); */
}

th {
    font-weight: 500;
}

tr {
    height: 92px;
}

.table-row {
    height: 92px;
    color: #131520;
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    border-bottom: 1px solid #F2F3F7;
    border-top: 1px solid #F2F3F7;
}

.table-row.active {
    background-color: #F2F3F7;
}

.coin-icon {
    width: 14px;
    height: 16px;
    margin-bottom: 2px;
}

.hint-icon {
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: 600;
}

.user-place-cell,
.user-place-header {
    text-align: center;
    color: #7F87B3;
}

.coins-cell {
    font-weight: 600;
    padding-left: 35px;
}

.coins-header img {
    margin-right: 5px;
}

.user__info {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.user__info:hover .user-avatar {
    border: 2px solid #BFC3D9;
}

.user__info:hover .user__info-name {
    /* border-bottom: 1px solid; */
    color: black;
    text-decoration: underline;
}

.user-avatar {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #dfe1ec;
    color: #0d393a;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-transform: capitalize;
}

.user-avatar img {
    border-radius: 100%;
    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: 100%;
}

.user__info-text {
    display: flex;
    flex-direction: column;
}

.user__info-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    width: fit-content;
}

.user__info-nick {
    color: #7F87B3;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}

.hint {
    position: relative;
    display: flex;
    width: fit-content;
    height: fit-content;
    align-items: center;
    cursor: pointer;
}

.hint:hover {
    color: #131520
}

.badge {
    height: 32px;
    border-radius: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0D393A;
    background-color: #EEFBFB;
    padding: 4px 12px;
}

.active .badge {
    background-color: #DEF7F8;
}

.active .tag {
    background-color: #DFE1EC;
}

.tag {
    display: flex;
    justify-content: center;
    height: 24px;
    width: fit-content;
    border-radius: 6px;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #5F69A0;
    background-color: #F2F3F7;
    padding: 4px 8px;
}

.tag.empty {
    width: 82px;
}

th,
td {
    /* border: 1px solid black; */
    padding-left: 10px
}

/*column width*/

.width-sm {
    width: 8%;
}

.width-md {
    width: 11%;
}

.width-md_plus {
    width: 13%;
}

.width-lg {
    width: 35%;
}

.delimeter-dots td {
    text-align: center;
}

.leaderboard-dot {
    background-color: #7F87B3;
    margin-top: 3px;
    height: 6px;
    width: 6px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 10px;
}

@media only screen and (max-width: 767.99px) { 
    .coins-header{
        padding: 0 !important;
        /* width: 295px !important; */
    }   
    .width-sm {
        width: 40px;
    }
    
    .width-md_plus {
        width: 105px;
    }
    
    .width-lg {
        width: 200px;
    }

    .user-place-cell img{
        width: 32px;
        height: 32px;
    }

    .user-place-cell,
    .user-place-header{
        padding-left: 0;
    }

    .coins-cell{
        padding-left: 35px;
    }
}

@media only screen and (max-width: 390px) { 
    .coins-cell{
        padding-left: 15px;
    }
}