.admin-name,
.Giveaways-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #131520;
}

.Let-check-status,
.giveAwaysContent {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;

}

.admin-cards-frame {
    padding-top: 32px;
    padding-bottom: 64px;
}

.admin-newuser-cards {
    max-width: 302px;
    width: 100%;
    padding: 32px;
    background: #0D393A;
    border-radius: 6px;
    margin-right: 32px;
}

.admin-newuser-cards .title {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #DEF7F8;
    padding-bottom: 24px;
}

.total-user-count {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #DEF7F8;
}

.admin-newuser-cards .userCount {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding-bottom: 16px;
}

.admin-newprediction-cards {
    max-width: 302px;
    width: 100%;
    padding: 32px;
    border-right: 1px solid #F2F3F7;
}

.admin-newprediction-cards .title {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    padding-bottom: 24px;
}

.newpredictionCount {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #131520;
    padding-bottom: 16px;
}

.total-prediction-count {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;

}

.see-all-user {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    cursor: pointer;
}

.dashboard-user-table {
    border: 1px solid #F2F3F7;
    padding: 32px;
    border-radius: 6px;
}

/* table design */
.dashboard-user-table .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #131520;
    padding-bottom: 24px;

}

.user-table {
    border-collapse: collapse;
    width: 100%;
}

.user-table th {
    border-bottom: 1px solid #F2F3F7;
    padding-top: 16px;
    padding-bottom: 16px;
}

.user-table td {
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #F2F3F7;
}

.user-table th {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
}

.user-profile-containt-panel .user-first-last-name:hover {
    text-decoration: underline;
    cursor: pointer;
}

.SeeAllUsersBtn {
    margin-top: 24px;
    padding: 12px 16px 12px 16px;
}

.status-button .PendingBtn {
    color: #C09E16;
    padding: 4px 8px 4px 8px;
    background: #FDFAED;
    border: 1px solid #C09E16;
    border-radius: 16px;
    text-align: center;
    max-width: 69px;
    width: 100%;
}

.status-button .ActivatedBtn {
    max-width: 77px;
    width: 100%;
    padding: 4px 8px 4px 8px;
    background: #F7FDFD;
    border: 1px solid #0D393A;
    border-radius: 16px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #0D393A;
}

.s-rate-button .blue-btn {
    min-width: 45px;
    text-align: center;
    width: 100%;
    padding: 4px 8px 4px 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
    background: #F2F3F7;
    border: 1px solid #7F87B3;
    border-radius: 16px;
}

.s-rate-button .yellow-btn {
    max-width: 45px;
    text-align: center;
    width: 100%;
    padding: 4px 8px 4px 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #C09E16;
    background: #FDFAED;
    border: 1px solid #C09E16;
    border-radius: 16px;
}

.s-rate-button .red-btn {
    max-width: 45px;
    text-align: center;
    width: 100%;
    padding: 4px 8px 4px 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #C94B13;
    background: #FDF4F4;
    border: 1px solid #A73636;
    border-radius: 16px;
}

.user-profile-containt-panel .user-first-last-name,
.change-permission-user .user-first-last-name,
.Country-panel .Country-name,
.email-panel .email-id,
.Pred-rate {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.email-panel a {
    text-decoration: none;
}

.user-profile-containt-panel .user-name,
.change-permission-user .user-name,
.Country-panel .ip-count,
.email-panel .signed-with-google-facebook {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
}

.email-panel .signed-with-google-facebook {
    cursor: pointer;
}

.user-profile-containt-panel {
    display: flex;
}

@media(max-width: 500px) {
    .heading {
        display: none;
    }

    .user-table td {
        display: block;
    }

}

/* tooltip */
.tooltip-icon {
    height: 115.75px;
}

.tooltip-change-permision-div,
.tooltip-delete-user-div {
    cursor: pointer;
}


.change-permision-button-tooltip,
.change-permision-button-tooltip-ua {
    /* width: 100%; */
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    /* top: 187%;
    left: 89%; */
    /* transform: translateX(-50%); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 32px;
    background: #131520;
    border-radius: 8px;
    flex: none;
    order: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 8px 12px;
}

.delete-user-button-tooltip {
    min-width: max-content;
    width: 100%;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 186%;
    left: 92.5%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 32px;
    background: #131520;
    ;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 8px 12px;
}

.change-permision-button-tooltip:after,
.delete-user-button-tooltip:after,
.change-permision-button-tooltip-ua::after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: #131520 transparent transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
}

.tooltip-change-permision-div:hover .change-permision-button-tooltip,
.tooltip-delete-user-div:hover .delete-user-button-tooltip,
.tooltip-change-permision-div:hover .change-permision-button-tooltip-ua {
    opacity: 1;
    visibility: visible;
}

.permission-icon-path {
    transition: all 0.2s ease-in-out;
}

.icon-container:hover .permission-icon-path {
    stroke: #000;
}

.tooltip-change-permision-div,
.tooltip-delete-user-div {
    position: relative;
}

.change-permision-button-tooltip {
    /* top: 100% !important; */
    top: -48% !important;
    left: -35px !important;
    width: max-content !important;
}

.change-permision-button-tooltip-ua {
    bottom: 80%;
    right: -58px !important;
    width: max-content !important;
}

.icon-container {
    position: relative;
}

.delete-user-button-tooltip {
    top: -48% !important;
    left: 13px !important;
}

.icon-container:hover .change-permision-button-tooltip,
.icon-container:hover .change-permision-button-tooltip-ua {
    opacity: 1;
    visibility: visible;
}

.icon-container:hover .delete-user-button-tooltip {
    opacity: 1;
    visibility: visible;
}

/* delete user model */
.delete-user-model .modal-dialog {
    max-width: 449px;
    width: 100%;
    margin: 100px auto;
}



.delete-user-model .delete-sport-Msg {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

/* change-permission-model */
.change-permission-model .modal-dialog {
    max-width: 484px !important;
    width: 100%;
    margin: 100px auto;
}

/* custom loader */
.admin-card-user-loader {
    padding: 32px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    background-color: #0D393A;
}

.admin-card-loader {
    padding: 32px;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
}

td.user-profile-containt-panel {
    min-height: 115.75px;
    height: 100%;
}

/*
td.Country-panel {
    padding-right: 32px;
}

td.email-panel {
    padding-right: 32px;
} */

td.status-button {
    /* padding-right: 32px; */
    padding-left: 0;
    max-width: 100%;
    border-left: none;
    border-right: none;
}

td.s-rate-button {
    padding-right: 32px;
}

td .Pred-rate {
    padding-right: 32px;
}

.delete-changepermission-icon .tooltip-change-permision-div {
    padding-right: 23px;
}

td.status-button .ActivatedBtn {
    max-width: 100%;
}

.change-permission-model .inputfield-label {
    margin-top: 16px !important;
}

.change-permission-model .inputfield-tab {
    max-width: 100%;
}