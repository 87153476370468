.read-more-less_text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.read-more-less_full-text {
    -webkit-line-clamp: unset;
}

.read-more-less_btn {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
}


@media screen and (max-width: 768px) {
    .read-more-less_text {
        -webkit-line-clamp: 5;
    }

    .read-more-less_full-text {
        -webkit-line-clamp: unset;
    }
}