.cell-wrapper-center{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.skeleton-grid-placeholder {
    background: linear-gradient(90deg, #F2F3F7 0px, #f8f8f8 40px, #F2F3F7 80px);
    background-size: 350px;
    width: 35px;
    height: 20px;
    border-radius: 12px;
    animation: animation 2.5s infinite;
}

.skeleton-grid-placeholder.circle{
    width: 20px;
    height: 20px;
}

.skeleton-grid-placeholder.circle-md{
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.skeleton-grid-placeholder.md{
    width: 70px;
    height: 20px;
}

.skeleton-grid-placeholder.lg{
    width: 90px;
    height: 20px;
}


@keyframes animation {
    0% {
        background-position: -100px;
    }

    40%,
    100% {
        background-position: 270px;
    }
}