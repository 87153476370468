.leaderboard-banner-section {
    height: 445px;
    width: 100%;
    background: #0D393A;
    box-shadow: 0px 16px 32px 0px rgba(5, 6, 15, 0.05);
    position: relative;
    overflow: hidden;
}

.leaderboard-banner__content,
.leaderboard__content {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}

.leaderboard-banner__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}

.banner__text-section{
    z-index: 3;
}

.banner__title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    width: fit-content;
    text-transform: uppercase;
}

.banner__sub-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    width: fit-content;
}

.gradiant-single-border {
    width: 160px;
    height: 160px;
    /* display: block; */
    z-index: 10;
    /* position: absolute; */
    margin: 0 auto;
    top: 2rem;
    right: 500px;
}

.banner-image {
    z-index: 1;
    transform: rotate(9deg);
}

.banner-image-data {
    max-width: 527px;
    width: 100%;
    max-height: 527px;
    height: 100%;
}

.leaderboard-banner-image-gradiant {
    border-radius: 50px;
    background: #208B8E;
    filter: blur(100px);
    width: 428px;
    height: 428px;
    position: absolute;
    right: -1%;
    bottom: -15px;

}

.leaderboard__content-section {
    width: 100%;
    padding-top: 32px;
    background-color: #FFF;
    padding-bottom: 24px;
}

.leaderboard__header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leaderboard__header-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.homePage-footer-main-div {
    background-color: #fff;
}

.leaderboard__table{
    position: relative;
}

.signup-banner{
    position: absolute;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    height: 345px;
    padding: 48px;
    text-align: center;
}

.signup-banner-title{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    margin-top: 16px;
}

.signup-banner-text{
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}

.sigin-link{
    margin-top: 24px;
    background-color: #0D393A;
    padding: 16px 24px;
    border-radius: 6px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight:7300;
    line-height: 24px;
    transition: all 0.3s ease-in-out;
}

.sigin-link:hover{
    color: #fff;
    background-color: #09292a
}

/* teblet media */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .leaderboard-banner-section{
        height: 304px;
    }

    .banner-image-data {
        width: 354px;
        height: 354px;
    }

    .leaderboard-banner-image-gradiant {
        width: 288px;
        height: 288px;
        right: -3%;
        bottom: -15px;
    
    }

    .gradiant-single-border {
        display: block !important;
        right: 337px;
    }
}

/* mobile */
@media only screen and (max-width: 767.99px) {  
    .leaderboard-banner-section{
        height: 384px;
    }

    .leaderboard-banner-image-gradiant {
        width: 345px;
        height: 345px;
    }

    .leaderboard-banner__content {
        margin-top: 24px;
        flex-direction: column;
    }

    .banner__title{
        font-size: 32px;
        line-height: 48px;
        text-transform: capitalize;
    }

    .leaderboard__header {
        height: 88px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .banner-image {
        margin-top: 20px;
    }

    .banner-image-data {
        max-width: 248px;
        max-height: 248px;
    }

    .gradiant-single-border {
        display: none !important;
    }

    .dropdown-input{
        width: 100%;
    }
}

.dropdown-input{
    z-index: 24 !important;
}

.logedInUser-navbar {
    z-index: 25;
}
