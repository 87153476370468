.searchbar-feild {
    padding: 14px 16px;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-indent: 26px;
}

.searchbar-feild:hover {
    border: 1px solid #BFC3D9;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.filter-searchbar-section .form-control:disabled {
    background-color: #ffffff;
    opacity: 1;
}

.filter-searchbar-section .prediction-list-searchbar,
.filter-button-lock-tooltip {
    position: relative;
}

.searchbarLocked-tooltip {
    max-width: 270px;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 53px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    background: #131520;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 8px 12px;
    z-index: 15;
}

.filter-button-tooltip {
    min-width: 270px;
    width: 100%;
    bottom: 55px;
}

@media only screen and (max-width: 1280px) {
    .filter-button-tooltip {
        min-width: 135px;
        width: 100%;
    }
}

.searchbarLocked-tooltip::after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 5px;
    border-color: #131520 transparent transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
}

.prediction-list-searchbar:hover .searchbarLocked-tooltip,
.product-card-div .filter-button-lock-tooltip:hover .searchbarLocked-tooltip,
.filter-searchbar-section .filter-button-lock-tooltip:hover .searchbarLocked-tooltip {
    opacity: 1;
    visibility: visible;
}


.product-card-div .filter-button-tooltip {
    min-width: 302px;
}

.searchbar-feild:focus {
    border: 2px solid #DFE1EC;
    outline: none;
    box-shadow: none;
}

.searchbar-feild::placeholder {
    color: #7f87b3 !important;
}

.searchbar-feild::placeholder {
    color: #7f87b3 !important;
}

.searchbar-feild:-moz-placeholder {
    color: #7f87b3 !important;
}

.searchbar-feild::-moz-placeholder {
    color: #7f87b3 !important;
}

.searchbar span {
    position: absolute;
    padding: 14px 16px;
}

.dropDownSearchbar span,
.dropDownSearchbar-feild {
    padding: 8px !important;
}

.dropDownSearchbar {
    padding-right: 3px;
}

/* showSuggetions searchBar */
.showSuggetions-filter {
    min-width: 240px;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 5px;
    background-color: #ffffff;
    border: 1px solid #DFE1EC;
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    border-radius: 6px;
    margin-top: 5px;
    position: absolute;
    z-index: 1;
}

.filtered-items {
    padding-top: 8px;
    flex-wrap: wrap;
}

.filtered-items-containter {
    padding: 8px;
    cursor: pointer;
}

.filtered-items-containter,
.Pending-containter,
.Won-containter,
.Lost-containter,
.Returned-containter {
    padding: 8px;
    cursor: pointer;
}

.filtered-items-containter:hover,
.tournament-container:hover,
.Returned-containter:hover,
.Pending-containter:hover,
.Won-containter:hover,
.Lost-containter:hover {
    background-color: #F2F3F7;
    border-radius: 6px;
}

.filtered-items-name,
.Returned-containter,
.Pending-containter,
.Won-containter,
.Lost-containter {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    padding-left: 8px;
}

.tournament-container {
    padding: 10px 8px;
    cursor: pointer;
}

/* sport-container */
.filter-sport-container,
.filter-tournament-container {
    max-height: 224px;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-right: 3px;
}


.filter-sport-container::-webkit-scrollbar,
.filter-tournament-container::-webkit-scrollbar {
    width: 5px;
}

.filter-sport-container::-webkit-scrollbar-thumb,
.filter-tournament-container::-webkit-scrollbar-thumb {
    background: #BFC3D9;
    border-radius: 6px;
}

.filter-sportDataEmpty-msg,
.alert-message {
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    padding-left: 16px;
}