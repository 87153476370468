.privacy-wrapper {
    background-color: #F9F9FB;
    border-bottom: 1px solid #F2F3F7;
    border-top: 1px solid #F2F3F7;
    min-height: 200px;
    height: 100%;
}

.privacy-wrapper-bottom-container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    margin-top: 56px;
    margin-bottom: 100px;
}

.privacy-wrapper-inner-container {
    max-width: 870px;
    width: 100%;
}

.privacy-wrapper-container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.privacyPolicy-contains {
    padding-top: 64px;
    padding-bottom: 64px;
}

.privacy-content {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #131520;
}

.version-update {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
}

.privacy-policy-description {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    margin-bottom: 16px;
}

.privacy-policy-description span {
    padding-right: 8px;
}

.privacy-policy-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
    color: #131520;
    margin-top: 40px;
}

.Tracking-sub-div {
    padding-left: 17px;
}

.Tracking-main-div {
    padding-left: 24px;
}