.homePage-nav .navlink {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.home-nav-right-side {
    max-width: 333px;
    width: 100%;
    max-height: 48px;
    height: 100%;
    justify-content: flex-end;
}

.container-1440 {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.homeNavCreateAccountBtn {
    margin-top: 0px;
    margin-left: 8px;
    min-width: 141.27px;
    width: 100%;
}

.homeNavlogin {
    padding: 12px 16px;
    min-width: 75px;
    width: 100%;
}

.nav-language-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    font-family: 'SF UI Display';

}

.home-Page-language-selector {
    position: relative;
}

.home-nav-right-side .language-suggestion {
    right: 18px;
}

.nav-language-containt {
    margin-right: 24px;
    cursor: pointer;
    padding-right: 8px;
    height: 100%;
    padding-left: 8px;
}

.nav-language-containt:hover,
.mobile-language-name:hover {
    background-color: #f2f3f7;
    border-radius: 6px;
    cursor: pointer;
}

.nav-language-logo {
    margin-right: 10px;
    cursor: pointer;
}

.mobile-nav-right-side {
    display: none;
}

.home-nav-right-side {
    display: flex;
}

.homepageHeader-topnav {
    flex-wrap: wrap;
}

.mobile-language-containt {
    padding: 10px;
}

.mobile-nav-right-side {
    justify-content: space-between;
}

.mobile-language-containt:hover {
    background-color: #f2f3f7;
    border-radius: 6px;
}



/* teblet media */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .homepageHeader-topnav {
        display: flex;
    }

    .mobile-language-name {
        margin-bottom: 10px;
    }

    .home-nav-right-side {
        max-width: 100%;
        justify-content: flex-start;
    }

    .mobile-nav-right-side {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .mobile-nav-right-side .homeNavCreateAccountBtn {
        margin-left: 0px;
        margin-top: 20px;
    }

    .mobile-nav-right-side .nav-language-containt {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .home-nav-right-side {
        display: none;
    }

    .toggle-button-responsive {
        width: 100%;
        justify-content: space-between;
    }

    .mobile-nav-right-side .language-suggestion {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        right: 0;
    }

    .mobile-nav-right-side .language-toggle-icon {
        display: flex;
        align-items: center;
    }

    .mobile-nav-right-side .language-suggestion {
        border: none;
        box-shadow: none;
        border-radius: none;
    }

}

.notLogedinUser-nav-languages {
    display: none;
}

@media only screen and (max-width: 1023.99px) {
    .homepageHeader-topnav {
        display: flex;
        padding: 16px !important;
        /*  border-bottom: 1px solid #F2F3F7; */
    }

    .notLogedinUser-nav-languages {
        display: flex;
    }

    .notLogedIn-userNavbar {
        padding: unset !important;
    }

    .notLogedinUser-nav-languages .English-EN-dropDown-active {
        padding: 16px 16px;
        display: flex !important;
        justify-content: center;
        background-color: #F2F3F7;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #131520;
        align-items: center;
    }

    .notLogedinUser-nav-languages .Українська-UA-dropDown-active {
        padding: 16px 16px;
        display: flex !important;
        justify-content: center;
        background-color: #F2F3F7;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #131520;
        align-items: center;
    }

    .notLogedinUser-nav-languages .Українська-UA-English-EN-dropDown {
        padding: 16px 16px;
        display: flex !important;
        justify-content: center;
        border-radius: 6px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #131520;
        align-items: center;
    }

    .notLogedinUser-nav-languages .Українська-UA-label,
    .notLogedinUser-nav-languages .English-EN-label {
        padding-left: 8px;
    }

    .homeNavlogin {
        margin-top: 26px;
    }

    .homePage-nav .navlink {
        padding: 20px 0px 22px 0px;

    }

    .home-nav-right-side {
        max-width: 100%;
        justify-content: flex-start;
    }

    .mobile-nav-right-side {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .mobile-nav-right-side .homeNavCreateAccountBtn {
        margin-left: 0px;
        margin-top: 20px;
    }

    .mobile-nav-right-side .slogo {
        display: none;
    }

    .mobile-nav-right-side .nav-language-containt {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100%;
    }

    .mobile-nav-right-side .language-suggestion {
        padding: 8px;
    }

    .home-nav-right-side {
        display: none;
    }

    .toggle-button-responsive {
        width: 100%;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1025px) {
    .homepageHeader-topnav {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 778px) {
    .mobile-nav-right-side {
        width: 100%;
    }
}

.Signup-to-continue {
    margin-top: 0px;
    margin-right: 32px;
    padding: 12px 16px;

}

.Sign-in-continue {
    margin-left: 32px;
    padding: 12px 16px;
}

.or-word {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;

}