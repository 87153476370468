.following-modal {
    position: static;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
}

.content-modal {
    overflow-y: auto;
}

.content-modal::-webkit-scrollbar {
    display: none;
}

.no-follow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px 4px;
    gap: 24px
}

.no-follow-img {
    width: 48px;
    height: 48px;
}

.no-follow-text-wraper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.no-follow-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #131520;
    align-items: center;
}

.no-follow-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #7F87B3;
    text-align: center;
}