.game-details__controls {
    display: flex;
    justify-content: space-between;
}

.copy-link__btn,
.back__btn {
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.copy-link__btn:hover,
.back__btn:hover {
    border: 1px solid #BFC3D9;
}

.game-details__teams-info {
    margin-top: 24px;
}

.game-details__teams-imgs {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.game-details__team-img-wrapper {
    width: 149px;
    height: 149px;
    background-color: #F2F3F7;
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team1-img-wrapper {
    margin-left: 10px;
}

.team2-img-wrapper {
    margin-left: -25px;
}

.game-details__teams-name {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.game-details__team-name-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    align-items: center;
}

.game-details__match-time {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    gap: 12px;
}

.game-details__match-time .game-period,
.game-details__match-time .live-champions-leaue-score-minute {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    width: fit-content;
}

.game-details__match-time .extra-time {
    background-color: #FDF4F4;
    padding: 4px;
}

.game-details__match-time_delimeter {
    width: 2px;
    height: 10px;
    background-color: #D14343;
}

.game-details__team-score {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #F2F3F7;
    border-radius: 4px;
    padding: 4px;
    ;
}

.game-details__team-score.live {
    color: #D14343;
    background-color: #fdf4f4;
    border: 1px solid #D14343;
}

.game-details__info-about-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #7F87B3;
    gap: 8px;
}

.game-details__info-about {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.make-prediction__link {
    margin-top: 24px;
    width: 100%;
    height: 48px;
    display: inline-block;
    padding: 12px 16px;
    background-color: #0D393A;
    color: #FFF;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 6px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.make-prediction__link:hover {
    color: #FFF;
    background-color: #09292a;
}

.game-details__live-status-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-info__teams-score.live {
    color: #D14343;
}

@media only screen and (max-width: 1024px) {
    .game-details__game-info {
        display: flex;
        flex-direction: column;
        gap: 32px;
        background-color: #fff;
        padding: 16px 24px 32px 24px;
    }

    .game-details__top-mobile-panel {
        position: absolute;
        width: 100%;
        top: -10px;
        background-color: #fff;
        padding: 16px 24px;
        transition: all .3s ease-in;
        border-bottom: 1px solid #F2F3F7;
    }

    .game-details__top-mobile-panel.scrolled {
        position: absolute;
        width: 100%;
        top: 70px;
        z-index: 10;
        background-color: #fff;
        padding: 16px 24px;
    }

    .game-info__top-tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        gap: 4px;
    }

    .game-info__match {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #7F87B3;
    }

    .game-info__league-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        /* margin-bottom: 4px; */
        text-align: center;
    }

    .game-details__match-time {
        margin-bottom: 0;
    }

    .game-info__teams-info-wrapper {
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
    }

    .game-info__team-info {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .game-info__team-name {
        font-size: 16px;
        font-weight: 700;
        width: 124px;
    }

    .game-details__team-img-wrapper {
        width: 100px;
        height: 100px;
    }

    .game-details__team-logo {
        width: 80px;
        height: 80px;
    }

    .game-info__teams-score {
        font-size: 32px;
        font-weight: 700;
        white-space: nowrap;
    }

    .make-prediction__link-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .make-prediction__link {
        margin-top: 0;
        width: fit-content;
    }

    .game-info__top-tab .game-details__team-img-wrapper {
        width: 40px;
        height: 40px;
    }

    .game-info__top-tab .game-details__team-logo {
        width: 32px;
        height: 32px;
    }
}

@media only screen and (max-width: 768px) {
    .game-details__game-info {
        padding: 16px 16px 24px 16px;
    }

    .game-info__team-info {
        flex-direction: column;
    }

    .game-info__team-name {
        text-align: center;
        height: 40px;
    }

    .game-info__team-name.team1-name {
        order: 2;
    }

    .game-info__teams-score {
        padding-bottom: 50px;
        font-size: 24px;
    }

    .game-info__teams-score.top-mobile{
        padding: 0;
    }

    .make-prediction__link {
        width: 100%;
    }

    .game-details__top-mobile-panel {
        padding: 16px;
    }

    .game-info__top-tab .game-info__team-name {
        display: none;
    }

    .game-info__top-tab .game-info__teams-score {
        font-size: 18px;
    }
}

.game-info__league-loader {
    width: 167px;
    height: 16px;
    margin-bottom: 4px;
}

.game-info__date-loader {
    width: 87px;
    height: 16px;
}

.game-info__team-name-loader {
    width: 100%;
    height: 24px;
}

.make-prediction__link.loading {
    opacity: 60%;
}

.game-details_info-img_loader {
    width: 24px;
    height: 24px;
}

.game-details_info-title_loader {
    width: 184px;
    height: 24px;
}

.game-details_team1-name_loader {
    width: 95px;
    height: 24px;
}

.game-details_team2-name_loader {
    width: 129px;
    height: 24px;
}


.game-details_team-score_loader {
    width: 32px;
    height: 32px;
}