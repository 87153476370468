.social-media-containt {
    margin-bottom: 32px;
}

.social-media-network {
    max-width: 176px;
    width: 100%;
    justify-content: space-between
}

.link-copybtn {
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: space-between;
}

.sharelink-inputbtn {
    max-width: calc(100% - 360px);
    width: 100%;
}

.share-input-btn .input-button {
    min-height: 54px;
}

.sharelink-inputbtn .input-button {
    padding: 14px 12px !important;
}

.sharelink-inputfeild {
    padding: 14px 16px !important;
}

.shareprofile-body-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    margin-bottom: 8px;
}

.shareprofile-model .modal-dialog {
    max-width: 551px;
    width: 100%;
}

.shareModelStyle {
    margin-top: 30px;
}

.twiter-media,
.facebook-media,
.telegram-media,
.download-media {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    background-color: #F2F3F7;
    cursor: pointer;
}

.twiter-media:hover,
.facebook-media:hover,
.telegram-media:hover,
.download-media:hover {
    background-color: #DFE1EC;
}

.twiter-media span,
.facebook-media span,
.telegram-media span,
.download-media span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}