.input-button {
    padding: 12px 16px;
    min-width: 119.27px;
    /* background-color: #0d393a; */
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    line-height: 24px;
    border: none;
    border-radius: 6px;
    margin-top: 24px;
}

.show-odds-line,
.prediction-list-btn,
.show-odds-line,
.JoinGiveaway-btn,
.makePrediction-btn,
.makePrediction-joingiveawayBtn,
.subscription-Cancel-btn,
.Browse-predictions,
.homeNavCreateAccountBtn,
.apply-btn,
.setting-saveChanges,
.deleteConfirmBtn,
.default-button,
.Browse-all-sport-predictions,
.Get-Started-for-FREE-btn,
.Signup-to-continue,
.shareModelStyle {
    background-color: #0d393a;
}

/* .sport-line-sportBtn {
    min-width: 119.27px;
} */

.input-button:hover {
    background-color: #09292a !important;
    border: none;
    color: #ffffff;
}

.input-button[disabled] {
    background-color: #0d393a !important;
    opacity: 40% !important;
}

.secondary-btn {
    padding: 6px 16px;
    background-color: #ffffff;
    border: 1px solid #DFE1EC;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    line-height: 24px;
    color: #131520;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
}

.secondary-btn:focus {
    border: 2px solid #dfe1ec !important;
}

.secondary-btn:hover {
    border: 1px solid #bfc3d9;
}

.copybtn-span {
    padding-right: 8px;
}

.show-odds-line {
    min-width: 135px;
    width: 100%;
    margin-top: 0;
}

.icon-button {
    display: flex;
    justify-content: space-between;
    /* min-width: 102px;
    width: 100%; */
    background-color: #ffffff;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
    padding: 14px 0 14px 8px;
}

.add-sport-btn {
    margin-top: 0px;
    justify-content: unset;
    border: 1px solid #0D393A !important;
    border-radius: 6px;
    padding: 10px 16px 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0D393A !important;
    background-color: #F7FDFD;
}

.add-sport-btn span,
.add-sport-gray-btn span {
    padding-right: 10px;
}

.add-sport-gray-btn {
    margin-top: 0px;
    /* min-width: 124px;
    width: 100%; */
    justify-content: unset;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    padding: 10px 16px 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7F87B3;
    margin-right: 8px;
}

.add-sport-plus-btn {
    padding: 10px 16px 10px 16px;
    min-width: 130px;
    width: 100%;
    justify-content: unset;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    margin-right: 8px;
}

.icon-button span {
    padding-right: 8px;
}

.addPrediction-buttons {
    display: flex;
}

.buttons-container .top-panel {
    margin-bottom: 20px;
}

.addPrediction-button {}

.add-sport-plus-btn span {
    padding-right: 10px;
}

.add-sport-plus-btn:hover {
    background-color: #F2F3F7;
    border-radius: 6px;
}

.add-sport-plus-btn span {
    padding-right: 10px;
}

.manage-sport-btn {
    min-width: 160px;
    width: 100%;
    justify-content: unset;
    padding: 10px 16px 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.manage-sport-btn:hover {
    background-color: #F2F3F7;
    border-radius: 6px;
}

.sport-btn-loop {
    margin-right: 8px;
    margin-bottom: 4px;
}

.manage-sport-btn span {
    padding-right: 10px;
}

.edit-delete-icon-button {
    padding: 10px 16px;
    color: #7F87B3;
    background-color: #ffffff;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.edit-icon {
    padding-left: 8px;
    padding-right: 0px !important;
    cursor: pointer;
}

.prediction-list-detail .delete-user-button-tooltip {
    top: -40px !important;
    left: 73% !important;
    padding: 8px 12px;
    min-width: max-content;
}

.remove-icon {
    padding-left: 8px;
}

.edit-delete-icon-button img {
    padding-right: 8px;
}

.delete-sportModel-Buttons .deleteDiscardBtn {
    padding: 12px 16px;
}

.delete-sportModel-Buttons .deleteConfirmBtn {
    margin-top: 0px;
    margin-left: 16px;
}

.delete-sportModel-Buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}


/* prediction-list-btn */
.Recomended-following-sidebar-btn-active {
    padding: 12px 16px;
    justify-content: unset;
    background-color: #F2F3F7;
    border-radius: 6px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    display: flex;
    align-items: center;
}

.Recomended-following-sidebar-btn-active span,
.Recomended-following-sidebar-btn span,
.filter-data-icon-button span {
    padding-right: 8px;
}

.Recomended-following-sidebar-btn {
    width: 100%;
    justify-content: unset;
    padding: 12px 16px;
    font-weight: 500;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    display: flex;
    align-items: center;
}

.Recomended-following-button {
    margin-bottom: 8px;
}

.prediction-list-btn {
    width: 100%;
    margin-top: 0px;
}

.filter-btn {
    cursor: pointer;
    padding: 12px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.filter-btn:hover {
    border: 1px solid #bfc3d9;
}

.filter-btn-active {
    background-color: #F7FDFD;
    border: 1px solid #0D393A !important;
    color: #0D393A;
}

.apply-btn {
    min-width: 0px;
    padding: 6px 16px;
    margin-top: 21px;
}

.CreateGiveaway-btn {
    /* min-width: 240px;
    width: 100%; */
    padding: 12px 67px;
}

/* filter-icon-btn */
.filter-data-icon-button {
    display: flex;
    padding: 8px;
    background-color: #F7FDFD;
    border: 1px solid #0D393A;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0D393A;
    margin-right: 8px;

}

.filter-close-icon {
    padding-left: 8px;
}


/* default button */
.default-button {
    margin-top: 0;
    padding: 16px 24px;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0D393A;
    transition: all 0.3s ease-in-out;
}

.default-button:hover {
    background-color: #FFFFFF !important;
    color: #0D393A;
}

/* .default-button:focus {
    border: 2px solid #208B8E;
    border-radius: 6px;
} */

/* Browse predictions-btn */
.Browse-predictions {
    margin-top: 0px;
    padding: 12px 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.Get-Started-for-FREE-btn:hover {
    background-color: #FFFFFF !important;
    color: #0D393A;
}

.Browse-all-sport-predictions {
    padding: 12px 32px;
    margin-top: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.favorite-sport-btn {
    padding: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    display: flex;
    align-items: center;
    border: 1px solid #F2F3F7;
    border-radius: 6px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
}

.Client-First-contains {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding-bottom: 32px;
}

.Get-Started-for-FREE-btn {
    padding: 12px 32px;
    background: radial-gradient(96.93% 448.75% at 50% 0%, #0B3132 0%, #061919 100%);
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin-top: 0px;
}

.favorite-sport-btn:hover,
.Get-Started-for-FREE-btn:hover {
    border: 1px solid #ffffff;
    border-radius: 6px;
    background: #F2F3F7;
    color: #0D393A;
}