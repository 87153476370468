.instagram-tiktok-inputFeild .input-group-text {
    padding: 16px 12px;
    background-color: #ffffff;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    border: 1px solid #DFE1EC;
}

.instagram-tiktok-inputFeild .form-control {
    padding: 16px 12px;
    background-color: #ffffff;
    border: 1px solid #DFE1EC;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    cursor: pointer;
}

.instagram-tiktok-inputFeild .form-control:hover {
    border: 1px solid #BFC3D9;
    cursor: pointer;
}

.instagram-tiktok-inputFeild .form-control:focus {
    box-shadow: none;
}

/* .input-group-text,
.input-group .form-control {
    border: 1px solid transparent;
} */

.input-group:hover .input-group-text {
    border: 1px solid #BFC3D9;
    border-radius: 6px;
    cursor: pointer;
}

.instagram-tiktok-inputFeild .input-group:hover .instagram-tiktok-inputFeild .form-control {
    border: 1px solid #BFC3D9;
    border-radius: 6px;
    cursor: pointer;
}

.instagram-tiktok-inputFeild .form-control::placeholder {
    color: #7f87b3 !important;
}

.instagram-tiktok-inputFeild .form-control::placeholder {
    color: #7f87b3 !important;
}

.instagram-tiktok-inputFeild .form-control::-moz-placeholder {
    color: #7f87b3 !important;
}

.instagram-tiktok-inputFeild .form-control::-moz-placeholder {
    color: #7f87b3 !important;
}

.subscription-Cancel-btn {
    padding: 12px 16px;
    margin-top: 32px;
}

.makePrediction-btn {
    min-width: 240px;

}