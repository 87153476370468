.setting-section {
    background-color: #F9F9FB;
    /* border-bottom: 1px solid #f2f3f7; */
}

.setting-tab-content {
    background-color: #ffffff !important;
}

.profile-save-changes {
    background-color: #0d393a;
}

@media screen and (max-width: 768px) {
    .setting-section .container-1240 {
        padding: 0px 15px !important;
    }
}

.settingpage-title {
    padding-top: 32px;
    padding-bottom: 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #131520;
    max-width: 540px;
    width: 100%;
    /* padding: 32px 15px 32px 15px; */
}

.tab-part .nav-tabs {
    /* max-width: 840px;
    width: 100%; */
    --bs-nav-tabs-border-width: 0;
}

.tab-part .nav-item {
    flex-basis: 0;
    flex-grow: 0;
    padding-right: 32px;
    /* padding-bottom: 8px; */
    text-align: center;
    flex-grow: 0;
}

.tab-part .nav-link {
    padding: 0;

}

.tab-part .nav-link {
    display: flex;
}

.tab-part .nav-tabs .nav-link.active {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #131520;
    background-color: #F9F9FB;
    border-bottom: 2px solid #131520;
    ;
}

.tab-part .nav-tabs {}

.tab-part .nav-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
    color: #7F87B3;
}

.general-tab-container {
    padding-top: 32px;
}

.profile-tab-container,
.security-tab-container {
    padding-top: 8px;
}

.selected-language,
.selected-currency {
    justify-content: space-between;
    border: 1px solid #DFE1EC;
    padding: 14px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.drop-down-logo {
    margin-right: 8px;
}

.general-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
}

.tab-content .tab-pane {
    max-width: 400px;
    width: 100%;
}

.list-drop-down-show {
    display: block;
}

.list-drop-down-hide {
    display: none;
}

.tabs-label {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;

}

.list-drop-down {
    padding: 8px;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    background-color: #ffffff;
}

.language-menu {
    padding: 10px 8px 10px 8px;
}

.language-menu:hover {
    background-color: #F2F3F7;
    cursor: pointer;
    border-radius: 8px;
}

.currency-label,
.oddformate-label {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    margin-top: 24px;
}

.currency-name,
.language-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
}

.general-button {
    margin-top: 24px;
    padding: 12px 16px;
    background-color: #0d393a;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    line-height: 24px;
    border: none;
    border-radius: 6px;

}

.tabs-dropdown {
    position: relative;
}

.list-unstyled {
    position: absolute;
    top: 53.97px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: auto;
}

.setting-password-acc {
    bottom: 0;
}

@media screen and (max-width: 991.98px) {
    .security-tab-container .setting-password-acc {
        border: #ffdead;
        bottom: 0;
        box-shadow: none;
        max-width: 100%;
        padding: 16px 0 0;
        position: relative;
        right: 0% !important;
        width: 100%;
        bottom: 0% !important;
        min-height: 170px !important;
    }
}

.setting-section .setting-tab {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    border-bottom: 1px solid #F2F3F7;
}

.setting-section .tab-content {
    max-width: 1270px !important;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    padding-bottom: 200px;
}

/* .tab-part .setting-tab-list {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
} */

.cancle-change-btn-width {
    min-width: 129px;
    width: 100%;
}

.resendEmail-btn-width {
    min-width: 118px;
    width: 100%;
}

.success-popup {
    position: fixed;
    right: 24px;
    bottom: 24px;
    max-width: 268px;
    width: 100%;
    border: 1px solid #0D393A;
    border-radius: 6px;
    padding: 16px 24px;
    background-color: #EEFBFB;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}

.popup-content {
    padding-left: 10px;
    padding-right: 10px;
}

.popup-hide {
    display: none;
}

.popup-show {
    display: flex;
}


.list-drop-down {
    z-index: 10;
}


.setting-password-acc {
    position: absolute;
    right: -59%;
    bottom: -346%;
    background: #fff;
    max-width: 234px;
    width: 100%;
    padding: 24px;
    border: 1px solid #dfe1ec;
    border-radius: 6px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease-in-out;
}

.setting-password-acc p {
    color: #7f87b3;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

.setting-password-acc li {
    color: #131520;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 24px;
}

.setting-password-acc li:last-child {
    margin-bottom: 0;
}

.setting-password-acc li i {
    color: #bfc3d9;
    margin-right: 8px;
}

.text-green {
    color: #208b8e !important;
}

/* profile-tab */
.security-tab-container {
    position: relative;
}

.security-tab-container .setting-password-acc {
    bottom: 0%;
    right: -60%;
    min-height: 241px;
}

.security-tab-container .setting-password-acc .list-unstyled {
    position: relative;
    left: auto;
    top: 0;
}