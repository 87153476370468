.game-vote__wrapper {
    margin-top: 32px;
    padding: 16px;
    border-radius: 6px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    background-color: #F2F3F7;
}

.game-vote__title {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
}

.game-vote_items {
    display: flex;
}

.game-vote_item {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    flex: 1;
    cursor: pointer;
}

.game-vote_item:hover {
    background-color: #DFE1EC;
}

.item-x {
    border-left: 1px solid #DFE1EC;
    border-right: 1px solid #DFE1EC;
}

.item-p1 {
    border-radius: 6px 0 0 6px;
}

.item-p2 {
    border-radius: 0 6px 6px 0;
}


.game-vote_item-title {
    color: #7F87B3;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
}

.game-vote_item-value {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
}

.game-vote_rating-items {
    display: flex;
    gap: 2px;
}

.game-vote_rating-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    font-weight: 600;
}

.game-vote_rating-item span {
    color: #4C5480;
    font-weight: 300;
}

.game-vote_progress.item-p1 {
    background-color: #9FA5C6;
}

.game-vote_progress.item-p2 {
    background-color: #BFC3D9;
}

.game-vote_rating-item {
    min-width: 10px;
}

.game-vote_progress {
    width: 100%;
    height: 16px;
    background-color: #DFE1EC;
}

.game-vote_progress.item-p1 {
    border-radius: 4px 0 0 4px;
}

.game-vote_progress.item-p2 {
    border-radius: 0 4px 4px 0;
}

.game-vote_rating-item.item-x {
    border: none;
}

.game-vote_item-value_loader {
    width: 28px;
    height: 20px;
    border-radius: 4px;
}

.game-vote_rating-titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
}

.game-vote_progress-title span {
    color: #4C5480;
    font-weight: 300;
}

.game-vote__no-votes {
    color: #5F69A0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.game-vote__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.game-vote__user-vote {
    color: #7F87B3;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
}

.game-vote__user-vote span {
    color: #000;
    font-size: 14px;
    line-height: 12px;
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    .game-vote__wrapper {
        margin: 0;
        background-color: #fff;
        border-radius: 6px;
        padding: 24px;
    }

    .game-vote_item {
        background-color: #F2F3F7;
    }

    .skeleton-placeholder-light.game-vote_item-value_loader {
        background: linear-gradient(90deg, #DFE1EC 0px, #f8f8f8 40px, #DFE1EC 80px);
    }
}