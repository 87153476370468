.inputfield-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #131520;
    padding-bottom: 4px;
    margin-top: 32px;
}

.shareinputfield-label {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #7F87B3;
    margin-bottom: 8px;
}

.shareinputfield {
    max-width: 360px;
    width: 100%;
    /* padding-right: 5px; */
}

.inputfield-tab {
    max-width: 400px;
    width: 100%;
    padding: 16px;
    border: 1px solid #DFE1EC;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #131520;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.inputfield-tab:hover {
    border: 1px solid #BFC3D9;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.inputfield-tab:focus {
    border: 2px solid #DFE1EC;
    outline: none;
    box-shadow: none;
}

.disable-inputfield {
    border: 1px solid #DFE1EC;
    outline: none;
    box-shadow: none;
    background-color: #F2F3F7;
    color: #9FA5C6;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}

.inputfield-tab::placeholder {
    color: #7f87b3 !important;
}

.inputfield-tab::placeholder {
    color: #7f87b3 !important;
}

.inputfield-tab:-moz-placeholder {
    color: #7f87b3 !important;
}

.inputfield-tab::-moz-placeholder {
    color: #7f87b3 !important;
}


.change-permission-model .inputfield-tab::placeholder {
    color: #131520 !important;
}

.change-permission-model .inputfield-tab::placeholder {
    color: #131520 !important;
}

.change-permission-model .inputfield-tab:-moz-placeholder {
    color: #131520 !important;
}

.change-permission-model .inputfield-tab::-moz-placeholder {
    color: #131520 !important;
}

.form-error .inputfield-tab {
    border: 1px solid #d14343;
    color: #d14343;
}

.form-error .inputfield-tab::placeholder {
    color: #d14343 !important;
}

.form-error .error-msg {
    color: #d14343;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}

.form-error .inputfield-tab:hover {
    border-color: #d14343;
}

.eye-toggle-icon {
    position: absolute;
    right: 4%;
    top: 28%;
    color: #BFC3D9
}

.input-toggle {
    display: flex;
    position: relative;

}

.updatePassword-pop-up {
    max-width: 456px !important;
}

.updateName-pop-up {
    max-width: 350px !important;
}

.verify-email-popup {
    background-color: #FDFAED;
    border: 1px solid #EBCC4F;
    border-radius: 6px;
    padding: 18.75px 16px 18.75px 18.75px 16px;
    margin-top: 24px;
}

.verify-email-frame-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #C09E16;
}



.verify-email-frame {}

.warning-icon {
    padding-right: 8px
}

.verify-email-frame-containt {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #7F87B3;
    padding-bottom: 8px;
}

.verify-email-popup-hide {
    display: none;
}

.verify-email-popup-show {
    display: block;
}

.verify-email {
    color: #131520;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}